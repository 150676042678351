import { Chip, CircularProgress, Dialog, DialogContent, Fade, Grid, Typography } from "@mui/material";
import store, { useAppSelector } from "../../utils/redux/store";
import { useEffect } from "react";
import { CikamTransaction, useCheckTransactionQuery } from "../../utils/redux/api/cikamApi";
import { createReturnPath } from "../../utils/config";
import { useDialog } from "../../utils/dialog/DialogProvider";
import useAppNavigate from "../../utils/useAppNavigate";
import { CikamStatusCode, resetCikamTransaction } from "../../utils/redux/cikamSlice";
import Box from "@mui/material/Box";
import "./Bounce.css";
import CikamCancelButton from "./CikamCancelButton";
import CikamStatusView from "./CikamStatusView";
import FormattedMessageJamezz from "../FormattedMessageJamezz";
import { usePreventKioskTimeout } from "../../modules/PreventKioskTimeout/preventKioskTimeout.ts";
import FormatCurrency from "../Currencies/FormatCurrency.tsx";

export default function CikamDialog() {
  const transactionId = useAppSelector((state) => state.cikam.transactionId); // ?? "40ce9277-ff0e-41d3-80bb-08085dbac5e0";
  const paymentAmount = useAppSelector((state) => state.cikam.paymentAmount); // ?? 2450;
  const zoomFactor = useAppSelector((state) => state.global.salesarea?.custom_data?.kiosk?.zoom_factor ?? 0);
  const { data } = useCheckTransactionQuery(transactionId ?? "0", { pollingInterval: 1000, skip: !transactionId });
  const { closeAllDialogs } = useDialog();
  const navigate = useAppNavigate();

  const paid = data?.data?.paid ?? false;
  const orderId = data?.data?.oid;

  useEffect(() => {
    if (paid) {
      closeAllDialogs();
      navigate(createReturnPath() + "?oid=" + orderId);
    }
  }, [paid, orderId, closeAllDialogs, navigate]);

  usePreventKioskTimeout(Boolean(transactionId && paymentAmount));

  if (transactionId && paymentAmount) {
    return (
      <Dialog
        disableAutoFocus={true} // Do not remove, is needed for iframe usage.
        fullScreen={false}
        fullWidth={true}
        maxWidth={zoomFactor > 0 ? false : "md"}
        open={true}
      >
        <DialogContent sx={{ display: "flex", justifyContent: "center" }}>
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Typography>
              <FormattedMessageJamezz id={"Total to be paid"} />
            </Typography>
            <Typography>
              <FormatCurrency value={paymentAmount / 100} />
            </Typography>
          </Box>
        </DialogContent>

        <DialogContent>
          {data?.data?.transaction && data?.data?.transaction.PaymentID === transactionId ? (
            <CikamModule transaction={data.data.transaction} />
          ) : (
            <Box sx={{ width: 1, display: "flex", justifyContent: "center" }}>
              <CircularProgress size={64} />
            </Box>
          )}
        </DialogContent>
      </Dialog>
    );
  } else {
    return null;
  }
}

export function CikamModule({ transaction }: { transaction: CikamTransaction }) {
  const transactionResultDescription = transaction?.TransactionResultDescription;

  const statusCode = transaction?.StatusCode;

  useEffect(() => {
    if (transactionResultDescription === "Cancel") {
      store.dispatch(resetCikamTransaction());
    }
  }, [transactionResultDescription]);

  return (
    <>
      {transaction ? (
        <Grid container>
          {statusCode === CikamStatusCode["Waiting insertion of cash"] || CikamStatusCode["Counting"] ? (
            <InsertCash transaction={transaction} />
          ) : null}
        </Grid>
      ) : null}
    </>
  );
}

function InsertCash({ transaction }: { transaction: CikamTransaction }) {
  return (
    <>
      <Grid item xs={12}>
        <Typography sx={{ fontWeight: 800 }}>
          <FormattedMessageJamezz id={"Insert your cash into the machine"} />
        </Typography>
      </Grid>
      <AlreadyPaid amount={transaction.InsertedAmount / 100} />

      {transaction.ChangeRemaining >= 0 ? <StillToPay amount={transaction.ChangeRemaining / 100} /> : null}

      <Grid item xs={12} container justifyContent={"center"}>
        <CikamStatusView transaction={transaction} />
      </Grid>

      <Grid item xs={12} container justifyContent={"center"}>
        <Fade in={transaction.StatusCode === CikamStatusCode["Waiting insertion of cash"]}>
          <Box>
            <CikamCancelButton />
          </Box>
        </Fade>
      </Grid>
    </>
  );
}

function AlreadyPaid({ amount }: { amount: number }) {
  return (
    <>
      <Grid item xs={6}>
        <Typography>
          <FormattedMessageJamezz id={"Total inserted"} />
        </Typography>
      </Grid>
      <Grid item xs={6} sx={{ textAlign: "right" }}>
        <Typography key={amount} className={"Bounce"}>
          <FormatCurrency value={amount} />
        </Typography>
      </Grid>
    </>
  );
}

function StillToPay({ amount }: { amount: number }) {
  return (
    <>
      <Grid item xs={6}>
        <Typography>
          <FormattedMessageJamezz id={"Amount yet to be inserted"} />
        </Typography>
      </Grid>
      <Grid item xs={6} sx={{ textAlign: "right" }}>
        <Chip label={<FormatCurrency value={amount} />} sx={{ backgroundColor: "black", color: "white", height: 48 }} />
      </Grid>
    </>
  );
}
