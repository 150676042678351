import useVouchers from "../useVouchers";
import { useAppSelector } from "../redux/store.tsx";
import { useEffect } from "react";

export default function DefaultVoucherCode() {
  const defaultVoucherCode = useAppSelector((state) => state.global.salesarea?.defaultVoucherCode);
  const checkVoucherCode = useVouchers({ withLoading: false, withToasts: false });

  useEffect(() => {
    if (defaultVoucherCode) {
      checkVoucherCode(defaultVoucherCode, 1);
    }
  }, [checkVoucherCode, defaultVoucherCode]);

  return null;
}
