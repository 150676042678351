import Article from "../../../../utils/models/menu/Article.ts";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import FormattedMessageJamezz from "../../../FormattedMessageJamezz.tsx";

export default function ConfirmMaxPerTicketPopup({
  article,
  onCancel,
  onConfirm,
  maxPerTicket,
  limitedItemsPreviouslyOrdered,
  limitedItemsInShoppingCartRightNow,
  count,
  valid,
}: {
  article: Article;
  onCancel: () => void;
  onConfirm: () => void;
  maxPerTicket: number;
  limitedItemsPreviouslyOrdered: number;
  limitedItemsInShoppingCartRightNow: number;
  count: number;
  valid: boolean;
}) {
  const totalItemCount = limitedItemsPreviouslyOrdered + limitedItemsInShoppingCartRightNow + count;
  const orderLimitReached = !valid;

  return (
    <Dialog open={true}>
      <DialogTitle>
        <FormattedMessageJamezz id="AYCE.popups.heading.limited-product" />
      </DialogTitle>
      <DialogContent>
        <Typography component={"span"} style={{ textDecoration: "underline", fontWeight: "bold" }}>
          {article.name}
        </Typography>
        <Typography component={"span"}>
          <FormattedMessageJamezz
            id="AYCE.popups.max-per-ticket.rule"
            values={{
              maxPerTicket,
            }}
          />
        </Typography>
        <Typography>
          {limitedItemsPreviouslyOrdered === 0 ? (
            limitedItemsInShoppingCartRightNow === 0 ? (
              <FormattedMessageJamezz id="AYCE.popups.current-zero" />
            ) : null
          ) : (
            <FormattedMessageJamezz
              id="AYCE.popups.current-nonzero"
              values={{
                count: totalItemCount,
              }}
            />
          )}
        </Typography>

        {limitedItemsInShoppingCartRightNow > 0 ? (
          <Typography>
            <FormattedMessageJamezz
              id={`You already have {itemCount} items in your shopping cart.`}
              values={{ itemCount: limitedItemsInShoppingCartRightNow }}
            />
          </Typography>
        ) : null}
        {orderLimitReached ? null : (
          <Typography>
            <FormattedMessageJamezz id="AYCE.popups.are-you-sure" />
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} variant="contained" data-cy="arrangement-rule-notice-cancel-btn">
          <FormattedMessageJamezz id="Cancel" />
        </Button>
        {orderLimitReached ? null : (
          <Button
            onClick={onConfirm}
            variant="contained"
            color="success"
            data-cy="arrangement-rule-notice-confirmation-btn"
          >
            <FormattedMessageJamezz id="Ok" />
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
