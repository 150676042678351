import { useAppSelector } from "../redux/store.tsx";
import { selectArticlesMap } from "../redux/selectors/selectArticlesMap.ts";
import useMenuFilters from "./filters/useMenuFilters.ts";
import { useArticleTraitFiltering } from "./filters/useArticleTraitFiltering.ts";
import { selectArticleArticlegroupsMap } from "../redux/selectors/selectArticleArticlegroupsMap.ts";
import useMenuTagFilters from "./filters/useMenuTagFilters.ts";
import { useIntl } from "react-intl";
import { selectAppLanguage } from "../../../kiosk/components/LanguageSelector/useLanguage.ts";
import { useMemo } from "react";
import Article from "../models/menu/Article.ts";
import { menuTagNamesMap, MenuTagType } from "../models/menu/MenuTag.tsx";

export function usePreferencesAndAllergens() {
  const articlesMap = useAppSelector(selectArticlesMap);
  const articleFilter = useMenuFilters({ usePreferencesAndAllergens: false });
  const { articleTraitFilter } = useArticleTraitFiltering();
  const articleArticlegroupsMap = useAppSelector(selectArticleArticlegroupsMap);
  const menuTags = useMenuTagFilters();
  const intl = useIntl();
  const selectedLanguage = useAppSelector(selectAppLanguage);
  const articles = useMemo(
    () =>
      Object.values(articlesMap)
        .filter((a): a is Article => Boolean(a))
        .filter((p) => p.menuTagIds.length)
        .filter((article: Article) => {
          const record = articleArticlegroupsMap[article.id];
          return record && record?.length > 0;
        })
        .filter(articleFilter)
        .filter(articleTraitFilter),
    [articlesMap, articleFilter, articleTraitFilter, articleArticlegroupsMap]
  );

  const myAllergens = useMemo(() => {
    return menuTags
      .filter((menuTag) => menuTag.type === MenuTagType.Exclude)
      .filter((menuTag) => articles.some((article) => article.menuTagIds.includes(menuTag.id)))
      .sort((a, b) => {
        return (
          a.name in menuTagNamesMap
            ? intl.formatMessage({ id: menuTagNamesMap[a.name as keyof typeof menuTagNamesMap] })
            : (a.translations?.[selectedLanguage]?.name ?? a.name)
        ).localeCompare(
          b.name in menuTagNamesMap
            ? intl.formatMessage({ id: menuTagNamesMap[b.name as keyof typeof menuTagNamesMap] })
            : (b.translations?.[selectedLanguage]?.name ?? b.name)
        );
      });
  }, [menuTags, intl, selectedLanguage, articles]);

  const myPreferences = useMemo(() => {
    return menuTags
      .filter((menuTag) => menuTag.type === MenuTagType.Include)
      .filter((menuTag) => articles.some((article) => article.menuTagIds.includes(menuTag.id)))
      .sort((a, b) =>
        (a.translations?.[selectedLanguage]?.name ?? a.name).localeCompare(
          b.translations?.[selectedLanguage]?.name ?? b.name
        )
      );
  }, [menuTags, selectedLanguage, articles]);

  return {
    preferences: myPreferences,
    allergens: myAllergens,
  };
}
