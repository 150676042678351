import { useEffect, useMemo, useState } from "react";
import { Button, Dialog, DialogContent, Typography } from "@mui/material";
import store, { useAppSelector } from "../../../global/utils/redux/store";
import { useIntl } from "react-intl";
import Box from "@mui/material/Box";
import { useCancelReadyToOrderSharedShoppingCartMutation } from "../../../global/utils/redux/api/shoppingCartApi";
import {
  processSharedShoppingCart,
  useFetchSharedShoppingCart,
} from "../../../global/utils/sharedShoppingCart/processSharedShoppingCart";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz";
import { useDialog } from "../../../global/utils/dialog/DialogProvider.tsx";
import useAppNavigate from "../../../global/utils/useAppNavigate.ts";
import { createReturnPath } from "../../../global/utils/config.ts";
import { sharedShoppingCartTimerElapsed } from "../../../global/utils/redux/shoppingCartSlice.tsx";
import { BlockUserInput } from "@jamezz/react-components";

export default function SharedShoppingCartDialog() {
  const readyToOrder = useAppSelector((state) => state.shoppingCart.sharedShoppingCart?.readyToOrder);

  return (
    <Dialog open={!!readyToOrder}>
      {readyToOrder ? (
        <DialogContent key={readyToOrder.uuid}>
          <Box>
            <Typography sx={{ color: "red", width: 1, textAlign: "center" }}>
              <TimerSeconds unix_wait_until_time={readyToOrder.unix_wait_until_time} />
            </Typography>
          </Box>
          <Box sx={{ marginY: 1 }}>
            <Typography sx={{ fontSize: "0.95rem" }}>
              <FormattedMessageJamezz id={"send_shared_order"} />
            </Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CancelButton />
          </Box>
        </DialogContent>
      ) : null}
    </Dialog>
  );
}

function CancelButton() {
  const [request] = useCancelReadyToOrderSharedShoppingCartMutation();
  const { blockingStarted, blockingStopped } = BlockUserInput.useBlockUserInput();
  return (
    <Button
      variant={"outlined"}
      onClick={() => {
        blockingStarted();
        request(store.getState().global.webcode)
          .then((response) => {
            if (response.data) {
              processSharedShoppingCart(response.data);
            }
          })
          .finally(() => {
            blockingStopped();
          });
      }}
    >
      <FormattedMessageJamezz id={"button_change_order"} />
    </Button>
  );
}

function TimerSeconds({ unix_wait_until_time }: { unix_wait_until_time: number }) {
  const serverTimestamp = useAppSelector((state) => state.global.serverTimestamp);
  const orderData = useAppSelector((state) => state.global.order);
  const { closeAllDialogs } = useDialog();
  const navigate = useAppNavigate();
  const initialTimeLeft = useMemo(() => {
    return Math.max(0, unix_wait_until_time - (serverTimestamp ?? Math.round(new Date().getTime() / 1000)));
  }, [unix_wait_until_time, serverTimestamp]);
  const intl = useIntl();
  const [secondsLeft, setSecondsLeft] = useState(initialTimeLeft);
  const fetchSharedShoppingCart = useFetchSharedShoppingCart();

  useEffect(() => {
    if (secondsLeft > 0) {
      const timeout = setTimeout(() => {
        if (secondsLeft - 1 <= 0) {
          setSecondsLeft(0);
        } else {
          setSecondsLeft(secondsLeft - 1);
        }
      }, 1000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [secondsLeft]);

  useEffect(() => {
    if (secondsLeft === 0 && orderData != null && orderData?.orderId) {
      closeAllDialogs();
      navigate(createReturnPath() + "?oid=" + orderData.orderId);
    } else if (secondsLeft === 0) {
      closeAllDialogs();
      store.dispatch(sharedShoppingCartTimerElapsed());
      fetchSharedShoppingCart({ showLoading: true });
    }
  }, [secondsLeft, orderData, closeAllDialogs, navigate, fetchSharedShoppingCart]);

  return <>{intl.formatMessage({ id: "seconds_left" }, { seconds: secondsLeft })}</>;
}
