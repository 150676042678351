import Box from "@mui/material/Box";
import { IconButton, Typography } from "@mui/material";
import JTooltip from "../../../../kiosk/components/JamezzMUI/JTooltip.tsx";
import store from "../../../../global/utils/redux/store.tsx";
import { Delete } from "@mui/icons-material";
import { vouchersV2Removed, VoucherV2 } from "../../../../global/utils/vouchersV2/vouchersV2Slice.tsx";
import { useIntl } from "react-intl";

export default function SingleVoucherTableView({ voucherV2 }: { voucherV2: VoucherV2 }) {
  const intl = useIntl();
  return (
    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
      <Typography sx={{ fontWeight: 800 }}>{voucherV2.voucher.code}</Typography>
      {/*<Typography>{toCurrency(voucher.amount_in_cents / 100)}</Typography>*/}
      <JTooltip
        title={intl.formatMessage({ id: "Voucher.remove" })}
        arrow
        onClose={() => {}}
        onOpen={() => {}}
        disableInteractive
      >
        <IconButton
          onClick={() => {
            store.dispatch(vouchersV2Removed([voucherV2]));
          }}
        >
          <Delete />
        </IconButton>
      </JTooltip>
    </Box>
  );
}
