import React, { Dispatch, useEffect, useMemo, useRef, useState } from "react";
import { CircularProgress, Typography } from "@mui/material";
import { useArticleTraitFiltering } from "../../../global/utils/menu/filters/useArticleTraitFiltering";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz";
import useArticleSearchFiltering from "../../../global/utils/menu/filters/useArticleSearchFiltering";
import useMenuFilters from "../../../global/utils/menu/filters/useMenuFilters";

import Article from "../../../global/utils/models/menu/Article";
import Box from "@mui/material/Box";
import { debounce } from "lodash";
import LazyLoadItems from "../../../global/components/LazyLoadItems";
import { useScroll } from "../../../global/utils/ScrollProvider";
import ActiveQuickTraitsChipBar from "./MultipleLayersMenuPage/QuickTraits/ActiveQuickTraitsChipBar";
import { useAppSelector } from "../../../global/utils/redux/store";
import { selectArticlesSum } from "../../../global/utils/redux/shoppingCartSlice";
import { selectArticleArticlegroupsMap } from "../../../global/utils/redux/selectors/selectArticleArticlegroupsMap";
import { selectArticlesMap } from "../../../global/utils/redux/selectors/selectArticlesMap";
import ArticleContent from "./ArticleContent/ArticleContent.tsx";

const debounceSearch = debounce(
  (
    articleArticlegroupsMap: PartialRecord<string, string[]>,
    articles: Article[],
    setFilteredArticles: Dispatch<Article[]>,
    articleFilter: (article: Article) => boolean,
    articleTraitFilter: (article: Article) => boolean,
    searchArticles: (articles: Article[]) => Article[],
    setIsSearching: (isSearching: boolean) => void
  ) => {
    const filtered = articles
      .filter((article: Article) => {
        const record = articleArticlegroupsMap[article.id];
        return record && record.length > 0;
      })
      .filter(articleFilter)
      .filter(articleTraitFilter);

    setFilteredArticles(searchArticles(filtered));
    // setFilteredArticles(filtered);
    setIsSearching(false);
  },
  1000,
  {}
);

export default function SearchContent(props: { pixelHeightCartButton: string; scrollContainerRef?: any }) {
  const articleArticlegroupsMap = useAppSelector(selectArticleArticlegroupsMap);
  const [isSearching, setIsSearching] = useState(false);
  const { scrollContent } = useScroll();
  const articlesMap = useAppSelector(selectArticlesMap);

  const articles = useMemo<Article[]>(() => {
    return Object.values(articlesMap).filter((a): a is Article => Boolean(a));
  }, [articlesMap]);

  const [filteredArticles, setFilteredArticles] = useState<Article[] | null>(null);

  const articleFilter = useMenuFilters();
  const { articleTraitFilter } = useArticleTraitFiltering();
  const searchArticles = useArticleSearchFiltering();

  useEffect(() => {
    setIsSearching(true);
    debounceSearch(
      articleArticlegroupsMap,
      articles,
      setFilteredArticles,
      articleFilter,
      articleTraitFilter,
      searchArticles,
      setIsSearching
    );
  }, [articles, searchArticles, articleTraitFilter, articleArticlegroupsMap, articleFilter]);

  const contentRef = useRef<HTMLDivElement | null>(null);
  const count = useAppSelector(selectArticlesSum);

  return (
    <Box
      className={"JS-SearchContent-Root"}
      ref={contentRef}
      sx={{
        position: "relative",
        alignContent: "flex-start",
        flex: 1,
      }}
    >
      <Box>
        <ActiveQuickTraitsChipBar />
      </Box>
      {isSearching ? (
        <Box sx={{ position: "absolute", top: 200, left: 0, right: 0, width: 1, textAlign: "center" }}>
          <CircularProgress size={122} />
        </Box>
      ) : null}
      {filteredArticles != null ? (
        <>
          {filteredArticles.length > 0 ? (
            <Box sx={{ margin: 4 }}>
              <Typography
                className={"JS-SearchContent-Name"}
                sx={{
                  fontWeight: 800,
                  fontStyle: "italic",
                  fontSize: 48,
                  marginLeft: 1,
                  color: "primary.main",
                }}
              >
                <FormattedMessageJamezz id={"Search.title.results"} />
              </Typography>
            </Box>
          ) : null}
          {filteredArticles.length === 0 ? (
            <Box className={"JS-ArticleContent-Root"} sx={{ display: "flex", flexDirection: "column", margin: 4 }}>
              <Typography
                sx={{ fontSize: 32, fontStyle: "italic", color: "primary.main" }}
                data-cy="search-no-articles-found"
              >
                <FormattedMessageJamezz id={"No articles found..."} />
              </Typography>
            </Box>
          ) : null}

          <LazyLoadItems
            items={filteredArticles}
            numberOfItemsToRenderInitially={10}
            numberOfItemsToAddEachLoad={10}
            scrollContent={props.scrollContainerRef ?? scrollContent}
            width={0.333}
            pixelHeightCartButton={count > 0 ? props.pixelHeightCartButton : undefined}
            articleContent={ArticleContent}
          />
        </>
      ) : null}
    </Box>
  );
}
