import { useCallback } from "react";
import { useIntl } from "react-intl";
import store from "../../../../../../global/utils/redux/store.tsx";
import { toast } from "react-toastify";
import { PiggyRewardV2 } from "../../../../../../global/utils/redux/api/piggyRewardsApi.ts";
import { VoucherDefV2, vouchersV2Added } from "../../../../../../global/utils/vouchersV2/vouchersV2Slice.tsx";
import _ from "lodash";

export function useClaimPiggyRewardDialogV2(item: PiggyRewardV2 | undefined) {
  const intl = useIntl();

  const onClose = useCallback(
    (claimed: boolean) => {
      if (!item) return;
      if (claimed) {
        if (
          (item.jamezzPiggyReward.rewardable as VoucherDefV2).discountType != "PERCENTAGE_OFF_PRODUCT" &&
          (item.jamezzPiggyReward.rewardable as VoucherDefV2).discountType != "AMOUNT_OFF_PRODUCT" &&
          (item.jamezzPiggyReward.rewardable as VoucherDefV2).discountType != "ADD_DISCOUNTED_PRODUCT"
        ) {
          toast.success(
            intl.formatMessage(
              {
                id: "Piggy.messages.rewardAdded",
              },
              {
                name: item.title,
              }
            )
          );
        }
        store.dispatch(
          vouchersV2Added([
            {
              voucher: {
                piggyReward: _.omit(item.jamezzPiggyReward, "rewardable"),
                number_of_times: 1,
                code: item.uuid,
              },
              voucherdef: item.jamezzPiggyReward.rewardable as VoucherDefV2,
            },
          ])
        );
      }
    },
    [item, intl]
  );

  return {
    piggyRewardLoading: false,
    onPiggyRewardDialogClose: onClose,
  };
}
