import { useAppSelector } from "../../../global/utils/redux/store.tsx";
import { selectSumOfDiscountsPerCampaign } from "../../../global/utils/redux/selectors/discountsPerCampaign.ts";
import { useAccountTotalValue } from "../../../global/utils/redux/accountSlice.tsx";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz.tsx";
import { selectPiggyGiftcardAmount } from "../../../global/utils/redux/selectors/selectPiggyGiftcardAmount.tsx";
import FormatCurrency from "../../../global/components/Currencies/FormatCurrency.tsx";

export function InfoTotal() {
  const totalDiscountAmountOfCampaigns = useAppSelector(selectSumOfDiscountsPerCampaign);
  const totalAmount = useAccountTotalValue() - useAppSelector(selectPiggyGiftcardAmount);

  return (
    <Box>
      {totalDiscountAmountOfCampaigns != 0 ? (
        <Box sx={{ display: "inline-flex", alignItems: "baseline" }}>
          <Typography>
            <FormattedMessageJamezz id={"Discount: "} />
          </Typography>
          <Typography
            className={"JS-OrderPage-DiscountAmount"}
            align={"right"}
            sx={{ fontWeight: 800, fontSize: 48, flex: 1, marginLeft: 2 }}
          >
            <FormatCurrency value={totalDiscountAmountOfCampaigns} />
          </Typography>
        </Box>
      ) : null}
      <Typography
        className={"JS-OrderPage-TotalAmount"}
        align={"right"}
        data-cy={"TotalOrderAmount"}
        sx={{ fontWeight: 800, fontSize: 48, flex: 1 }}
      >
        <FormatCurrency value={totalAmount} />
      </Typography>
    </Box>
  );
}
