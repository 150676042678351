import { getNow } from "../../models/JamezzHours";
import { addHours, differenceInMilliseconds, isAfter, isBefore } from "date-fns";
import { setMenuState } from "../../redux/menuStateSlice";
import { MutableRefObject, useEffect, useMemo, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import OrderHours from "../../models/menu/OrderHours";
import Article from "../../models/menu/Article";
import Articlegroup from "../../models/menu/Articlegroup";
import { selectArticlegroupsMap } from "../../redux/selectors/selectArticlegroupsMap";
import { selectArticlesMap } from "../../redux/selectors/selectArticlesMap";
import { isOpenAt, nextClose, nextOpen, OpeningHours } from "@jamezz/react-components";

export default function MenuArticleOrderHours() {
  const dispatch = useAppDispatch();
  const [updateArticleOrderHours, setUpdateArticleOrderHours] = useState(new Date().toTimeString());
  const [updateArticlegroupOrderHours, setUpdateArticlegroupOrderHours] = useState(new Date().toTimeString());
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);
  const timer2 = useRef<ReturnType<typeof setTimeout> | null>(null);
  const articlegroupsMap = useAppSelector(selectArticlegroupsMap);
  const articlesMap = useAppSelector(selectArticlesMap);

  const articles = useMemo<Article[]>(
    () => Object.values(articlesMap).filter((a): a is Article => Boolean(a)),
    [articlesMap]
  );
  const articlegroups = useMemo<Articlegroup[]>(() => Object.values(articlegroupsMap), [articlegroupsMap]);

  const generateOrderHours = (
    items: { id: string; openingHours?: OpeningHours }[],
    setState: any,
    timer: MutableRefObject<ReturnType<typeof setTimeout> | null>
  ) => {
    const now = getNow();

    const orderHours: OrderHours = {};
    if (items.length == 0) {
      return {};
    }
    let nearestUpdateDate = getNow();
    nearestUpdateDate = addHours(nearestUpdateDate, 1);
    items.forEach((item) => {
      if (item.openingHours) {
        const isOpen = isOpenAt(item.openingHours, now);
        // console.log(isOpen);
        const date = isOpen
          ? nextClose(item.openingHours, now, nearestUpdateDate)
          : nextOpen(item.openingHours, now, nearestUpdateDate);

        if (!isOpen || (date != null && isAfter(nearestUpdateDate, date))) {
          orderHours[item.id] = isOpen ? (date?.getTime() ?? false) : false;
          if (date) {
            nearestUpdateDate = isBefore(nearestUpdateDate, date) ? nearestUpdateDate : date;
          }
        }
      }
    });

    if (timer.current) {
      clearTimeout(timer.current);
    }
    const timeout = differenceInMilliseconds(nearestUpdateDate, now);

    // console.log("Next update: " + timeout / 3600000);
    // console.log("Next update: " + timeout / 1000);
    timer.current = setTimeout(() => {
      setState(new Date().toTimeString());
      timer.current = null;
    }, timeout + 1000);

    return orderHours;
  };

  useEffect(() => {
    if (!articles) {
      return;
    }

    const articleOrderHours = generateOrderHours(articles, setUpdateArticleOrderHours, timer);

    dispatch(setMenuState({ articleOrderHours }));
  }, [articles, updateArticleOrderHours, dispatch]);

  useEffect(() => {
    if (!articlegroups) {
      return;
    }

    const articlegroupOrderHours = generateOrderHours(articlegroups, setUpdateArticlegroupOrderHours, timer2);

    dispatch(setMenuState({ articlegroupOrderHours }));
  }, [updateArticlegroupOrderHours, articlegroups, dispatch]);

  return null;
}
