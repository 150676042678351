import store, { useAppSelector } from "./redux/store";
import { useEffect, useMemo, useState } from "react";
import { getNow } from "./models/JamezzHours";
import { isOpenCalculated } from "./redux/globalSlice.tsx";
import { isOpenAt, nextClose, nextOpen } from "@jamezz/react-components";
import { differenceInMilliseconds } from "date-fns";

export default function useSalesareaIsOpen() {
  const forcedOpen = useAppSelector((state) => state.dev.forcedOpen);

  const sessionState = useAppSelector((state) => state.global.sessionState);

  const systemOnline = sessionState?.systemOnline && sessionState?.tableIsActive && sessionState?.zoneIsActive;

  const isOpen = useAppSelector((state) => state.global.isOpen);

  return useMemo(() => {
    if (forcedOpen) {
      return true;
    }

    return systemOnline && isOpen;
  }, [forcedOpen, systemOnline, isOpen]);
}

//
export function useOpenStateAndDuration() {
  const openingHours = useAppSelector((state) => state.global.salesarea.time_settings?.opening_hours);
  const [now, setNow] = useState(getNow());

  const isOpen = useMemo(() => isOpenAt(openingHours ?? {}, now), [now, openingHours]);

  const openNext = useMemo(() => nextOpen(openingHours ?? {}, now), [now, openingHours]);
  const closeNext = useMemo(() => nextClose(openingHours ?? {}, now), [now, openingHours]);

  useEffect(() => {
    if (openingHours) {
      store.dispatch(isOpenCalculated({ isOpen }));
    }
  }, [openingHours, now, isOpen]);

  useEffect(() => {
    if (isOpen && closeNext) {
      const timeout = setTimeout(
        () => {
          setNow(getNow());
        },
        differenceInMilliseconds(closeNext, getNow())
      );
      return () => {
        clearTimeout(timeout);
      };
    } else if (!isOpen && openNext) {
      const timeout = setTimeout(
        () => {
          setNow(getNow());
        },
        differenceInMilliseconds(openNext, getNow())
      );
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [openNext, closeNext, isOpen]);
}
