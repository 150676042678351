import { Grid, Paper, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { Fragment, memo, useCallback } from "react";
import OrderArticle, { getTotalPrice } from "../../../global/utils/models/order/OrderArticle";
import OrderOptionGroup from "../../../global/utils/models/order/OrderOptionGroup";
import { getArticleName } from "../../../global/utils/models/menu/Article";
import { useOptionTranslations } from "../../../global/utils/translations/useOptionTranslations";
import _ from "lodash";
import { useAppSelector } from "../../../global/utils/redux/store.tsx";
import { selectVoucherDiscounts } from "../../../global/utils/vouchersV2/selectors/selectVoucherDiscounts.ts";
import { selectAppLanguage } from "../../../kiosk/components/LanguageSelector/useLanguage.ts";
import { selectSalesAreaPriceLineId } from "../../../global/utils/useSalesAreaPriceLineId.ts";
import FormatCurrency from "../../../global/components/Currencies/FormatCurrency.tsx";

function _CheckoutArticleContent({
  orderArticle,
  piggyDiscountsIndex,
  voucherDiscounts,
}: {
  orderArticle: OrderArticle;
  piggyDiscountsIndex?: number;
  voucherDiscounts?: { discountsPerOrderArticle: { [p: string]: { discount: number } } };
}) {
  const lang = useAppSelector(selectAppLanguage);
  const { translate } = useOptionTranslations();
  const printOrderOptionGroup = useCallback<any>(
    (orderOptionGroup: OrderOptionGroup) => {
      return (
        <Fragment key={orderOptionGroup.id}>
          {orderOptionGroup.orderArticles
            .filter((orderArticle) => orderArticle.count > 0)
            .map((orderArticle) => {
              return (
                <Fragment key={orderArticle.uuid}>
                  <Grid item xs={12} container>
                    {orderArticle.count > 1 ? (
                      <Typography
                        sx={{ marginRight: 1, fontSize: "0.8rem", opacity: 0.6 }}
                        className="JS-CheckoutPage-OrderItem-OptionCount"
                      >
                        {orderArticle.count}x
                      </Typography>
                    ) : null}
                    <Typography
                      sx={{ fontSize: "0.8rem", opacity: 0.6 }}
                      className="JS-CheckoutPage-OrderItem-OptionName"
                    >
                      {!_.isEmpty(orderArticle.article.translations)
                        ? getArticleName(orderArticle.article, lang)
                        : translate(orderArticle.article.name)}
                    </Typography>
                  </Grid>
                  {orderArticle.orderOptionGroups.map((orderOptionGroup) => printOrderOptionGroup(orderOptionGroup))}
                </Fragment>
              );
            })}
        </Fragment>
      );
    },
    [lang, translate]
  );

  const voucherDiscountsFromStore = useAppSelector(selectVoucherDiscounts);
  const priceLineId = useAppSelector(selectSalesAreaPriceLineId);
  const totalPrice =
    getTotalPrice(orderArticle, priceLineId) -
    (piggyDiscountsIndex == null
      ? 0
      : ((voucherDiscounts ?? voucherDiscountsFromStore).discountsPerOrderArticle[orderArticle.uuid]?.discount ?? 0));

  return (
    <Grid item xs={12} key={orderArticle.article.id}>
      <Paper
        sx={{
          margin: 1,
          position: "relative",
          borderRadius: 3,
          padding: 1,
          display: "flex",
          alignItems: "flex-start",
        }}
        className="JS-CheckoutPage-OrderItem"
      >
        <Box sx={{ display: "flex", flexDirection: "column", flex: "1 1 0%" }}>
          <Box sx={{ display: "flex", alignItems: "flex-start" }}>
            <Typography sx={{ fontWeight: 800, flex: "1 1 0%" }} className="JS-CheckoutPage-OrderItem-Name">
              {orderArticle.count}x {getArticleName(orderArticle.article, lang)}
            </Typography>

            {totalPrice !== 0 ? (
              <Typography sx={{ fontWeight: 800 }} className="JS-CheckoutPage-OrderItem-Price">
                <FormatCurrency value={totalPrice} />
              </Typography>
            ) : null}
          </Box>
          {orderArticle.orderOptionGroups.map((orderOptionGroup) => printOrderOptionGroup(orderOptionGroup))}
        </Box>
      </Paper>
    </Grid>
  );
}

const CheckoutArticleContent = memo(_CheckoutArticleContent);

export default CheckoutArticleContent;
