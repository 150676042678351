import { useAppSelector } from "../../global/utils/redux/store";
import { useMemo } from "react";

export default function useEmployeeCodes() {
  const customData = useAppSelector((state) => state.global.salesarea.custom_data);

  return useMemo<PartialRecord<string, boolean>>(() => {
    const employeeCodes = customData?.employee_codes ? JSON.parse(customData.employee_codes) : [];
    const employeeCodesObject: PartialRecord<string, boolean> = {};
    employeeCodes.forEach((employeeCode: string | number) => {
      employeeCodesObject[String(employeeCode)] = true;
    });
    return employeeCodesObject;
  }, [customData]);
}
