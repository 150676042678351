import { FC, useEffect, useRef } from "react";
import { useFetchLastQueuedOrderQuery } from "../../../global/utils/redux/api/legacyApi.tsx";
import store, { RootState, useAppSelector } from "../../../global/utils/redux/store.tsx";
import OrderArticle, { initOrderArticle } from "../../../global/utils/models/order/OrderArticle.ts";
import { selectArticlesMap } from "../../../global/utils/redux/selectors/selectArticlesMap.ts";
import { changeOrderReceived } from "../../../global/utils/redux/shoppingCartSlice.tsx";

export const LastQueuedOrder: FC = () => {
  const reloadFromQueue = useAppSelector((state) => state.global.salesarea.reloadFromQueue);
  const shoppingCartUuid = useAppSelector((state: RootState) => state.shoppingCart.serverSideShoppingCart?.uuid);
  const { data } = useFetchLastQueuedOrderQuery(shoppingCartUuid, { skip: !reloadFromQueue });
  const articlesMap = useAppSelector(selectArticlesMap);
  const added = useRef<string>(undefined);

  useEffect(() => {
    if (data && Object.keys(articlesMap).length > 0 && shoppingCartUuid != added.current) {
      added.current = shoppingCartUuid;

      const orderArticles: OrderArticle[] = [];

      data.data.items.map((item) => {
        const article = articlesMap[item.pid];

        if (article) {
          const orderArticle = initOrderArticle(articlesMap, article, item.aantal);
          const options = item.product.pk;
          options.forEach((option) => {
            const jsonOption: { article: { id: string } } = JSON.parse(option);

            orderArticle.orderOptionGroups.some((orderOptionGroup) => {
              return orderOptionGroup.orderArticles.some((optionOrderArticle) => {
                if (optionOrderArticle.article.id === jsonOption.article.id) {
                  optionOrderArticle.count = 1;
                  return true;
                } else {
                  return false;
                }
              });
            });
          });
          orderArticles.push(orderArticle);
        }
      });

      store.dispatch(changeOrderReceived(orderArticles));
    }
  }, [articlesMap, data]); // DO not add shoppingCartUuid

  return null;
};
