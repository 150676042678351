import { ChainPart, ChainPartPriority, ChainPartResult, createChainPart } from "./chainParts.ts";
import { BlockUserInput } from "@jamezz/react-components";
import { toast } from "react-toastify";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz.tsx";
import { useMemo } from "react";
import useVouchers from "../../../global/utils/useVouchers";

export function useVoucherChainPart(isOnHomePage: boolean): ChainPart {
  const checkVoucherCode = useVouchers({ withLoading: false, withToasts: false });

  return useMemo(() => {
    return createChainPart(
      ChainPartPriority.Medium,
      (data: string) =>
        new Promise<ChainPartResult>((resolve) => {
          if (!isOnHomePage && !BlockUserInput.isUserInputBlocked()) {
            checkVoucherCode(data, 1 /* 1 means that we scan 1 voucher at a time */)
              .then(() => {
                toast.success(<FormattedMessageJamezz id="Voucher.messages.added-successfully" />);
                resolve("found");
              })
              .catch((err) => {
                console.log(err);
                if (isValidMessage(err)) {
                  if (err.result.message.includes(" is already added!")) {
                    resolve("found");
                    toast.warning(<FormattedMessageJamezz id="Vouchers.warnings.duplicate" />);
                    return;
                  }
                }
                resolve("not_found");
              });
          } else {
            resolve("not_found");
          }
        })
    );
  }, [checkVoucherCode, isOnHomePage]);
}

function isValidMessage(value: unknown): value is { result: { message: string } } {
  return Boolean(
    value != null &&
      typeof value == "object" &&
      "result" in value &&
      value.result &&
      typeof value.result == "object" &&
      "message" in value.result &&
      value.result.message &&
      typeof value.result.message == "string"
  );
}
