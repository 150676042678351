import {
  SharedShoppingCartResponse,
  shoppingCartApi,
  Transaction,
  useLazyFetchSharedShoppingCartQuery,
} from "../redux/api/shoppingCartApi";
import {
  clearSharedShoppingCart,
  clearShoppingCart,
  orderArticlesReceivedFromTransactions,
  sharedShoppingCartReceived,
  sharedShoppingCartTransactionsReceived,
} from "../redux/shoppingCartSlice";
import store from "../redux/store";
import { setServerTimestamp } from "../redux/globalSlice";
import { v4 as uuidv4 } from "uuid";
import { BlockUserInput } from "@jamezz/react-components";
import { useCallback, useEffect } from "react";

export type ShoppingCart = {
  uuid: string;
  webcode?: string;
};

export type SharedShoppingCart = ShoppingCart & {
  transactions: Transaction[];
  readyToOrder: any;
  isOrdered: boolean;
  isShared: boolean;
  orderId: null;
  outOfSync: boolean;
  state: string;
};

export function useFetchSharedShoppingCart() {
  const [fetch, result] = useLazyFetchSharedShoppingCartQuery();

  useEffect(() => {
    if (result.data) {
      processSharedShoppingCart(result.data);
    }
  }, [result.data]);

  return useCallback(
    ({ showLoading }: { showLoading: boolean } = { showLoading: false }) => {
      return new Promise<void>((resolve) => {
        if (store.getState().shoppingCart.sharedShoppingCart == null) {
          store.dispatch(clearShoppingCart());
        }

        store.dispatch(
          shoppingCartApi.util.invalidateTags([{ type: "sharedShoppingCart-get", id: store.getState().global.webcode }])
        );

        let loadingUuid: string | undefined = undefined;
        if (showLoading) {
          loadingUuid = uuidv4();
          BlockUserInput.blockingStarted(loadingUuid);
        }

        fetch(store.getState().global.webcode)
          .then(() => {
            resolve();
          })
          .finally(() => {
            if (loadingUuid) {
              BlockUserInput.blockingStopped(loadingUuid);
            }
          });
      });
    },
    [fetch]
  );
}

export function processSharedShoppingCart(response: SharedShoppingCartResponse) {
  if (response.data.uuid) {
    const sharedShoppingCart: SharedShoppingCart = response.data;

    if (sharedShoppingCart.uuid != store.getState().shoppingCart.sharedShoppingCart?.uuid) {
      store.dispatch(clearSharedShoppingCart());
      store.dispatch(clearShoppingCart());
    }

    store.dispatch(sharedShoppingCartReceived(sharedShoppingCart));
    store.dispatch(setServerTimestamp(response.timestamp));

    const processedTransactions = store.getState().shoppingCart.sharedShoppingCartTransactionUuidsProcessed;
    store.dispatch(
      sharedShoppingCartTransactionsReceived(
        sharedShoppingCart.transactions.map((transaction) => ({ uuid: transaction.uuid }))
      )
    );

    store.dispatch(
      orderArticlesReceivedFromTransactions(
        sharedShoppingCart.transactions
          .filter((transaction) => processedTransactions[transaction.uuid] == null)
          .map((transaction) => transaction.orderArticle)
          .filter((a) => a)
      )
    );
  } else {
    store.dispatch(clearSharedShoppingCart());
    store.dispatch(clearShoppingCart());
    store.dispatch(setServerTimestamp(response.timestamp));
  }
}
