import CustomField from "../../../../types/shared/CustomField";
import { GlobalStyles, Stack, ToggleButton, Typography } from "@mui/material";
import clsx from "clsx";
import useCustomField from "./useCustomField";
import { useAppSelector } from "../../../../global/utils/redux/store.tsx";
import { selectAppLanguage } from "../../../../kiosk/components/LanguageSelector/useLanguage.ts";
import { useCustomfieldTranslatableText } from "../../../../global/utils/languages.ts";

interface Props {
  customField: CustomField;
}

export default function CustomFieldButtonGroupContent({ customField }: Props) {
  const { value, setCustomField } = useCustomField(customField);
  const selectedLanguage = useAppSelector(selectAppLanguage);

  const customFieldText = useCustomfieldTranslatableText();
  const explanationText = customFieldText(customField, "explanation");

  return (
    <Stack gap={3} sx={{ overflowY: "auto", height: 1, width: 1 }} alignItems={"center"}>
      <Typography className={"JS-CustomFieldButtonGroupContent-Explanation"} variant={"h5"}>
        {explanationText ?? null}
      </Typography>
      <Stack
        justifyContent={"center"}
        gap={6}
        flexWrap={"wrap"}
        direction={"row"}
        className={"JS-CustomFieldButtonGroupContent-Root"}
        data-cy="customfield-button-group"
      >
        {customField.styles ? <GlobalStyles styles={customField.styles} /> : null}
        {customField.options?.map((option: any) => (
          <Stack gap={2} alignItems={"center"} key={option.id} className={"JS-CustomFieldButtonGroupContent-Grid"}>
            <ToggleButton
              data-cy={`customfield-button`}
              className={clsx(
                "JS-CustomFieldButtonGroupContent-Button",
                String(option.id) === String(value)
                  ? "JS-CustomFieldButtonGroupContent-Button-Selected"
                  : "JS-CustomFieldButtonGroupContent-Button-Unselected"
              )}
              onClick={() => {
                const value = String(option.id);
                setCustomField(value);
              }}
              value={String(option.id)}
              sx={{ width: 300, height: 300 }}
              selected={String(option.id) === value}
            >
              {option.customHtml ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: option.customHtml,
                  }}
                ></div>
              ) : (
                (option.label?.[selectedLanguage] ?? option.label)
              )}
            </ToggleButton>
            {option.customHtml ? (
              <Typography style={{ textAlign: "center" }} className={"JS-CustomFieldButtonGroupContent-Button-Label"}>
                {option.label?.[selectedLanguage] ?? option.label}
              </Typography>
            ) : null}
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
}
