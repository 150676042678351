import { FormatNumberOptions, useIntl } from "react-intl";
import { useAppSelector } from "../../utils/redux/store.tsx";
import { useCallback } from "react";

export interface DisplayCurrencyProps {
  value: number;
  options?: {
    signDisplay?: FormatNumberOptions["signDisplay"];
    roundingMode?: FormatNumberOptions["roundingMode"];
  };
}

export function useCurrency() {
  const intl = useIntl();
  const currency = useAppSelector((state) => state.menu.currency);
  const currencyRates = useAppSelector((state) => state.menu.currencyRates);
  const currencyMinFractionDigits = useAppSelector(
    (state) => state.global.salesarea?.custom_data?.currencyMinFractionDigits
  );
  const currencySymbolShow = useAppSelector((state) => state.global.salesarea?.custom_data?.currencySymbolShow);

  return useCallback(
    (value: DisplayCurrencyProps["value"], options?: DisplayCurrencyProps["options"]) => {
      const { signDisplay = "auto", roundingMode = "halfFloor" } = options ?? {
        signDisplay: "auto",
        roundingMode: "halfFloor",
      };
      const currencyRate = currencyRates[currency]?.value ?? 1;

      if (currency) {
        value /= currencyRates[currency]?.value ?? 1;
      } else {
        value /= currencyRate;
      }
      value = Number(value.toFixed(9));

      const hideCurrencySymbol = !(currencySymbolShow || currencySymbolShow == undefined);

      const formattedValue = intl.formatNumber(value, {
        style: "currency",
        minimumFractionDigits:
          currencyMinFractionDigits == null || currencyMinFractionDigits == ""
            ? undefined
            : Number(currencyMinFractionDigits),
        currency,
        roundingMode: roundingMode,
        currencyDisplay: hideCurrencySymbol ? "code" : "narrowSymbol",
        signDisplay,
      });
      if (hideCurrencySymbol) {
        return formattedValue.replace(currency, "").trim();
      } else {
        return formattedValue;
      }
    },
    [intl, currency, currencyRates, currencyMinFractionDigits, currencySymbolShow]
  );
}
