import { PiggyReward } from "../../../../../../types/shared/piggy.ts";
import Article from "../../../../../../global/utils/models/menu/Article.ts";

export function findJamezzArticleForPiggyItem(
  item: PiggyReward,
  articlesMap: PartialRecord<string, Article>
): Article | undefined {
  const found = articlesMap[String(item.artikelid)]; // Object.entries(menu.articlesMap).find(([, article]) => String(item.artikelid) === article.id);
  if (found) {
    return found;
  }
  console.warn("Not found, no unlinked items should ever show up in the data. They're filtered in the query");
}
