import { getNow } from "../JamezzHours";
import { differenceInSeconds } from "date-fns";

export default interface OrderHours {
  [key: string]: number | false;
}

export function getSecondsLeftForOrderHours(orderHours: OrderHours, id: string): number | null {
  if (orderHours[id] !== undefined) {
    if (!orderHours[id]) {
      return -1;
    }
    const time = getNow();
    time.setTime(orderHours[id]);
    return differenceInSeconds(time, getNow());
  }
  return null;
}
