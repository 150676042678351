import { Language } from "../../../types/shared/language.ts";
import Box from "@mui/material/Box";
import { IconButton, Typography } from "@mui/material";
import clsx from "clsx";
import { languageToCountry } from "../../components/LanguageSelector/LanguageSelector.tsx";
import { FormattedMessage } from "react-intl";

export default function KioskTurnedOffComponent({ language, messageId }: { language: Language; messageId: string }) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <IconButton data-cy="language-button" sx={{ marginRight: 4 }}>
        <span
          style={{ width: 48 }}
          className={clsx(
            "JS-LanguageFlag-icon",
            "JS-LanguageFlag-" + language,
            "fi",
            "fi-" + languageToCountry(language).code.toLowerCase()
          )}
        ></span>
      </IconButton>
      <Typography
        component={"span"}
        sx={{ fontSize: 40, width: 1 }}
        className={"JS-HomeScreen-Content-KioskDefaultClosedMessage-Text"}
      >
        <FormattedMessage id={messageId} />
      </Typography>
    </Box>
  );
}
