import Article, { getArticleName, getArticlePrice } from "../../../global/utils/models/menu/Article";
import { ButtonBase, Typography } from "@mui/material";
import { useDialog } from "../../../global/utils/dialog/DialogProvider";
import SlideUpTransition from "../../../global/components/SlideUpTransition";
import Image from "../../../global/components/Image";
import store, { useAppSelector } from "../../../global/utils/redux/store";
import { selectAppLanguage } from "../../components/LanguageSelector/useLanguage.ts";
import { postAnalyticsEvent, postEcomAnalyticsEvent } from "../../../global/utils/analytics/useAnalytics";
import {
  findInvalidOrderOptionGroup,
  initOrderArticle,
  OrderArticleAnalytics,
} from "../../../global/utils/models/order/OrderArticle.ts";
import useIsOrderingAllowed from "../../../global/utils/hooks/useIsOrderingAllowed.ts";
import useAddOrderArticleToShoppingCart from "../../../global/utils/order/useAddOrderArticleToShoppingCart.tsx";
import OrderArticleSelectorPage from "../OrderArticleSelectorPage/OrderArticleSelectorPage.tsx";
import { makeGridArea } from "./ArticleSuggestionPage.tsx";
import { selectArticlesMap } from "../../../global/utils/redux/selectors/selectArticlesMap.ts";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz.tsx";
import Box from "@mui/material/Box";
import { selectSalesAreaPriceLineId } from "../../../global/utils/useSalesAreaPriceLineId.ts";
import FormatCurrency from "../../../global/components/Currencies/FormatCurrency.tsx";

interface Props extends Pick<OrderArticleAnalytics, "upsellType" | "upsellSource" | "upsellId"> {
  article: Article;
  upsell_text: string;
  upsell_title: string;
  useAsDialog: boolean;
  columnIndex: number;
}

export default function ArticleSuggestionItem(props: Props) {
  const { openDialog, closeDialog } = useDialog();
  const lang = useAppSelector(selectAppLanguage);
  const canOrder = useIsOrderingAllowed(undefined, props.article);
  const addOrderArticleToShoppingCart = useAddOrderArticleToShoppingCart({ allowUpsell: false });
  const priceLineId = useAppSelector(selectSalesAreaPriceLineId);
  const price = getArticlePrice(props.article, { salesAreaPriceLineId: priceLineId });
  const submitEComPromotion = () => {
    postAnalyticsEvent({
      category: "ArticleSuggestionItem",
      action: "OrderArticleSelectorPage",
      label: props.article.name,
    });
    postEcomAnalyticsEvent("select_promotion", {
      creative_name: props.upsell_title ?? "n/a",
      creative_slot: props.upsell_title ?? "n/a",
      promotion_id: "UPSELL_" + props.article.id,
      promotion_name: props.upsell_text ?? "n/a",
      items: [
        {
          item_id: props.article.id,
          item_name: props.article.name,
          price: props.article.price,
          quantity: 1,
        },
      ],
    });
  };

  const itemNotAvailable = props.article.isNotAvailable;

  return (
    <>
      <ButtonBase
        className={"JS-ArticleSuggestionItem-Root"}
        sx={{
          width: 1,
          gridArea: makeGridArea(props.columnIndex, 0, 4),
          borderRadius: 3,
          boxShadow: "1px 2px 2px 0px rgba(0,0,0,0.1)",
        }}
        disabled={itemNotAvailable}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();

          const orderArticle = initOrderArticle(selectArticlesMap(store.getState()), props.article, 1);
          if (
            !findInvalidOrderOptionGroup(orderArticle) &&
            canOrder == "ALLOWED" &&
            orderArticle.orderOptionGroups.length === 0
          ) {
            addOrderArticleToShoppingCart(orderArticle).catch((err) => console.error(err));
            closeDialog();
          } else {
            openDialog({
              children: (
                <OrderArticleSelectorPage
                  article={props.article}
                  isUpselled={true}
                  upsellType={props.upsellType ?? "Product"}
                  upsellSource={props.upsellSource}
                  upsellId={props.upsellId}
                />
              ),
              fullScreen: "almostFullScreen",
              TransitionComponent: SlideUpTransition,
            });

            submitEComPromotion();
          }
        }}
      ></ButtonBase>
      {props.article.imageMediaUrls?.[0]?.conversions?.responsive?.srcset ? (
        <Image
          className={"JS-ArticleSuggestionItem-Image"}
          srcSet={props.article.imageMediaUrls?.[0]?.conversions?.responsive?.srcset}
          style={{
            paddingTop: 16,
            width: "100%",
            maxHeight: 236,
            objectFit: "contain",
            pointerEvents: "none",
            gridArea: makeGridArea(props.columnIndex, 0),
          }}
        />
      ) : null}
      <Typography
        className={"JS-ArticleSuggestionItem-Name"}
        sx={{
          px: 2,
          fontSize: "1.5rem",
          fontWeight: 800,
          textAlign: "center",
          gridArea: makeGridArea(props.columnIndex, 1),
        }}
      >
        {getArticleName(props.article, lang)}
      </Typography>
      {price > 0 || props.article.vaprice > 0 ? (
        <Typography
          sx={{
            fontWeight: 800,
            fontSize: "1.2rem",
            px: 2,
            textAlign: "center",
            gridArea: makeGridArea(props.columnIndex, 2),
          }}
          className={"JS-ArticleSuggestionItem-Price"}
        >
          <FormatCurrency value={props.article.vaprice > 0 ? props.article.vaprice : price} />
        </Typography>
      ) : null}

      {itemNotAvailable ? (
        <Box
          sx={{
            gridArea: makeGridArea(props.columnIndex, 0, 4),
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            p: 4,
          }}
        >
          {props.article.isOutOfStock ? (
            <Typography
              variant={"h6"}
              sx={{ fontWeight: 1000, textAlign: "center" }}
              className={"JS-ArticleSuggestionItem-OutOfStock-Text"}
            >
              <FormattedMessageJamezz id={"OUT OF STOCK"} />
            </Typography>
          ) : (
            <Typography
              variant={"h6"}
              sx={{ fontWeight: 1000, textAlign: "center" }}
              className={"JS-ArticleSuggestionItem-NotAvailable-Text"}
            >
              <FormattedMessageJamezz id={"NOT AVAILABLE"} />
            </Typography>
          )}
        </Box>
      ) : null}
    </>
  );
}
