import { useMemo } from "react";
import { getArticlesByArticlegroupIds } from "../models/menu/Menu";
import ArticleTraitValue from "../models/ArticleTraits/ArticleTraitValue";
import { useAppSelector } from "../redux/store";
import ArticleTrait from "../models/ArticleTraits/ArticleTrait";
import { selectTopArticlegroupIds } from "../redux/selectors/selectTopArticlegroupIds";
import { selectArticlesMap } from "../redux/selectors/selectArticlesMap";
import { selectArticlegroupArticlesMap } from "../redux/selectors/selectArticlegroupArticlesMap";

export default function useArticleTraitValues(articleTrait: ArticleTrait) {
  const stateOfArticleTraits: any = useAppSelector((state) => state.traitFilter.stateOfArticleTraits);
  const currentValuesArray = stateOfArticleTraits[articleTrait.apiId];

  const selectedArticlegroup = useAppSelector((state) => state.menu.selectedArticlegroup);
  const topArticlegroupIds = useAppSelector(selectTopArticlegroupIds);
  const articlesMap = useAppSelector(selectArticlesMap);

  const articlegroupArticlesMap = useAppSelector(selectArticlegroupArticlesMap);
  const availableTraitValues = useMemo(() => {
    const availableTraitValues: PartialRecord<string, PartialRecord<string, boolean>> = {};

    const articlegroupIds = selectedArticlegroup ? [selectedArticlegroup.id] : topArticlegroupIds;

    availableTraitValues[articleTrait.apiId] = {};

    getArticlesByArticlegroupIds(articlesMap, articlegroupArticlesMap, articlegroupIds).forEach((article) => {
      if (article.articleTraits) {
        Object.keys(article.articleTraits).forEach((key) => {
          if (article.articleTraits?.[key]) {
            article.articleTraits?.[key].values.forEach((value) => {
              if (!availableTraitValues[key]) {
                availableTraitValues[key] = {};
              }
              availableTraitValues[key][value] = true;
            });
          }
        });
      }
    });

    return availableTraitValues;
  }, [articleTrait.apiId, articlegroupArticlesMap, articlesMap, selectedArticlegroup, topArticlegroupIds]);

  const currentValuesObject = useMemo(() => {
    const obj: { [value: string]: ArticleTraitValue } = {};

    (currentValuesArray ?? []).forEach((value: string) => {
      if (articleTrait.values[value]) {
        obj[value] = articleTrait.values[value];
      }
    });
    return obj;
  }, [currentValuesArray, articleTrait.values]);

  const articleTraitValues = useMemo(() => {
    return Object.keys(articleTrait.values)
      .filter((value) => currentValuesObject[value] || availableTraitValues[articleTrait.apiId]?.[value])
      .sort((a1: string, b1: string) => {
        const a = articleTrait.values[a1];
        const b = articleTrait.values[b1];
        if (a?.sortKey == null && b?.sortKey == null) {
          return (a?.name ?? "").localeCompare(b?.name ?? "");
        } else if (a?.sortKey == null) {
          return 1;
        } else if (b?.sortKey == null) {
          return -1;
        } else {
          return (a?.sortKey ?? 0) - (b?.sortKey ?? 0);
        }
      });
  }, [articleTrait.values, articleTrait.apiId, currentValuesObject, availableTraitValues]);
  return { articleTraitValues, currentValuesArray, currentValuesObject };
}
