import store, { useAppSelector } from "../redux/store";
import { useEffect } from "react";
import { selectArticlesMap } from "../redux/selectors/selectArticlesMap";
import { vouchersV2Removed } from "../vouchersV2/vouchersV2Slice.tsx";
import useVouchers from "../useVouchers";

/**
 * Responsible for removing vouchers if they are invalid
 */
export function VoucherChecker() {
  const articlesMap = useAppSelector(selectArticlesMap);
  const checkVoucherCode = useVouchers({ doNotAddVoucherToStore: true, withToasts: false });

  useEffect(() => {
    if (Object.keys(articlesMap).length > 0) {
      const vouchersV2 = store.getState().vouchersV2.vouchers;
      vouchersV2.forEach((voucher) => {
        if (voucher.voucher.code) {
          checkVoucherCode(voucher.voucher.code, 1).catch((e) => {
            if (e.result.message !== "Voucher is already added") {
              store.dispatch(vouchersV2Removed([voucher]));
            }
          });
        }
      });
    }
  }, [articlesMap, checkVoucherCode]);

  return null;
}
