import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { setStateOfArticleTraits } from "../redux/traitFilterSlice";
import ArticleTrait from "../models/ArticleTraits/ArticleTrait";

export default function useTraitFiltering() {
  const dispatch = useAppDispatch();

  const stateOfArticleTraits = useAppSelector((state) => state.traitFilter.stateOfArticleTraits);

  const setValuesForFilter = useCallback(
    (articleTrait: ArticleTrait, values: string[]) => {
      const copy: PartialRecord<string, string[]> = JSON.parse(JSON.stringify(stateOfArticleTraits));
      if (!copy[articleTrait.apiId]) {
        copy[articleTrait.apiId] = Object.keys(articleTrait.values);
      }
      copy[articleTrait.apiId] = values;

      dispatch(setStateOfArticleTraits(copy));
    },
    [dispatch, stateOfArticleTraits]
  );

  return {
    setValuesForFilter,
  };
}
