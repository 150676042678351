import { Collapse, darken, Divider, Typography } from "@mui/material";
import FormattedMessageJamezz from "../../../../global/components/FormattedMessageJamezz";
import Box from "@mui/material/Box";
import { RootState, useAppSelector } from "../../../../global/utils/redux/store.tsx";
import { TransitionGroup } from "react-transition-group";

export default function OrderPageVouchers() {
  const vouchersV2 = useAppSelector((state: RootState) =>
    state.vouchersV2.vouchers.filter((voucher) => Boolean(voucher.voucher.code))
  );
  const venueId = useAppSelector((state) => state.global.salesarea?.venue_id);

  const piggyVouchersEnabled = useAppSelector((state: RootState) => state.global.salesarea.piggy.vouchers.enabled);
  if (!piggyVouchersEnabled) {
    return null;
  }
  return (
    <Box
      sx={{
        alignSelf: "end",
        marginBottom: 4,
        width: "100%",
        marginTop: 4,
        color: "white",
      }}
    >
      {venueId == 1647 ? (
        <Divider
          sx={{
            "&::before": {
              backgroundColor: "white",
              borderTopWidth: "4px",
            },
            "&::after": {
              backgroundColor: "white",
              borderTopWidth: "4px",
            },
          }}
        >
          <FormattedMessageJamezz id="Piggy.order-page.vouchers-list.header" />
          <Typography
            component="span"
            variant="body2"
            sx={(theme) => ({ color: darken(theme.palette.primary.main, 0.6) })}
          ></Typography>
        </Divider>
      ) : null}

      {vouchersV2.length === 0 && venueId == 1647 ? (
        <Typography textAlign={"center"} sx={{ fontSize: 72, fontWeight: 800, textTransform: "uppercase" }}>
          <FormattedMessageJamezz id={"Vouchers.messages.scan-code"} />
        </Typography>
      ) : (
        <TransitionGroup style={{ width: "100%" }}>
          {vouchersV2.map((voucherV2) => {
            console.log(voucherV2);
            return (
              <Collapse key={voucherV2.voucher.code}>
                <Box
                  key={voucherV2.voucher.code}
                  sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
                >
                  {voucherV2.voucher.code?.length <= 16 ? (
                    <Typography className={"JS-OrderPage-LeatVoucherText"}>
                      *****{voucherV2.voucher.code?.substring(5)}
                    </Typography>
                  ) : (
                    <Typography className={"JS-OrderPage-LeatVoucherText"}>{voucherV2.voucherdef.title}</Typography>
                  )}
                  <Typography className={"JS-OrderPage-LeatVoucherAmount"}>
                    {voucherV2.voucher.number_of_times ?? null}
                  </Typography>
                  {/*<Typography>{toCurrency(voucherV2.voucher / 100)}</Typography>*/}
                  {/*<JTooltip*/}
                  {/*  title={intl.formatMessage({ id: "PiggyGiftCard.remove" })}*/}
                  {/*  arrow*/}
                  {/*  onClose={() => {}}*/}
                  {/*  onOpen={() => {}}*/}
                  {/*  disableInteractive*/}
                  {/*>*/}
                  {/*  <IconButton*/}
                  {/*    onClick={() => {*/}
                  {/*      store.dispatch(vouch(voucherV2));*/}
                  {/*    }}*/}
                  {/*  >*/}
                  {/*    <Delete style={{ color: "white" }} />*/}
                  {/*  </IconButton>*/}
                  {/*</JTooltip>*/}
                </Box>
              </Collapse>
            );
          })}
        </TransitionGroup>
      )}
      {vouchersV2.length > 0 || venueId == 1647 ? (
        <Divider
          className={"JS-OrderPage-LeatVoucherDivider"}
          sx={{ borderBottomWidth: "4px", marginTop: 4, backgroundColor: "white" }}
        />
      ) : null}
    </Box>
  );
}
