import Campaign from "../../../../global/utils/models/campaigns/Campaign.ts";
import { ButtonBase, Stack, Typography } from "@mui/material";
import Image from "../../../../global/components/Image.tsx";
import { useDialog } from "../../../../global/utils/dialog/DialogProvider.tsx";
import Box from "@mui/material/Box";
import { useAppSelector } from "../../../../global/utils/redux/store.tsx";
import useMenuFilters from "../../../../global/utils/menu/filters/useMenuFilters.ts";
import { selectArticlesMap } from "../../../../global/utils/redux/selectors/selectArticlesMap.ts";
import { useMemo } from "react";
import { getArticlesById } from "../../../../global/utils/models/menu/Menu.ts";
import { CSSProperties } from "@mui/material/styles/createMixins";
import SlideUpTransition from "../../../../global/components/SlideUpTransition.tsx";
import { selectArticleGroupsForCampaign } from "./selectArticleGroupsForCampaign.tsx";
import CampaignProductsDialog from "./CampaignProductsDialog.tsx";
import { isAfter, isBefore } from "date-fns";

export default function CampaignMenuItem({
  campaign,
  widthPercentage,
}: {
  campaign: Campaign;
  // category: Articlegroup;
  widthPercentage: CSSProperties["width"];
}) {
  const { openDialog } = useDialog();
  const articleFilter = useMenuFilters();
  const articlesMap = useAppSelector(selectArticlesMap);

  const groups = useAppSelector((state) => selectArticleGroupsForCampaign(state, campaign));

  const isValid = useMemo(() => {
    if (campaign.startDateTime != null && !isAfter(new Date(), campaign.startDateTime)) {
      return false;
    } else if (campaign.endDateTime != null && !isBefore(new Date(), campaign.endDateTime)) {
      return false;
    }
    return groups.every((group) => {
      return (
        (getArticlesById(articlesMap, group.productIds).filter(articleFilter) ?? []).length > 0 &&
        group.numberOfProducts > 0
      );
    });
  }, [articleFilter, articlesMap, groups, campaign.startDateTime, campaign.endDateTime]);
  // const articleIds = useAppSelector(selectCampaignArticlesMap)[campaign.id] ?? [];
  // const articleIdsPerGroup = useMemo(() => {
  //   if(campaign.type == "X_PER_GROUP_FOR_Y") {
  //     const copy = campaign as XPerGroupForYCampaign;
  //     return copy
  //   }
  // },[])
  // console.log(articleIds);
  // const articles = useMemo<Article[]>(() => {
  //   return getArticlesById(articlesMap, articleIds).filter(articleFilter) ?? [];
  // }, [articlesMap, articleIds, articleFilter]);
  // const parseCurrency = useCurrency({ location: CurrencyLocation.CampaignMenuItem });
  //
  // const price = useMemo(() => {
  //   return getPriceOfCampaign(campaign);
  // }, [campaign]);

  if (!isValid) {
    return null;
  }

  return (
    <Box sx={{ width: widthPercentage }} className={"JS-CampaignMenuItem-Root"}>
      <ButtonBase
        className={"JS-CampaignMenuItem-Button"}
        sx={{ width: 1, height: 1 }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          openDialog({
            children: <CampaignProductsDialog campaign={campaign} />,
            fullScreen: true,
            TransitionComponent: SlideUpTransition,
          });
        }}
      >
        <Stack gap={2} justifyContent={"space-between"} className={"JS-CampaignMenuItem-Stack"}>
          <Image
            className={"JS-CampaignMenuItem-Image"}
            srcSet={campaign.mediaInCategory?.conversions?.responsive?.srcset}
            style={{ objectFit: "contain" }}
          />
          <Typography className={"JS-CampaignMenuItem-Title"}>{campaign.name}</Typography>
          {/*{price != null ? <Typography textAlign={"center"}>{parseCurrency(price)}</Typography> : null}*/}
        </Stack>
      </ButtonBase>
    </Box>
  );
}
