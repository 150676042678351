import { useCallback, useMemo } from "react";
import Article from "../../models/menu/Article";
import Articlegroup from "../../models/menu/Articlegroup";
import { useAppSelector } from "../../redux/store";
import { MenuTagType } from "../../models/menu/MenuTag";
import {
  useIsArticleAvailableInArrangement,
  useIsArticlegroupAvailableInArrangement,
} from "../../redux/arrangements/arrangementsSlice.tsx";
import isQr from "../../helpers/isQr";
import { selectMenuTagFilters } from "../../redux/selectors/selectMenuTagFilters";
import { selectServiceRequestArticles } from "../../redux/selectors/selectServiceRequestArticles";
import { selectArticleArticlegroupsMap } from "../../redux/selectors/selectArticleArticlegroupsMap";
import { selectArticlegroupsMap } from "../../redux/selectors/selectArticlegroupsMap";
import useAgeCheckResponse from "../../ageCheck/useAgeCheckResponse.ts";
import { selectOptionGroupsMap } from "../../redux/selectors/selectOptionGroupsMap.ts";
import { selectArticlesMap } from "../../redux/selectors/selectArticlesMap.ts";
import { selectActiveArrangement } from "../../redux/arrangements/selectActiveArrangement.tsx";
import { useIntl } from "react-intl";
import { getPropertyOfOptionGroup } from "../../models/menu/OptionGroup.ts";
import { selectArticlegroupArticlesMap } from "../../redux/selectors/selectArticlegroupArticlesMap.ts";

function modelIsFiltered<T>(model: T, reason: string, result: FilterResult<T>): FilterResult<T> {
  result.reason = reason;
  result.model = model;
  result.isFiltered = true;
  return result;
}

export type FilterResult<T> = {
  isFiltered: boolean;
  reason?: string;
  model?: T;
  children: Omit<FilterResult<T>, "isFiltered">[];
};

function useMenuFilters(
  {
    usePreferencesAndAllergens = true,
    useArrangementsFilter = true,
    useArticlegroupsFilter = true,
    showReasons = false,
  }: {
    usePreferencesAndAllergens?: boolean;
    useArrangementsFilter?: boolean;
    useArticlegroupsFilter?: boolean;
    showReasons?: boolean;
  } = {
    usePreferencesAndAllergens: true,
    useArrangementsFilter: true,
    useArticlegroupsFilter: true,
    showReasons: false,
  }
) {
  const articleOrderHoursState = useAppSelector((state) => state.menuState.articleOrderHours);
  const articlegroupOrderHoursState = useAppSelector((state) => state.menuState.articlegroupOrderHours);
  const articleArticlegroupsMap = useAppSelector(selectArticleArticlegroupsMap);
  const hideItemsBySelectedAllergens = useAppSelector((state) => state.global.hideItemsBySelectedAllergens);
  const intl = useIntl();
  const menuTags = useAppSelector((state) => selectMenuTagFilters(state, intl));
  const serviceRequestArticles = useAppSelector(selectServiceRequestArticles);
  const menuTagPreferences = useAppSelector((state) => state.menuState.menuTagPreferences);
  const useArrangements = useAppSelector((state) => state.global.salesarea.use_arrangements);
  const arrangement = useAppSelector(selectActiveArrangement);
  const articlegroupsMap = useAppSelector(selectArticlegroupsMap);
  const articlesMap = useAppSelector(selectArticlesMap);
  const ageCheckResponse = useAgeCheckResponse();
  const ageCheckIsDenied = ageCheckResponse === "DENIED";
  const isArticleAvailableInArrangement = useIsArticleAvailableInArrangement(articleArticlegroupsMap);
  const optionGroupsMap = useAppSelector(selectOptionGroupsMap);

  const allergens = useMemo(() => {
    return Object.values(menuTags).filter(
      (menuTag) => menuTag.type === MenuTagType.Exclude && menuTagPreferences?.[menuTag.id]?.checked
    );
  }, [menuTags, menuTagPreferences]);

  const preferences = useMemo(() => {
    return Object.values(menuTags).filter(
      (menuTag) => menuTag.type === MenuTagType.Include && menuTagPreferences?.[menuTag.id]?.checked
    );
  }, [menuTags, menuTagPreferences]);

  const articleFilter = useCallback(
    (
      article: Article,
      index?: number,
      array?: Article[],
      usePreferences: boolean = usePreferencesAndAllergens,
      useArrangementsF = useArrangementsFilter,
      useArticlegroupsF = useArticlegroupsFilter,
      articleIdsPath: { [articleId: string]: boolean } = {}
    ) => {
      const result: FilterResult<Article> = { isFiltered: false, children: [] };
      if (Object.keys(articleIdsPath).length === 0) {
        articleIdsPath[article.id] = true;
      }
      if (articleOrderHoursState[article.id] === false) {
        return modelIsFiltered(article, "falls outside of order hours.", result);
      }

      if (ageCheckIsDenied && article.requireAge && article.requireAge > 0) {
        return modelIsFiltered(article, "Age check is denied", result);
      }

      if (article.blocked) {
        return modelIsFiltered(article, "Article is blocked", result);
      }

      if (isQr() && useArrangements && useArrangementsF) {
        if (arrangement && !isArticleAvailableInArrangement(article, arrangement)) {
          return modelIsFiltered(
            article,
            `Article is not available in this arrangement ${arrangement.name}\x1B[1m#${arrangement.id}\x1B[m`,
            result
          );
        }
        if (arrangement && "arrangementArticleIds" in arrangement && arrangement.arrangementArticleIds[article.id]) {
          return modelIsFiltered(article, "Is configured as an arrangement identifier", result);
        }
      }

      if (serviceRequestArticles[article.id]) {
        return modelIsFiltered(article, "Is a service request", result);
      }

      const record = articleArticlegroupsMap[article.id];
      if (record == null || record.length === 0) {
        // Exclude articles which are not found in an active articlegroup, like option articles
        // return false;
      }

      if (
        useArticlegroupsF &&
        articleArticlegroupsMap[article.id]?.every((articlegroupId) => {
          return !articlegroupsMap[articlegroupId].isVisibleInJamezz;
        })
      ) {
        return modelIsFiltered(article, "All of its article groups have isVisibileInJamezz = false", result);
      }

      if (
        useArticlegroupsF &&
        articleArticlegroupsMap[article.id]?.every((articlegroupId) => {
          return articlegroupOrderHoursState[articlegroupId] === false;
        })
      ) {
        return modelIsFiltered(
          article,
          "All of its article groups falls outside of the configured order hours",
          result
        );
      }

      if (usePreferencesAndAllergens) {
        if (allergens.length > 0 && hideItemsBySelectedAllergens) {
          if (article.menuTagIds.some((menuTagId) => allergens.some((allergen) => allergen.id == menuTagId))) {
            return modelIsFiltered(article, "Filtered because it is selected as an allergen", result);
          }
        }

        if (preferences.length > 0 && usePreferences) {
          if (article.menuTagIds.every((menuTagId) => !preferences.find((preference) => preference.id == menuTagId))) {
            return modelIsFiltered(article, "Filtered because of something related with preferences?", result);
          }
        }
      }

      // Find an option group which has minCount > 0 and does not have any options available, this article would fail to be accepted in the pos, so filter it out.
      if (
        article.optionGroupIds.some((optionGroupId) => {
          const optionGroup = optionGroupsMap[optionGroupId];
          if (
            optionGroup &&
            getPropertyOfOptionGroup(optionGroup, article, "minCount") > 0 &&
            optionGroup.optionIds
              .filter((optionId) => !articleIdsPath[optionId])
              .every((optionId) => {
                const option = articlesMap[optionId];
                if (
                  !option ||
                  !articleFilter(option, undefined, undefined, false, false, false, {
                    [optionId]: true,
                    ...articleIdsPath,
                  })
                ) {
                  result.children.push({
                    reason: `Either the option #${optionId} does not exist, or the option is itself filtered`,
                    children: [],
                  });
                  return true;
                } else {
                  return false;
                }
              })
          ) {
            result.children.push({
              reason: `Filtered because all options in required option group ${optionGroup.name}\x1B[90m#${optionGroup.id}\x1B[m are unavailable or filtered out`,
              children: [],
            });
            return true;
          }

          return false;
        })
      ) {
        return modelIsFiltered(
          article,
          `The article has at least one option group where all options are unavailable (either missing or filtered out),
and that option group requires at least one option to be selected (minCount > 0).);`,
          result
        );
      }

      result.isFiltered = false;
      return result;
    },
    [
      articleOrderHoursState,
      ageCheckIsDenied,
      useArrangements,
      serviceRequestArticles,
      articleArticlegroupsMap,
      allergens,
      hideItemsBySelectedAllergens,
      preferences,
      arrangement,
      isArticleAvailableInArrangement,
      articlegroupsMap,
      articlegroupOrderHoursState,
      articlesMap,
      optionGroupsMap,
      usePreferencesAndAllergens,
      useArrangementsFilter,
      useArticlegroupsFilter,
    ]
  );

  return useCallback(
    (
      article: Article,
      index?: number,
      array?: Article[],
      usePreferences: boolean = usePreferencesAndAllergens,
      useArrangementsF = useArrangementsFilter,
      useArticlegroupsF = useArticlegroupsFilter
    ) => {
      const printFilterResult = (result: Pick<FilterResult<Article>, "reason" | "children">) => {
        if (result.reason) {
          console.log(`${result.reason}`);
        }
        if (result.children) {
          result.children.forEach((child) => printFilterResult(child));
        }
      };

      const result = articleFilter(article, index, array, usePreferences, useArrangementsF, useArticlegroupsF);
      if (showReasons && result.isFiltered) {
        console.groupCollapsed(`Article ${article.name}#\x1B[90m${article.id}\x1B[m is filtered`);
        printFilterResult(result);
        console.groupEnd();
      }

      return !result.isFiltered;
    },
    [articleFilter, showReasons, useArrangementsFilter, useArticlegroupsFilter, usePreferencesAndAllergens]
  );
}

export function useArticlegroupFilter(
  {
    showReasons = false,
  }: {
    showReasons?: boolean;
  } = {
    showReasons: false,
  }
) {
  const articlegroupOrderHoursState = useAppSelector((state) => state.menuState.articlegroupOrderHours);
  const useArrangements = useAppSelector((state) => state.global.salesarea.use_arrangements);
  const onlyShowAllowedMenus = useAppSelector(
    (state) => state.global.salesarea.custom_data?.qr?.only_show_allowed_menus ?? true
  );
  const arrangement = useAppSelector(selectActiveArrangement);
  const articlegroupArticlesMap = useAppSelector(selectArticlegroupArticlesMap);
  const isArticlegroupAvailableInArrangement = useIsArticlegroupAvailableInArrangement(articlegroupArticlesMap);

  const articlegroupFilter = useCallback(
    (articlegroup: Articlegroup) => {
      const result: FilterResult<Articlegroup> = { isFiltered: false, children: [] };
      if (!articlegroup.isVisibleInJamezz) {
        return modelIsFiltered(articlegroup, `Articlegroup is not isVisibleInJamezz`, result);
      }
      if (!articlegroup.showInCategoryMenu) {
        return modelIsFiltered(articlegroup, `Articlegroup showInCategoryMenu is disabled`, result);
      }

      if (isQr() && useArrangements) {
        if (arrangement && !isArticlegroupAvailableInArrangement(articlegroup) && onlyShowAllowedMenus) {
          return modelIsFiltered(
            articlegroup,
            `Articlegroup is not available in this arrangement ${arrangement.name}\x1B[1m#${arrangement.id}\x1B[m`,
            result
          );
        }
      }

      if (articlegroupOrderHoursState[articlegroup.id] == false) {
        return modelIsFiltered(articlegroup, "The articlegroup falls outside of the configured order hours", result);
      }

      result.isFiltered = false;
      return result;
    },
    [
      arrangement,
      articlegroupOrderHoursState,
      isArticlegroupAvailableInArrangement,
      useArrangements,
      onlyShowAllowedMenus,
    ]
  );

  return useCallback(
    (articlegroup: Articlegroup) => {
      const printFilterResult = (result: Pick<FilterResult<Articlegroup>, "reason" | "children">) => {
        if (result.reason) {
          console.log(`${result.reason}`);
        }
        if (result.children) {
          result.children.forEach((child) => printFilterResult(child));
        }
      };

      const result = articlegroupFilter(articlegroup);
      if (showReasons && result.isFiltered) {
        console.groupCollapsed(`Articlegroup ${articlegroup.name}#\x1B[90m${articlegroup.id}\x1B[m is filtered`);
        printFilterResult(result);
        console.groupEnd();
      }

      return !result.isFiltered;
    },
    [articlegroupFilter, showReasons]
  );
}

export default useMenuFilters;
