import { useCallback, useEffect } from "react";
import Article from "../models/menu/Article";
import { getArticleById } from "../models/menu/Menu";
import SlideUpTransition from "../../components/SlideUpTransition";
import { useDialog } from "../dialog/DialogProvider";
import useMenuFilters from "../menu/filters/useMenuFilters";
import store, { useAppDispatch, useAppSelector } from "../redux/store";
import QrArticleSuggestionPage, {
  ArticleSuggestionPageProps,
} from "../../../qr/pages/ArticleSuggestionPage/ArticleSuggestionPage";
import useIsWidescreen from "../useIsWidescreen";
import { selectArticlesMap } from "../redux/selectors/selectArticlesMap";
import _ from "lodash";
import { OneTimeUpsellWhere } from "../redux/globalSlice";
import { oneTimeUpsellShown } from "../redux/upsellSlice";
import isKiosk from "../helpers/isKiosk.tsx";
import KioskArticleSuggestionPage from "../../../kiosk/pages/ArticleSuggestionPage/ArticleSuggestionPage.tsx";

export default function useOpenOneTimeUpsellDialog() {
  const { openDialog } = useDialog();
  const articleFilter = useMenuFilters();
  const isWidescreen = useIsWidescreen();

  const vatGroup = useAppSelector((state) => state.global?.sessionState?.vatGroup);
  const upsellOnOrderMode = useAppSelector(
    (state) => state.global?.salesarea?.upsellSettings?.oneTimeUpsell?.upsell_on_order_mode ?? "both"
  );

  return useCallback(
    (ArticleSuggestionPage: React.FC<ArticleSuggestionPageProps>) => {
      return new Promise<void>((resolve) => {
        const state = store.getState();
        const articlesMap = selectArticlesMap(state);
        const upsellSettings = state.global.salesarea.upsellSettings;
        let pageIsOpened = false;

        if (
          upsellSettings &&
          upsellSettings.is_active &&
          upsellSettings.oneTimeUpsell &&
          upsellSettings.oneTimeUpsell.isActive &&
          !_.isEmpty(articlesMap)
        ) {
          const productIds = upsellSettings.oneTimeUpsell.productIds;
          if (productIds) {
            const articles: Article[] = JSON.parse(productIds)
              .map((productId: number) => getArticleById(articlesMap, String(productId)))
              .filter(Boolean)
              .filter(articleFilter);
            const validArticlesCount = articles.filter((article) => {
              return !(article.isOutOfStock || article.isNotAvailable);
            }).length;

            if (
              (vatGroup === 1 && upsellOnOrderMode === "eatIn") ||
              (vatGroup === 2 && upsellOnOrderMode === "takeAway") ||
              upsellOnOrderMode === "both"
            ) {
              if (validArticlesCount > 0) {
                pageIsOpened = true;
                openDialog({
                  fullScreen: isKiosk() ? "almostFullScreen" : !isWidescreen,
                  children: (
                    <ArticleSuggestionPage
                      articles={articles}
                      title={upsellSettings.oneTimeUpsell.title}
                      text={upsellSettings.oneTimeUpsell.text}
                      upsellType={"OneTimeUpsell"}
                      translations={upsellSettings.oneTimeUpsell.translations}
                      onClose={resolve}
                    />
                  ),
                  TransitionComponent: SlideUpTransition,
                });
              }
            }
          }
        }
        if (!pageIsOpened) {
          resolve();
        }
      });
    },
    [articleFilter, openDialog, isWidescreen, vatGroup, upsellOnOrderMode]
  );
}

export function OnetimeUpsell({ where }: { where: OneTimeUpsellWhere }) {
  const openUpsell = useOpenOneTimeUpsellDialog();
  const shownAt = useAppSelector((state) => state.global.salesarea.upsellSettings?.oneTimeUpsell?.shownAt);
  const articlesMap = useAppSelector(selectArticlesMap);
  const isWidescreen = useIsWidescreen();
  const dispatch = useAppDispatch();
  const dontShow = useAppSelector((state) => state.upsell.oneTimeUpsellShown);

  useEffect(() => {
    if (shownAt) {
      const widescreenOnShoppingCart = shownAt === "onOpenShoppingCart" && isWidescreen;

      if (where === shownAt && !widescreenOnShoppingCart && !_.isEmpty(articlesMap) && !dontShow) {
        dispatch(oneTimeUpsellShown());
        openUpsell(isKiosk() ? KioskArticleSuggestionPage : QrArticleSuggestionPage);
      }
    }
  }, [articlesMap, dispatch, dontShow, isWidescreen, openUpsell, shownAt, where]);

  return null;
}
