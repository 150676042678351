import { useEffect, useRef, useState } from "react";
import { useAppSelector } from "../../utils/redux/store";
import useLoadExternalFile, { LoadingFileState } from "./useLoadExternalFile";

import { CircularProgress, Dialog, DialogContent, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { useCallSixpaymentsTimCallbackMutation, useWriteTerminalLogMutation } from "./timApiBackend";
import useStartTransaction from "./useStartTransaction.tsx";
import checkConnection from "./checkConnection.ts";
import { initializeTimApi } from "./initializeTimApi.tsx";

export enum TerminalState {
  loggedIn = "loggedIn",
  loggedOut = "loggedOut",
  disconnected = "disconnected",
}

export default function SixPaymentsMain() {
  const [writeLog] = useWriteTerminalLogMutation();
  const [callbackToServer] = useCallSixpaymentsTimCallbackMutation();
  const [timeoutPassed, setTimeoutPassed] = useState(false);
  const timApiState = useLoadExternalFile("/plugins/sixpaymentstim/4.17/timapi.js");

  const [ecrPayment, setEcrPayment] = useState<any>(undefined);
  const isTryingToConnect = useRef<boolean>(false);
  const isCheckingConnection = useRef<boolean>(false);
  const [connectionState, setConnectionState] = useState<TerminalState | undefined>(undefined);
  useStartTransaction(connectionState, ecrPayment, writeLog);
  const sixPaymentsTimTerminal = useAppSelector((state) => state.global.salesarea?.sixPaymentsTimTerminal);

  if (!window.onTimApiReady) {
    window.onTimApiReady = function () {
      window.timReady = true;
    };
  }
  // const [pinTerminalId, setPinTerminalId] = useState<string>("");
  // const [pinTerminalIpAddress, setPinTerminalIpAddress] = useState<string>("");

  useEffect(() => {
    const timeout = setTimeout(() => {
      setTimeoutPassed(true);
    }, 10000);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    let interval: any = null;
    if (ecrPayment?.terminal) {
      interval = setInterval(() => {
        if (!isCheckingConnection.current) {
          isCheckingConnection.current = true;
          const newConnectionState = checkConnection(ecrPayment);
          if (connectionState != newConnectionState) {
            setConnectionState(newConnectionState);
          }
          isCheckingConnection.current = false;
        }
      }, 5000);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [writeLog, connectionState, ecrPayment]);

  useEffect(() => {
    if (connectionState) {
      writeLog({
        type: "Connection status",
        data: "",
        result: connectionState ? connectionState.toString() : "undefined",
        status: connectionState === TerminalState.loggedIn ? 1 : 0,
      });
    }
  }, [connectionState, writeLog]);

  const connectToTerminal = () => {
    if (
      sixPaymentsTimTerminal &&
      timApiState === LoadingFileState.ready &&
      connectionState !== TerminalState.loggedIn &&
      !isTryingToConnect.current
    ) {
      if (!isTryingToConnect.current) {
        isTryingToConnect.current = true;
        console.log("TIM TRYING TO CONNECT!");
        const interval = setInterval(() => {
          console.log("TIM INTERVAL!");

          if (window.timReady === true) {
            clearInterval(interval);
            console.log("TIM CONNECTING!");
            const ecrPaymentI: any = {};
            const ecrPaymentRes = initializeTimApi(
              String(sixPaymentsTimTerminal.pinTerminalIpAddress ?? ""),
              String(sixPaymentsTimTerminal.pinTerminalId ?? ""),
              ecrPaymentI,
              writeLog,
              callbackToServer
            );

            setEcrPayment(ecrPaymentRes);
            isTryingToConnect.current = false;
          }
        }, 1000);
      }
    }
  };

  useEffect(() => {
    if (connectionState === TerminalState.disconnected || connectionState == undefined) {
      setTimeout(() => {
        connectToTerminal();
      }, 60000);
    }
  }, [connectionState]);

  useEffect(() => {
    if (!isTryingToConnect.current && (connectionState == TerminalState.disconnected || connectionState == undefined)) {
      connectToTerminal();
    }
  }, [sixPaymentsTimTerminal, timApiState, connectionState, writeLog, callbackToServer]);

  return (
    <Dialog open={connectionState !== TerminalState.loggedIn && timeoutPassed}>
      <DialogContent>
        <Box sx={{ display: "flex" }}>
          <Typography>Trying to connect to pin terminal...</Typography>
          {/*"SIX" {LoadingFileState.ready.toString()}{" "}*/}
          <CircularProgress />
        </Box>
      </DialogContent>
    </Dialog>
  );
}
