import OrderArticle from "../../../../global/utils/models/order/OrderArticle.ts";
import Box from "@mui/material/Box";
import Image from "../../../../global/components/Image.tsx";
import { useMemo } from "react";
import Article from "../../../../global/utils/models/menu/Article.ts";

export default function DisplayConfiguredMeal({
  orderArticle,
  currentStep,
}: {
  orderArticle: OrderArticle;
  currentStep: number;
}) {
  const article = orderArticle.article;

  const allOptionItems = useMemo<OrderArticle[]>(() => {
    return orderArticle.orderOptionGroups.slice(0, currentStep + 1).reduce((optionItems, orderOptionGroup) => {
      for (const optionItem of orderOptionGroup.orderArticles) {
        if (optionItem.count > 0) {
          const imageIndex = optionItem.article.mealConfiguratorSteps?.steps?.[currentStep]?.imageIndex;

          if (
            (imageIndex != null && optionItem.article.mealConfiguratorLayers?.[imageIndex]) ||
            optionItem.article.mealConfiguratorLayer?.conversions?.responsive?.srcset
          ) {
            optionItems.push(optionItem);
          }
        }
      }
      return optionItems;
    }, [] as OrderArticle[]);
  }, [currentStep, orderArticle.orderOptionGroups]);

  const startingImage = article.mealConfiguratorStart?.conversions?.responsive?.srcset;

  const sorted = useMemo(
    () => [...allOptionItems].sort((a, b) => getIndex(a.article, currentStep) - getIndex(b.article, currentStep)),
    [allOptionItems, currentStep]
  );

  return startingImage || allOptionItems.length > 0 ? (
    <Box className={"JS-DisplayConfiguredMeal-Root"} sx={{ position: "relative", width: 1, height: 400 }}>
      {startingImage ? (
        <Image
          srcSet={startingImage}
          style={{ position: "absolute", width: "100%", height: "100%", zIndex: 0, objectFit: "contain" }}
        />
      ) : null}
      {sorted.map((optionItem) => {
        return <OptionImage optionArticle={optionItem} currentStep={currentStep} />;
      })}
    </Box>
  ) : null;
}

function getIndex(article: Article, currentStep: number): number {
  return (
    article.mealConfiguratorSteps?.steps?.[currentStep].layerIndex ??
    article.customData?.meal_configurator?.layer_index ??
    Number.MIN_VALUE
  );
}

function OptionImage({ optionArticle, currentStep }: { optionArticle: OrderArticle; currentStep: number }) {
  const imageIndex = optionArticle.article.mealConfiguratorSteps?.steps?.[currentStep]?.imageIndex;

  if (imageIndex != null && optionArticle.article.mealConfiguratorLayers?.[imageIndex]) {
    return (
      <Image
        key={optionArticle.uuid}
        srcSet={optionArticle.article.mealConfiguratorLayers[imageIndex]?.conversions?.responsive?.srcset}
        style={{ position: "absolute", width: "100%", height: "100%", zIndex: 0, objectFit: "contain" }}
        useSkeletonLoading={false}
      />
    );
  }

  if (optionArticle.article.mealConfiguratorLayer?.conversions?.responsive?.srcset) {
    return (
      <Image
        key={optionArticle.uuid}
        srcSet={optionArticle.article.mealConfiguratorLayer?.conversions?.responsive?.srcset}
        style={{ position: "absolute", width: "100%", height: "100%", zIndex: 0, objectFit: "contain" }}
        useSkeletonLoading={false}
      />
    );
  } else {
    return null;
  }
}
