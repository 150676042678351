import { VoucherV2 } from "../../../../global/utils/vouchersV2/vouchersV2Slice.tsx";
import { useAppSelector } from "../../../../global/utils/redux/store.tsx";
import { selectSalesAreaPriceLineId } from "../../../../global/utils/useSalesAreaPriceLineId.ts";
import { selectArticleArticlegroupsMapUnfiltered } from "../../../../global/utils/redux/selectors/selectArticleArticlegroupsMapUnfiltered.ts";
import { selectArticlegroupsMap } from "../../../../global/utils/redux/selectors/selectArticlegroupsMap.ts";
import buildDiscountsRecursively from "../../../../global/utils/vouchersV2/selectors/buildDiscountsRecursively.ts";
import { useMemo } from "react";
import { calculateDiscountForVoucher } from "../../../../global/utils/vouchersV2/selectors/calculateDiscountForVoucher.ts";
import _ from "lodash";

export function usePotentialVouchers(vouchersV2: VoucherV2[]) {
  const priceLineId = useAppSelector(selectSalesAreaPriceLineId);
  const articleArticlegroupsMap = useAppSelector(selectArticleArticlegroupsMapUnfiltered);
  const articlegroupsMap = useAppSelector(selectArticlegroupsMap);
  const items = useAppSelector((state) => state.shoppingCart.items);
  const currentDiscounts: {
    [orderArticleUuid: string]: {
      discount: number;
    }[];
  } = buildDiscountsRecursively(items);

  return useMemo(() => {
    return vouchersV2.filter((voucherV2) => {
      const discounts = calculateDiscountForVoucher(
        voucherV2,
        items,
        priceLineId,
        currentDiscounts,
        articleArticlegroupsMap,
        articlegroupsMap
      );

      return _.chain(discounts).values().flattenDepth(1).sumBy("discount").value() > 0;
    });
  }, [articleArticlegroupsMap, articlegroupsMap, currentDiscounts, items, priceLineId, vouchersV2]);
}
