import { useCallback, useEffect, useMemo, useRef } from "react";
import { useLocation } from "react-router";
import useInputListener from "../../../global/utils/useInputListener.ts";
import { useDialog } from "../../../global/utils/dialog/DialogProvider.tsx";
import { createPath, routes } from "../../../global/utils/config.ts";
import useSalesareaIsOpen from "../../../global/utils/useSalesareaIsOpen.ts";
import useOrderModeSelector from "../../components/OrderModeSelector/useOrderModeSelector.ts";
import PleaseFirstMakeAChoiceDialog from "../../components/BarCodeScanner/PleaseFirstMakeAChoiceDialog.tsx";
import store, { useAppSelector } from "../../../global/utils/redux/store.tsx";
import { StringParam, useQueryParams } from "use-query-params";
import { debounce } from "lodash";
import { toast } from "react-toastify";
import { ChainPart, executeChain } from "./chainParts.ts";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz.tsx";
import { useBarcodeChainPart } from "./useBarcodeChainPart.ts";
import { useJamezzVoucherChainPart } from "./useJamezzVoucherChainPart.tsx";
import { usePriceKeyChainPart } from "./usePriceKeyChainPart.tsx";
import { useVoucherChainPart } from "./useVoucherChainPart.tsx";
import { usePiggyRewardChainPart } from "./usePiggyRewardChainPart.ts";
import { usePiggyGiftcardChainPart } from "./usePiggyGiftcardChainPart.ts";
import { BlockUserInput } from "@jamezz/react-components";

// Sometimes, the scanner scans the same barcode or qr code in very quick succession
// Debounce these instances to prevent duplicate barcode entry handling
export const DOUBLE_SCAN_PREVENTION_DEBOUNCE_MS = 1250;

export default function KioskInputListener() {
  const { orderModes } = useOrderModeSelector();

  const isScanning = useRef<boolean>(false);

  const orderModesLength = useMemo(() => {
    return orderModes?.length ?? 0;
  }, [orderModes]);
  const { openDialog } = useDialog();
  const { pathname } = useLocation();
  const isOpen = useSalesareaIsOpen();

  const isOnHomePage = useMemo(() => {
    return pathname === createPath(routes.kiosk.rootPath);
  }, [pathname]);
  const isOnMenuPage = useMemo(() => {
    return pathname === createPath(routes.kiosk.menuPath);
  }, [pathname]);

  const barcodeScannerEnabled =
    store.getState().global.salesarea?.custom_data?.kiosk?.enable_product_barcode_scanner ?? true;

  const barcodeChainPart = useBarcodeChainPart(barcodeScannerEnabled);
  const jamezzVoucherChainPart = useJamezzVoucherChainPart();
  const priceKeyChainPart = usePriceKeyChainPart();
  const voucherChainPart = useVoucherChainPart(isOnHomePage);
  const piggyRewardChainPart = usePiggyRewardChainPart(isOnHomePage);
  const piggyGiftcardChainPart = usePiggyGiftcardChainPart(isOnHomePage);

  const processBuffer = useCallback(
    (buffer: string) => {
      if (isScanning.current || BlockUserInput.isUserInputBlocked()) {
        return;
      }
      if (store.getState().global.eancodeUnknownDialogIsOpen || !store.getState().global.scanner_is_enabled) {
        return;
      }

      if (((orderModesLength ?? 1) > 1 || !barcodeScannerEnabled) && isOnHomePage) {
        // check if ordermode selection must be done before enable scanning barcodes
        openDialog({ children: <PleaseFirstMakeAChoiceDialog type={"NEED_TO_SELECT_ORDERMODE"} /> });
        return;
      }

      if (isOpen && (isOnMenuPage || isOnHomePage)) {
        const chainParts: ChainPart[] = [
          barcodeChainPart,
          jamezzVoucherChainPart,
          priceKeyChainPart,
          voucherChainPart,
          piggyRewardChainPart,
          piggyGiftcardChainPart,
        ];

        isScanning.current = true;
        executeChain(buffer, chainParts)
          .then((result) => {
            if (result === "not_found") {
              toast.error(
                <FormattedMessageJamezz id="Not recognized: {scannedCode}" values={{ scannedCode: buffer }} />
              );
            }
          })
          .finally(() => {
            isScanning.current = false;
          });
      }
    },
    [
      barcodeChainPart,
      barcodeScannerEnabled,
      isOnHomePage,
      isOnMenuPage,
      isOpen,
      jamezzVoucherChainPart,
      openDialog,
      orderModesLength,
      piggyGiftcardChainPart,
      piggyRewardChainPart,
      priceKeyChainPart,
      voucherChainPart,
    ]
  );

  const processDebouncedMethod = debounce(processBuffer, DOUBLE_SCAN_PREVENTION_DEBOUNCE_MS, { leading: true });

  const onSubmit = useCallback(processDebouncedMethod, [processDebouncedMethod]);

  window.onScanCode = onSubmit;
  useInputListener(onSubmit);

  return <UpdateSelectedArticlegroupParam />;
}

function UpdateSelectedArticlegroupParam() {
  const [, setQueryParam] = useQueryParams({ selectedArticlegroupName: StringParam });
  const selectedArticlegroup = useAppSelector((state) => state.menu.selectedArticlegroup);
  useEffect(() => {
    if (store.getState().global.salesarea.custom_data?.kiosk?.selected_articlegroup_name_in_kiosk_url) {
      setQueryParam({ selectedArticlegroupName: selectedArticlegroup?.name }, "replaceIn");
    }
  }, [selectedArticlegroup, setQueryParam]);
  return null;
}
