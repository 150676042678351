import { ButtonBase, IconButton, Typography } from "@mui/material";
import { useCallback, useMemo } from "react";
import OrderOptionGroup, { isMoreValid } from "../../../../global/utils/models/order/OrderOptionGroup";
import OrderArticle, { isOrderArticleValidAfterSum } from "../../../../global/utils/models/order/OrderArticle";
import Image from "../../../../global/components/Image";
import Box from "@mui/material/Box";
import { getArticleName, getArticlePrice } from "../../../../global/utils/models/menu/Article";
import useMenuTagsForArticle from "../../../../global/utils/hooks/useMenuTagsForArticle";
import MenuTag, { useHasFilteredAllergens } from "../../../../global/utils/models/menu/MenuTag";
import MenuTagView from "../../../../qr/components/MenuTagView";
import { useOptionTranslations } from "../../../../global/utils/translations/useOptionTranslations";
import _ from "lodash";
import { useIntl } from "react-intl";
import { Info } from "@mui/icons-material";
import JTooltip from "../../../components/JamezzMUI/JTooltip";
import useActiveMenuTags from "../../../../global/utils/menu/useActiveMenuTags";
import { optionItemMenuTagsStyles } from "../OptionGroups/OptionGroupCountable";
import { useDialog } from "../../../../global/utils/dialog/DialogProvider";
import ArticleInformationDialog from "../../../components/ArticleInformationDialog/ArticleInformationDialog";
import { useAppSelector } from "../../../../global/utils/redux/store.tsx";
import { selectAppLanguage } from "../../../components/LanguageSelector/useLanguage.ts";
import clsx from "clsx";
import { selectSalesAreaPriceLineId } from "../../../../global/utils/useSalesAreaPriceLineId.ts";
import FormatCurrency from "../../../../global/components/Currencies/FormatCurrency.tsx";

interface Props {
  orderOptionGroup: OrderOptionGroup;
  orderArticle: OrderArticle;
  onChange: (orderArticle: OrderArticle) => void;

  columnIndex: number;
}

function OptionItemCountable(props: Props) {
  const { onChange, orderArticle } = props;
  const lang = useAppSelector(selectAppLanguage);
  const menuTags = useMenuTagsForArticle(props.orderArticle.article);
  const { translate } = useOptionTranslations();
  const intl = useIntl();
  const isValidAfterCount = useCallback(
    (count: number) => {
      return (
        isMoreValid(props.orderOptionGroup, props.orderArticle.article, count) &&
        isOrderArticleValidAfterSum(props.orderArticle, count)
      );
    },
    [props.orderArticle, props.orderOptionGroup]
  );

  const isValidAfterPlusOne = useMemo(() => {
    return isValidAfterCount(1);
  }, [isValidAfterCount]);

  const isValidAfterMinusOne = useMemo(() => {
    return isValidAfterCount(-1);
  }, [isValidAfterCount]);

  const isNotAvailable = props.orderArticle.article.isNotAvailable || props.orderArticle.article.isOutOfStock;

  const addCount = useCallback(
    (addCount: number) => {
      const copy = JSON.parse(JSON.stringify(orderArticle)) as OrderArticle;
      copy.count += addCount;
      onChange(copy);
    },
    [onChange, orderArticle]
  );

  const activeMenuTags = useActiveMenuTags(props.orderArticle.article);
  const hasFilteredAllergen = useHasFilteredAllergens(activeMenuTags);
  const { openDialog } = useDialog();
  const priceLineId = useAppSelector(selectSalesAreaPriceLineId);
  const price = getArticlePrice(props.orderArticle.article, { salesAreaPriceLineId: priceLineId });

  return (
    <>
      <JTooltip title={isNotAvailable ? intl.formatMessage({ id: "OptionItem.notAvailable" }) : ""} placement={"top"}>
        <Box
          className={clsx("JS-OptionItemCountable-Root", "JS-OptionItemGeneric-Root")}
          sx={{
            gridArea: `${1} / ${props.columnIndex + 1} / ${6} / ${props.columnIndex + 2}`,
          }}
          onClick={() => {
            if (isNotAvailable) return;
            if (isValidAfterPlusOne) {
              addCount(1);
            }
          }}
        ></Box>
      </JTooltip>

      {props.orderArticle.article.imageMediaUrls?.[0]?.conversions?.responsive?.srcset ? (
        <Box
          sx={{
            height: "200px",
            pointerEvents: "none",
            zIndex: 1,
            gridArea: `${1} / ${props.columnIndex + 1} / ${2} / ${props.columnIndex + 2}`,
            opacity: isNotAvailable ? "0.5" : "auto",
          }}
        >
          <Image
            srcSet={props.orderArticle.article.imageMediaUrls?.[0]?.conversions?.responsive?.srcset}
            style={{ width: "100%", height: "100%", objectFit: "contain" }}
          />
        </Box>
      ) : (
        <Box />
      )}
      {props.orderArticle.article.description ? (
        <Box
          sx={{
            height: "200px",
            pointerEvents: "none",
            zIndex: 1,
            width: 1,
            gridArea: `${1} / ${props.columnIndex + 1} / ${2} / ${props.columnIndex + 2}`,
            display: "inline-flex",
            alignItems: "flex-start",
            justifyContent: "flex-end",
            filter: hasFilteredAllergen ? "opacity(0.6)" : "",
            opacity: isNotAvailable ? "0.5" : "auto",
          }}
        >
          <IconButton
            sx={{ pointerEvents: "all" }}
            onClick={(e) => {
              e.stopPropagation();
              openDialog({
                children: <ArticleInformationDialog article={props.orderArticle.article} />,
              });
            }}
          >
            <Info />
          </IconButton>
        </Box>
      ) : null}

      <Box
        sx={{
          textAlign: "center",
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          pointerEvents: "none",
          wordBreak: "break-word",
          zIndex: 1,
          gridArea: `${2} / ${props.columnIndex + 1} / ${3} / ${props.columnIndex + 2}`,
          filter: hasFilteredAllergen ? "opacity(0.6)" : "",
          opacity: isNotAvailable ? "0.5" : "auto",
        }}
      >
        <Typography
          className={clsx(
            "JS-OptionItemCountable-Text",
            props.orderArticle.count ? "JS-OptionItemCountable-Text-selected" : null
          )}
          variant={"subtitle2"}
        >
          {!_.isEmpty(props.orderArticle.article.translations)
            ? getArticleName(props.orderArticle.article, lang)
            : translate(props.orderArticle.article.name)}
        </Typography>
      </Box>
      <Box
        sx={{
          width: "100%",
          // minHeight: 48,
          visibility: price === 0 ? "collapse" : null,
          pointerEvents: "none",
          zIndex: 1,
          gridArea: `${3} / ${props.columnIndex + 1} / ${4} / ${props.columnIndex + 2}`,
          filter: hasFilteredAllergen ? "opacity(0.6)" : "",
          opacity: isNotAvailable ? "0.5" : "auto",
        }}
        className={clsx(
          "JS-OptionItemCountable-PriceWrapper",
          props.orderArticle.count ? "JS-OptionItemCountable-PriceWrapper-selected" : null
        )}
      >
        {price !== 0 ? (
          <Typography
            variant={"subtitle2"}
            className={clsx(
              "JS-OptionItemCountable-Price",
              props.orderArticle.count ? "JS-OptionItemCountable-Price-selected" : null
            )}
            sx={{ fontSize: 24, fontStyle: "italic", opacity: 0.6, textAlign: "center", wordWrap: "noWrap" }}
          >
            <FormatCurrency value={price} options={{ signDisplay: "always" }} />
          </Typography>
        ) : null}
      </Box>
      {isNotAvailable ? (
        !props.orderArticle.article.description ? (
          <Info
            fontSize="small"
            sx={{
              pointerEvents: "none",
              gridArea: `${1} / ${props.columnIndex + 1} / ${1} / ${props.columnIndex + 2}`,
            }}
          />
        ) : null
      ) : (
        <Box
          className={clsx(
            "JS-OptionItemCountable-ControlBox",
            props.orderArticle.count ? "JS-OptionItemCountable-ControlBox-selected" : null
          )}
          sx={{
            justifySelf: "center",
            width: "80%",
            marginTop: 2,
            minHeight: 48,
            display: "flex",
            borderRadius: 8,
            overflow: "hidden",
            color: props.orderArticle.count > 0 ? "primary.contrastText" : null,
            backgroundColor: props.orderArticle.count > 0 ? "primary.light" : null,
            border: props.orderArticle.count === 0 ? "1px solid" : "none",
            borderColor: "grey.400",
            // pointerEvents: "none",
            zIndex: 1,
            gridArea: `${4} / ${props.columnIndex + 1} / ${5} / ${props.columnIndex + 2}`,
            filter: hasFilteredAllergen ? "opacity(0.6)" : "",
            boxShadow: "0 1px 5px 0px rgba(0,0,0,0.2)",
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <ButtonBase
            className={clsx(
              "JS-OptionItemCountable-plus-minus",
              props.orderArticle.count ? "JS-OptionItemCountable-plus-minus-selected" : null
            )}
            sx={{
              height: 1,
              flex: "1 1 0%",
              visibility: !isValidAfterMinusOne ? "collapse" : null,
            }}
            onClick={(e) => {
              e.stopPropagation();
              addCount(-1);
            }}
          >
            <Typography
              variant={"subtitle2"}
              className={clsx(
                "JS-OptionItemCountable-plus-minus-text",
                props.orderArticle.count ? "JS-OptionItemCountable-plus-minus-text-selected" : null
              )}
            >
              -
            </Typography>
          </ButtonBase>
          <Typography
            className={clsx(
              "JS-OptionItemCountable-count",
              props.orderArticle.count ? "JS-OptionItemCountable-count-selected" : null
            )}
            variant={"subtitle2"}
            sx={{ height: 1, flex: "1 1 0%", textAlign: "center" }}
          >
            {props.orderArticle.count}
          </Typography>
          <ButtonBase
            className={clsx(
              "JS-OptionItemCountable-plus-minus",
              props.orderArticle.count ? "JS-OptionItemCountable-plus-minus-selected" : null
            )}
            sx={{ height: 1, flex: "1 1 0%", visibility: !isValidAfterPlusOne ? "collapse" : null }}
            onClick={(e) => {
              e.stopPropagation();
              addCount(1);
            }}
            data-cy={
              "OptionItem-Button-" +
              getArticleName(props.orderArticle.article, lang) +
              "-" +
              props.orderOptionGroup.optionGroup.name
            }
          >
            <Typography
              variant={"subtitle2"}
              className={clsx(
                "JS-OptionItemCountable-plus-minus-text",
                props.orderArticle.count ? "JS-OptionItemCountable-plus-minus-text-selected" : null
              )}
            >
              +
            </Typography>
          </ButtonBase>
        </Box>
      )}
      {menuTags?.length > 0 ? (
        <Box
          sx={{
            ...optionItemMenuTagsStyles(),
            gridArea: `${5} / ${props.columnIndex + 1} / ${6} / ${props.columnIndex + 2}`,
            filter: hasFilteredAllergen ? "opacity(0.6)" : "",
            opacity: isNotAvailable ? "0.5" : "auto",
          }}
        >
          {menuTags.map((tag: MenuTag) => {
            return (
              <MenuTagView
                key={tag.id}
                menuTag={tag}
                width={48}
                margin={"4px"}
                clickable={false}
                placement={"top-end"}
              />
            );
          })}
        </Box>
      ) : null}
    </>
  );
}

export default OptionItemCountable;
