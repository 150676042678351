import Box from "@mui/material/Box";
import { Checkbox, FormControlLabel, Stack, Typography } from "@mui/material";
import { useMemo } from "react";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz";
import { useAppDispatch, useAppSelector } from "../../../global/utils/redux/store";
import CloseDialogButton from "../../components/Dialog/CloseDialogButton.tsx";
import { enabledHideItemsBySelectedAllergens } from "../../../global/utils/redux/globalSlice";
import { useCustomTexts } from "../../../global/utils/useCustomTexts";
import { unstable_LowPriority, unstable_scheduleCallback } from "scheduler";
import MenuFilterV2 from "./MenuFilterV2.tsx";
import { usePreferencesAndAllergens } from "../../../global/utils/menu/usePreferencesAndAllergens.ts";

export default function MenuFilterDialogV2() {
  const dispatch = useAppDispatch();
  const salesarea = useAppSelector((state) => state.global.salesarea);
  const hideItemsBySelectedAllergens = useAppSelector((state) => state.global.hideItemsBySelectedAllergens);

  const { preferences: myPreferences, allergens: myAllergens } = usePreferencesAndAllergens();

  const extraProductIds = useMemo(() => {
    return salesarea?.custom_data?.allergen_product_map ?? {};
  }, [salesarea]);
  const ct = useCustomTexts([
    "allergens_subtext",
    "tag_preferences_subtext",
    "allergens page - title",
    "allergens page - hide toggle button text",
  ]);

  return (
    <Box
      data-cy="allergen-page"
      sx={{
        width: 1,
        minHeight: 1,
        overflowY: "auto",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 4,
      }}
    >
      <CloseDialogButton />

      <Stack spacing={3} alignItems={"center"}>
        {myPreferences.length > 0 ? (
          <>
            <Typography
              className={"JS-MenuFilterDialog-PreferencesTitle"}
              variant={"h5"}
              sx={{ fontStyle: "italic", fontWeight: 800, textAlign: "center" }}
            >
              <FormattedMessageJamezz id={"My preferences"} />
            </Typography>
            <Typography className={"JS-MenuFilterDialog-Preference-Subtext"} sx={{ marginTop: 2, textAlign: "center" }}>
              {ct["tag_preferences_subtext"] ?? <FormattedMessageJamezz id={"Do you have any diet preferences?"} />}
            </Typography>

            <Box
              sx={{
                maxWidth: 300,
                width: 1,
                display: "grid",
                gridTemplateRows: `repeat(${myPreferences.length}, 50px)`,
                gridTemplateColumns: "[image] 20% [text] auto [checkbox] 20%",
                rowGap: 1,
                columnGap: 1,
              }}
            >
              {myPreferences.map((menuTag, index) => (
                <MenuFilterV2 menuTag={menuTag} key={menuTag.id} index={index} filterType={"PREFERENCE"} />
              ))}
            </Box>
          </>
        ) : null}

        {myAllergens.length > 0 ? (
          <>
            <Typography
              className={"JS-MenuFilterDialog-AllergiesTitle"}
              variant={"h5"}
              sx={{ fontStyle: "italic", fontWeight: 800, textAlign: "center" }}
            >
              {ct["allergens page - title"] ?? <FormattedMessageJamezz id={"My allergies"} />}
            </Typography>

            <Typography className={"JS-MenuFilterDialog-Allergens-Subtext"} sx={{ marginTop: 2 }}>
              {ct["allergens_subtext"] ?? <FormattedMessageJamezz id={"Do you have any allergies?"} />}
            </Typography>

            <FormControlLabel
              control={<Checkbox />}
              labelPlacement={"start"}
              label={
                ct["allergens page - hide toggle button text"] ?? (
                  <FormattedMessageJamezz id={"Hide items taking into account your allergies"} />
                )
              }
              sx={{
                ".MuiFormControlLabel-label": { fontSize: "0.8rem" },
                maxWidth: 300,
                width: 1,
                paddingRight: "9px",
              }}
              defaultChecked={hideItemsBySelectedAllergens}
              onChange={(e: any) => {
                unstable_scheduleCallback(unstable_LowPriority, function () {
                  dispatch(enabledHideItemsBySelectedAllergens(e.target.checked));
                });
              }}
            />

            <Box
              sx={{
                width: 1,
                maxWidth: 300,
                display: "grid",
                gridTemplateRows: `repeat(${myAllergens.length}, 50px)`,
                gridTemplateColumns: "[image] 20% [text] auto [checkbox] 20%",
                rowGap: 1,
                columnGap: 1,
              }}
            >
              {myAllergens.map((menuTag, index) => (
                <MenuFilterV2
                  menuTag={menuTag}
                  key={menuTag.id}
                  index={index}
                  articleId={extraProductIds[menuTag.id] ? String(extraProductIds[menuTag.id]) : undefined}
                  filterType={"ALLERGY"}
                />
              ))}
            </Box>
          </>
        ) : null}
      </Stack>
    </Box>
  );
}
