import { Route, Routes } from "react-router-dom";
import { createPath, routes } from "../global/utils/config";
import { useEffect } from "react";
import MenuPage from "./pages/MenuPage/MenuPage";
import MenuArticleOrderHours from "../global/utils/menu/filters/MenuArticleOrderHours";

import ReturnPage, { ReturnPageWrapper } from "./pages/ReturnPage/ReturnPage";
import TopBar from "./components/TopBar";
import { useAppSelector } from "../global/utils/redux/store";
import usePriceKeyChecker from "../kiosk/utils/usePriceKeyChecker";
import useAnalytics from "../global/utils/analytics/useAnalytics";

import JamezzLogo from "../assets/images/Logo.svg?react";
import LoadingDots from "../assets/images/LoadingDots.svg?react";
import useAppNavigate from "../global/utils/useAppNavigate";
import useServerSideShoppingCart from "../global/utils/hooks/useServerSideShoppingCart";
import useCheckPayment from "../global/utils/useCheckPayment";
import PageNotFound from "../global/components/PageNotFound";
import PiggySessionWatcher from "./components/Piggy/PiggySessionWatcher";
import useCheckTableIsOpenInPos from "../global/utils/hooks/useCheckTableIsOpenInPos";
import useLoadPackagings from "../global/utils/hooks/useLoadPackagings";
import useLoadMenuData from "../global/utils/hooks/useLoadMenuData";
import { useIntl } from "react-intl";
import { globalIntl } from "./App";
import { ArrangementsLoader } from "./utils/ArrangementsLoader.tsx";
import { TicketUpdatedListener, useMenuUpdatedListener } from "../global/utils/ageCheck/useWebsocketChannel";
import usePiggyLogin from "../kiosk/utils/piggy/usePiggyLogin";
import { useOpenStateAndDuration } from "../global/utils/useSalesareaIsOpen.ts";
import useLanguage from "../kiosk/components/LanguageSelector/useLanguage.ts";
import TicketPincode from "./components/TicketPincode/TicketPincode.tsx";
import Websockets from "../global/utils/Websockets.tsx";
import DefaultVoucherCode from "../global/utils/hooks/DefaultVoucherCode.tsx";
import { LastQueuedOrder } from "./components/LastQueuedOrder/LastQueuedOrder.tsx";

function QrRouter() {
  useAnalytics();

  globalIntl.object = useIntl();
  const order = useAppSelector((state) => state.global.order);
  const salesareaId = useAppSelector((state) => state.global.salesarea?.id);
  useAppSelector((state) => state.global.appStyling);
  // const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(true);

  if (!salesareaId) {
    return (
      <div
        style={{
          position: "fixed",
          top: 0,
          height: "95vh",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ width: "100%", maxWidth: 200 }}>
          <JamezzLogo />
        </div>
        <div style={{ marginLeft: "auto", marginRight: "auto", width: 100 }}>
          <LoadingDots />
        </div>
      </div>
    );
  }

  return (
    <>
      <MenuArticleOrderHours />

      <PiggySessionWatcher />
      <TopBar />
      <HooksAfterLoading />
      {order && order?.paymentError !== 1 && !window.location.href.includes(createPath(routes.qr.returnPath)) ? (
        <CheckOrderStatus />
      ) : null}
      <TicketPincode />
      <Routes>
        <Route path={routes.qr.redirectPath} element={<NavigateToWebcode />}></Route>
        <Route path={routes.qr.rootPath} element={<MenuPage />}></Route>
        <Route path={routes.qr.checkoutPath} element={<MenuPage />}></Route>
        <Route path={routes.qr.returnPath} element={<ReturnPageWrapper />}></Route>
        <Route path={routes.qr.returnPathAfterPay} element={<ReturnPage afterPay={true} />}></Route>
        <Route path="*" element={<PageNotFound redirectToHome={true} />} />
      </Routes>
    </>
  );
}

export function NavigateToWebcode() {
  const webcode = useAppSelector((state) => state.global.webcode);

  const navigate = useAppNavigate();
  useEffect(() => {
    setTimeout(() => {
      if (webcode) {
        navigate(webcode, { replace: true });
      }
    }, 500);
  }, [navigate, webcode]);

  return null;
}

function HooksAfterLoading() {
  const reloadFromQueue = useAppSelector((state) => state.global.salesarea.reloadFromQueue);
  usePriceKeyChecker();
  useServerSideShoppingCart();
  useCheckTableIsOpenInPos();
  useLoadPackagings();
  useLoadMenuData();
  useMenuUpdatedListener();
  usePiggyLogin();
  useOpenStateAndDuration();
  useLanguage();

  return (
    <>
      <ArrangementsLoader />
      <Websockets />
      <DefaultVoucherCode />
      <TicketUpdatedListener />
      {reloadFromQueue ? <LastQueuedOrder /> : null}
    </>
  );
}
function CheckOrderStatus() {
  const { orderState, responseData } = useCheckPayment();
  return null;
}

export default QrRouter;
