import { rtkQueryApi } from "./rtkQueryApi.tsx";

const legacyEndpoints = rtkQueryApi.enhanceEndpoints({
  addTagTypes: [],
});

type LastOrder = { id: number; items: { pid: number; aantal: number; product: { pk: string[] } }[] };
type LastOrderResponse = { data: LastOrder };

const legacyApi = legacyEndpoints.injectEndpoints({
  endpoints: (builder) => ({
    fetchLastQueuedOrder: builder.query<LastOrderResponse, string | undefined>({
      // DO NOT REMOVE shoppingCartUuid, for caching purposes
      query: (shoppingCartUuid) => ({ url: "/request/products/getlastqueued", method: "GET" }),
    }),
  }),
});

export const { useFetchLastQueuedOrderQuery } = legacyApi;
