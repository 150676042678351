import { useCallback } from "react";
import OrderArticle, { getTotalPrice } from "../../../global/utils/models/order/OrderArticle";
import { Chip } from "@mui/material";
import { getArticleName } from "../../../global/utils/models/menu/Article";
import { useOptionTranslations } from "../../../global/utils/translations/useOptionTranslations";
import _ from "lodash";
import { useAppSelector } from "../../../global/utils/redux/store.tsx";
import { selectAppLanguage } from "../../components/LanguageSelector/useLanguage.ts";
import { selectSalesAreaPriceLineId } from "../../../global/utils/useSalesAreaPriceLineId.ts";
import { useCurrency } from "../../../global/components/Currencies/useCurrency.ts";

export default function usePrintOptionOrderArticle(
  className: "JS-OrderArticleContent-Option" | "JS-OrderArticleSelectorPage-Option"
) {
  const lang = useAppSelector(selectAppLanguage);
  const { translate } = useOptionTranslations();
  const priceLineId = useAppSelector(selectSalesAreaPriceLineId);
  const formatCurrency = useCurrency();
  return useCallback(
    (orderArticle: OrderArticle, count = 1) => {
      const countPrefix = orderArticle.count !== 1 ? orderArticle.count + " x " : "";
      const amount = getTotalPrice(orderArticle, priceLineId) * count;
      const amountSuffix = amount !== 0 ? " " + formatCurrency(amount) : "";
      return (
        <Chip
          className={className}
          key={orderArticle.uuid}
          sx={{ backgroundColor: "primary.light", padding: 3, marginX: 1, marginBottom: 1, color: "white" }}
          label={
            <>
              {countPrefix +
                (!_.isEmpty(orderArticle.article.translations)
                  ? getArticleName(orderArticle.article, lang)
                  : translate(orderArticle.article.name))}
              <span className={className + "-Amount"}>{amountSuffix}</span>
            </>
          }
        />
      );
    },
    [priceLineId, formatCurrency, className, lang, translate]
  );
}
