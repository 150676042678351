import { TextField } from "@mui/material";
import { useContext } from "react";
import { OrderArticleContext } from "../../../global/utils/contexts/OrderArticleContext.ts";
import { RootState, useAppSelector } from "../../../global/utils/redux/store.tsx";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz.tsx";

export const OrderArticleNoteField = () => {
  const { orderArticle, setOrderArticle } = useContext(OrderArticleContext);
  const allowNotes = useAppSelector((state: RootState) => state.global.salesarea.custom_data?.appv5?.allow_item_notes);
  if (!orderArticle || !allowNotes) {
    return null;
  }
  return (
    <TextField
      sx={{ my: 2, width: 0.9, maxWidth: 400 }}
      multiline={true}
      label={<FormattedMessageJamezz id={"item_note"} />}
      rows={2}
      slotProps={{ htmlInput: { style: { fontSize: 14 }, maxLength: 140 } }}
      value={orderArticle.note}
      onChange={(e) => setOrderArticle?.({ ...orderArticle, note: e.target.value })}
    />
  );
};
