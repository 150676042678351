import { useCallback } from "react";
import { createReturnPath } from "./config";
import OrderArticle, { initOrderArticle } from "./models/order/OrderArticle";
import { selectAppLanguage } from "../../kiosk/components/LanguageSelector/useLanguage";
import store from "./redux/store";
import { selectArticlesMap } from "./redux/selectors/selectArticlesMap";
import { checkArrangementRulesResponse, sendCheckout } from "./useSendCheckout2.tsx";
import { useIntl } from "react-intl";
import { toast } from "react-toastify";

export default function useSendArticles(
  { ignoreSharedShoppingCart = true }: { ignoreSharedShoppingCart: boolean } = { ignoreSharedShoppingCart: true }
) {
  const intl = useIntl();
  return useCallback(
    (articleIds: string[]) => {
      return new Promise<boolean>((resolve) => {
        const articlesMap = selectArticlesMap(store.getState());
        const orderArticles: OrderArticle[] = [];
        articleIds.forEach((articleId) => {
          const article = articlesMap[articleId];
          if (article) {
            orderArticles.push(initOrderArticle(articlesMap, article, 1));
          }
        });

        const selectedLanguage = selectAppLanguage(store.getState());

        sendCheckout({
          piggy_giftcards: [],
          piggy_prepaids: [],
          items: orderArticles,
          orderCustomFields: [],
          voucherOrderItems: [],
          returnUrl: createReturnPath(),
          selectedLanguage,
          discountCardNr: null,
          ignoreSharedShoppingCart,
        })
          .then((response) => {
            if (!("checkResults" in response.data.data) && "reason" in response.data.data) {
              checkArrangementRulesResponse(response.data.data, intl);
            } else {
              resolve(true);
            }
            console.log("RESPONSE");
          })
          .catch((err) => {
            if (err.status === 404) {
              toast.error(intl.formatMessage({ id: "AYCE.error.heading.no_arrangement_loaded" }));
              console.log(err);
            }
          });
      });
    },
    [ignoreSharedShoppingCart, intl]
  );
}
