import { useCallback, useEffect } from "react";
import store, { useAppDispatch, useAppSelector } from "../redux/store";
import OrderArticle, { OrderArticleOrigin } from "../models/order/OrderArticle";
import { getArticleById } from "../models/menu/Menu";
import { orderArticlesPushedBySystem } from "../redux/shoppingCartSlice";
import _ from "lodash";

import Article, { getArticleName } from "../models/menu/Article";
import { selectArticlesMap } from "../redux/selectors/selectArticlesMap";
import useMenuFilters from "../menu/filters/useMenuFilters.ts";
import { toast } from "react-toastify";
import { useIntl } from "react-intl";
import { selectAppLanguage } from "../../../kiosk/components/LanguageSelector/useLanguage.ts";

function ShoppingCartChecker() {
  const orderArticles: OrderArticle[] = useAppSelector((state) => state.shoppingCart.items);
  const priceKey = useAppSelector((state) => state.menu.priceKey);
  const dispatch = useAppDispatch();
  const articlesMap = useAppSelector(selectArticlesMap);
  const articleFilter = useMenuFilters();
  const optionFilter = useMenuFilters({ useArrangementsFilter: false, useArticlegroupsFilter: false });
  const intl = useIntl();

  useEffect(() => {
    const removedArticles: Article[] = [];
    if (Object.keys(articlesMap).length > 0) {
      orderArticles
        .filter((item) => item.added_origin == "MENU")
        .forEach((orderArticle: OrderArticle) => {
          const article = getArticleById(articlesMap, orderArticle.article.id);

          if (
            !article ||
            !articleFilter(article) ||
            orderArticle.orderOptionGroups.some((orderOptionGroup) =>
              orderOptionGroup.orderArticles
                .filter((orderArticle) => orderArticle.count)
                .some((orderArticle) => {
                  const option = getArticleById(articlesMap, orderArticle.article.id);

                  return !option || !optionFilter(option);
                })
            )
          ) {
            const copyOrderArticle = _.cloneDeep(orderArticle);
            copyOrderArticle.count = -copyOrderArticle.count;
            dispatch(orderArticlesPushedBySystem([copyOrderArticle]));

            removedArticles.push(copyOrderArticle.article);
          }
        });
    }

    if (removedArticles.length) {
      let failedItems = "";
      const lang = selectAppLanguage(store.getState());
      removedArticles.forEach((article) => {
        failedItems += getArticleName(article, lang) + "\n";
      });
      toast.error(
        intl.formatMessage(
          {
            id: "OrderChecker.messages.items-were-removed-with-items",
          },
          { removedItems: failedItems }
        )
      );
    }
  }, [dispatch, articlesMap, orderArticles, articleFilter, optionFilter, intl]);

  const checkChildOrderArticles = useCallback(
    (articlesMap: PartialRecord<string, Article>, priceKey: string, orderArticles: OrderArticle[]) => {
      let someUpdated = false;
      orderArticles.forEach((orderArticle) => {
        const article = getArticleById(articlesMap, orderArticle.article.id);
        if (article) {
          if (orderArticle.article.price !== article.price && !orderArticle.article.hasCustomizedPriceAndName) {
            orderArticle.article.price = article.price;
            orderArticle.article.originalPrice = article.originalPrice;
            orderArticle.article.discounts = article.discounts;

            someUpdated = true;
          }
          orderArticle.orderOptionGroups.forEach((orderOptionGroup) => {
            if (checkChildOrderArticles(articlesMap, priceKey, orderOptionGroup.orderArticles)) {
              someUpdated = true;
            }
          });
        }
      });
      return someUpdated;
    },
    []
  );

  useEffect(() => {
    orderArticles.forEach((orderArticle: OrderArticle) => {
      if (articlesMap) {
        const article = getArticleById(articlesMap, orderArticle.article.id);
        if (article) {
          let childrenUpdated = false;
          let copyOrderArticle: OrderArticle = _.cloneDeep(orderArticle);

          copyOrderArticle.orderOptionGroups.forEach((orderOptionGroup) => {
            if (
              checkChildOrderArticles(
                articlesMap,
                priceKey,
                orderOptionGroup.orderArticles.filter((orderArticle) => orderArticle.count)
              )
            ) {
              childrenUpdated = true;
            }
          });

          if (
            (orderArticle.article.price !== article.price &&
              !orderArticle.article.hasCustomizedPriceAndName &&
              orderArticle.added_origin !== OrderArticleOrigin.SYSTEM) ||
            childrenUpdated
          ) {
            const removableCopy: OrderArticle = _.cloneDeep(orderArticle);
            const count = removableCopy.count;
            removableCopy.count = -count;
            dispatch(orderArticlesPushedBySystem([removableCopy]));
            copyOrderArticle = _.cloneDeep(copyOrderArticle);
            copyOrderArticle.article.price = article.price;
            copyOrderArticle.article.originalPrice = article.originalPrice;
            copyOrderArticle.article.discounts = article.discounts;
            copyOrderArticle.count = count;
            dispatch(orderArticlesPushedBySystem([copyOrderArticle]));
          }
        }
      }
    });
  }, [dispatch, articlesMap, priceKey, orderArticles, checkChildOrderArticles]);

  return null;
}

export default ShoppingCartChecker;
