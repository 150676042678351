import { BoxProps, darken, Divider, Skeleton, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import PiggyRewardItem from "./Rewards/RewardItem/PiggyRewardItem.tsx";
import FormattedMessageJamezz from "../../../../global/components/FormattedMessageJamezz.tsx";
import { usePiggyRewards } from "../../../../global/utils/piggy/usePiggyRewards.ts";
import { useAppSelector } from "../../../../global/utils/redux/store.tsx";
import { useFetchPointsBalanceQuery } from "../../../../global/utils/redux/api/piggyApi.ts";
import { selectNumberOfCreditsOfPiggyRewardsToBeClaimed } from "../../../../global/utils/redux/selectors/selectPiggyRewardsToBeClaimed.tsx";

export default function PiggyRewardsV1({
  showBalance,
  wrapperSx,
}: {
  showBalance: boolean;
  wrapperSx?: BoxProps["sx"];
}) {
  const piggyLoyaltyEnabled = useAppSelector((state) => state.global.salesarea.piggy.loyalty.enabled);
  const contactIdentifierScan = useAppSelector((state) => state.piggy.contactIdentifierScan);
  const {
    data: balance,
    isLoading: balanceLoading,
    isError: balanceError,
  } = useFetchPointsBalanceQuery(undefined, {
    skip: !piggyLoyaltyEnabled || !contactIdentifierScan,
  });
  const piggyRewardsRedeemingEnabled = useAppSelector(
    (state) =>
      state.global.salesarea.piggy.loyalty.enabled && state.global.salesarea.piggy.loyalty.rewards_redeeming_enabled
  );

  const { rewards, rewardsLoading } = usePiggyRewards({ skip: !piggyRewardsRedeemingEnabled });

  const numberOfCreditsToBeUsed = useAppSelector(selectNumberOfCreditsOfPiggyRewardsToBeClaimed);

  if ((rewards?.length == 0 && rewards !== undefined) || !piggyLoyaltyEnabled || !piggyRewardsRedeemingEnabled) {
    return null;
  }
  return (
    <Box sx={wrapperSx}>
      <Divider
        sx={{
          "&::before": {
            borderTopWidth: "4px",
          },
          "&::after": {
            borderTopWidth: "4px",
          },
        }}
      >
        <FormattedMessageJamezz id="Piggy.order-page.rewards-list.header" />
        {showBalance ? (
          <>
            {", "}
            <Typography
              component="span"
              variant="body2"
              sx={(theme) => ({ color: darken(theme.palette.primary.main, 0.6) })}
            >
              {balanceError ? (
                <FormattedMessageJamezz id="Piggy.messages.error.couldNotRetrieveBalance" />
              ) : (
                <FormattedMessageJamezz
                  id="Piggy.text.you_have_x_points"
                  values={{
                    balance: balanceLoading ? "..." : balanceError ? "" : (balance ?? 0) - numberOfCreditsToBeUsed,
                  }}
                />
              )}
            </Typography>
          </>
        ) : null}
      </Divider>
      <Box
        data-cy="piggy-rewards-container"
        sx={{
          display: "flex",
          flexWrap: "nowrap",
          overflowX: "scroll",
          paddingTop: 2,
        }}
      >
        {rewardsLoading ? (
          <>
            <Box sx={{ flex: "0 0 auto", display: "flex", justifyContent: "center", px: 2 }}>
              <Skeleton variant="rectangular">
                <Box sx={{ height: "160px", width: "320px" }} />
              </Skeleton>
            </Box>
            <Box sx={{ flex: "0 0 auto", display: "flex", justifyContent: "center", px: 2 }}>
              <Skeleton variant="rectangular">
                <Box sx={{ height: "160px", width: "320px" }} />
              </Skeleton>
            </Box>
            <Box sx={{ flex: "0 0 auto", display: "flex", justifyContent: "center", px: 2 }}>
              <Skeleton variant="rectangular">
                <Box sx={{ height: "160px", width: "320px" }} />
              </Skeleton>
            </Box>
          </>
        ) : rewards != null ? (
          <>
            {rewards.map((reward) => (
              <Box key={reward.uuid} sx={{ flex: "0 0 auto", display: "flex", justifyContent: "center", px: 2 }}>
                <PiggyRewardItem item={reward} piggyPoints={(balance ?? 0) - numberOfCreditsToBeUsed} />
              </Box>
            ))}
          </>
        ) : (
          <FormattedMessageJamezz id="Piggy.order-page.rewards.loading-error" />
        )}
      </Box>
    </Box>
  );
}
