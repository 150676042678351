import { Box, Typography } from "@mui/material";
// @ts-ignore
import Header from "../../../assets/images/fake/header.png";
import { useAppSelector } from "../../../global/utils/redux/store";
import TableIsNotOpenMessage from "../../../global/components/CheckTableOpen/TableIsNotOpenMessage";
import ArrangementsOverview from "../../components/Arrangements/ArrangementsOverview";
import WaitingTimeView from "./Components/WaitingTimeView.tsx";
import BackgroundMediaUrlFromSalesarea from "../../../kiosk/hooks/useBackgroundMediaUrl.tsx";

export default function MenuHeader() {
  const tableName = useAppSelector((state) => state.global.sessionState?.tableName);
  const useArrangements = useAppSelector((state) => state.global.salesarea.use_arrangements);
  const pincode = useAppSelector((state) => state.arrangements.ticket_pincode.pincode);
  return (
    <>
      <div style={{ position: "relative", top: 0, left: 0 }}>
        <BackgroundMediaUrlFromSalesarea
          keyName={"extra_settings__qrv5_menu_header"}
          className={"JS-MenuHeader-Root"}
          sx={{
            zIndex: 0,
            width: 1,
            height: 200,
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "center",
          }}
          sxWrapper={{
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: "100%",
            filter: "blur(4px)",
          }}
          fallbackBackground={`url("${Header}")`}
        />
        <BackgroundMediaUrlFromSalesarea
          keyName={"extra_settings__qrv5_menu_header"}
          className={"JS-MenuHeader-Root"}
          sx={{
            zIndex: 100,
            width: 1,
            height: 200,
            padding: 0,
            filter: "none",
          }}
          sxWrapper={{
            backgroundRepeat: "no-repeat",
            backgroundSize: "auto 100%",
            backgroundPosition: "center",
            position: "absolute",
            top: 0,
            left: 0,
          }}
          fallbackBackground={`url("${Header}")`}
        >
          <Box
            sx={{
              display: "grid",
              width: 1,
              height: 1,
              gridTemplate: `
            ". b" 1px
            "c d"
            / 2fr 1fr
            `,
              paddingTop: 1,
            }}
          >
            {useArrangements ? <ArrangementsOverview sx={{ gridArea: "c", alignSelf: "end" }} /> : null}
            <Box sx={{ gridArea: "b", justifySelf: "end" }}>
              <WaitingTimeView />
            </Box>
            <Box sx={{ gridArea: "d", justifySelf: "end", alignSelf: "end" }}>
              <Typography
                sx={{
                  textAlign: "right",
                  fontStyle: "bold",
                  fontWeight: 800,
                  fontSize: "10pt",
                  margin: 2,
                }}
                className={"JS-MenuHeader-TableText"}
              >
                {tableName}
              </Typography>
              {pincode ? (
                <Typography
                  sx={{
                    textAlign: "right",
                    fontStyle: "bold",
                    fontWeight: 800,
                    fontSize: "10pt",
                  }}
                  className={"JS-MenuHeader-TableText"}
                >
                  (Pin) {pincode}
                </Typography>
              ) : null}
            </Box>
          </Box>
        </BackgroundMediaUrlFromSalesarea>
      </div>

      <TableIsNotOpenMessage />
    </>
  );
}
