import CustomField from "../../../../types/shared/CustomField";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { differenceInCalendarDays, format } from "date-fns";
import FormattedMessageJamezz from "../../../../global/components/FormattedMessageJamezz";
import useCustomField from "./useCustomField";
import { useIntl } from "react-intl";
import { RootState, useAppSelector } from "../../../../global/utils/redux/store";
import { useCallback, useMemo } from "react";
import _ from "lodash";

function CustomFieldSelectPreorderingContent({ customField }: { customField: CustomField }) {
  const intl = useIntl();
  const { value, setCustomField } = useCustomField({ ...customField, name: "PreOrderDatumRaw" });
  const { setCustomField: setCustomFieldOms } = useCustomField({ ...customField, name: "Oms" });

  const preOrderTimes = useAppSelector((state: RootState) => state.global.sessionState.preOrderTimes);

  const formatDate = useCallback(
    (date: Date) => {
      const now = new Date();
      const diff = differenceInCalendarDays(date, now);
      const translatedDay = intl.formatMessage({ id: format(date, "iiii") });
      if (diff === 0) {
        return <FormattedMessageJamezz id={"Today"} />;
      } else if (diff === 1) {
        return <FormattedMessageJamezz id={"Tomorrow"} />;
      } else if (diff > 6) {
        return translatedDay + " - " + format(date, "dd LLL");
      } else {
        return translatedDay;
      }
    },
    [intl]
  );

  const oms = useMemo(() => {
    return _.keyBy(preOrderTimes, "datetime");
  }, [preOrderTimes]);
  return (
    <>
      <FormControl fullWidth sx={{ marginY: 1 }}>
        <InputLabel className="JS-CustomFieldSelectPreorderingContent-Label">
          <FormattedMessageJamezz id={"Pick a date"} />
        </InputLabel>
        <Select
          data-cy="timeslots-date-select"
          className="JS-CustomField-SelectPreordering-Input"
          inputProps={{ required: customField.required }}
          value={value}
          label={
            <span className="JS-CustomFieldSelectPreorderingContent-Label">
              <FormattedMessageJamezz id={"Pick a date"} />
            </span>
          }
          onChange={(e) => {
            setCustomField(e.target.value);
            setCustomFieldOms(oms[e.target.value]["name"]);
          }}
          MenuProps={{ PaperProps: { sx: { maxHeight: "50%" } } }}
        >
          {preOrderTimes.map((preOrderTime: any, idx: number) => (
            <MenuItem
              data-cy={`timeslots-date-option-${idx}`}
              key={new Date(preOrderTime.datetime).getTime()}
              value={preOrderTime.datetime}
              className="JS-CustomFieldDateContent-Option"
            >
              {formatDate(new Date(preOrderTime.datetime))}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
}

export default CustomFieldSelectPreorderingContent;
