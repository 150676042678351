import { ChainPart, ChainPartPriority, ChainPartResult, createChainPart } from "./chainParts.ts";
import store, { useAppSelector } from "../../../global/utils/redux/store.tsx";
import { useDialog } from "../../../global/utils/dialog/DialogProvider.tsx";
import usePriceKeyChecker from "../usePriceKeyChecker.ts";
import useEmployeeCodes from "../../hooks/useEmployeeCodes.ts";
import EancodeUnknownDialog from "../../components/BarCodeScanner/EancodeUnknownDialog.tsx";
import SlideUpTransition from "../../../global/components/SlideUpTransition.tsx";
import { useMemo } from "react";
import { selectArticlesMap } from "../../../global/utils/redux/selectors/selectArticlesMap.ts";
import Article from "../../../global/utils/models/menu/Article.ts";

export function usePriceKeyChainPart(): ChainPart {
  const priceKeyChecker = usePriceKeyChecker();
  const { openDialog } = useDialog();
  const articleMap = useAppSelector(selectArticlesMap);
  const employeeCodes = useEmployeeCodes();

  return useMemo(() => {
    return createChainPart(
      ChainPartPriority.Medium,
      (data: string) =>
        new Promise<ChainPartResult>((resolve, reject) => {
          priceKeyChecker(data)
            .then(() => {
              resolve("found");
            })
            .catch((err) => {
              console.error(err);
              const customizable_articles = store.getState().global.salesarea.custom_data.customizable_articles;
              const notification_article = store.getState().global.salesarea.custom_data.notification_article;

              if (!customizable_articles || !notification_article) {
                resolve("not_found");
              } else if (
                articles_exists(customizable_articles, articleMap) &&
                articles_exists(notification_article, articleMap) &&
                Object.keys(employeeCodes).length > 0
              ) {
                // eancode unknown!
                openDialog({
                  children: <EancodeUnknownDialog eancode={data} />,
                  TransitionComponent: SlideUpTransition,
                  disabledBackdropClick: Object.keys(employeeCodes).length > 0,
                });

                reject("unknown_eancode_flow"); // reject here to prevent an 'Unknown eancode' toast from showing (this is hacky)
              }
            });
        })
    );
  }, [employeeCodes, openDialog, priceKeyChecker, articleMap]);
}

const articles_exists = (rawArticles: string, articleMap: PartialRecord<string, Article>): boolean => {
  const articles = JSON.parse(rawArticles);
  const productIds = Array.isArray(articles) ? articles : Object.values(articles);
  return productIds.every((productId) => articleMap[productId]);
};
