import { ComponentOverlay } from "../../../../global/components/ComponentOverlay.tsx";
import { ButtonBase, Paper, Typography } from "@mui/material";
import { selectAppLanguage } from "../../LanguageSelector/useLanguage.ts";
import store, { useAppSelector } from "../../../../global/utils/redux/store.tsx";
import { useDialog } from "../../../../global/utils/dialog/DialogProvider.tsx";
import Article, { getArticleName } from "../../../../global/utils/models/menu/Article.ts";
import FormattedMessageJamezz from "../../../../global/components/FormattedMessageJamezz.tsx";
import isKiosk from "../../../../global/utils/helpers/isKiosk.tsx";
import SlideUpTransition from "../../../../global/components/SlideUpTransition.tsx";
import OrderArticleSelectorPage from "../../../pages/OrderArticleSelectorPage/OrderArticleSelectorPage.tsx";
import Image from "../../../../global/components/Image";
import { voucherOrderArticleConfirmed, VoucherV2 } from "../../../../global/utils/vouchersV2/vouchersV2Slice.tsx";
import useAddOrderArticleToShoppingCart from "../../../../global/utils/order/useAddOrderArticleToShoppingCart.tsx";

export default function DiscountedProductItem({ article, voucher }: { article: Article; voucher: VoucherV2 }) {
  const { openDialog, closeDialog } = useDialog();
  const lang = useAppSelector(selectAppLanguage);
  // const toCurrency = useCurrency({ location: CurrencyLocation.Menu });
  // const priceLineId = useAppSelector(selectSalesAreaPriceLineId);
  // const price = getArticlePrice(article, { salesAreaPriceLineId: priceLineId });
  const addOrderArticleToShoppingCart = useAddOrderArticleToShoppingCart({ allowUpsell: false });
  return (
    <ComponentOverlay
      disabled={!article.isNotAvailable}
      overlayContent={
        article.isOutOfStock ? (
          <Typography variant={"h6"} sx={{ fontWeight: 1000 }} className={"JS-ArticleSuggestionItem-OutOfStock-Text"}>
            <FormattedMessageJamezz id={"OUT OF STOCK"} />
          </Typography>
        ) : (
          <Typography variant={"h6"} sx={{ fontWeight: 1000 }} className={"JS-ArticleSuggestionItem-NotAvailable-Text"}>
            <FormattedMessageJamezz id={"NOT AVAILABLE"} />
          </Typography>
        )
      }
    >
      <ButtonBase
        className={"JS-ArticleSuggestionItem-Root"}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          margin: 2,
          flexBasis: "calc(50% - 32px)",
          boxShadow: "0 1px 10px 0px rgba(0,0,0,0.2)",
        }}
        onClick={() => {
          if (isKiosk()) {
            openDialog({
              children: (
                <OrderArticleSelectorPage
                  article={article}
                  isUpselled={true}
                  fixedCount={1}
                  onOrderArticleOk={(orderArticle) => {
                    // orderArticle.added_origin = OrderArticleOrigin.PIGGY;
                    addOrderArticleToShoppingCart(orderArticle).then(() => {
                      store.dispatch(voucherOrderArticleConfirmed({ orderArticles: [orderArticle], voucher }));
                      closeDialog();
                      closeDialog();
                    });
                  }}
                />
              ),
              fullScreen: "almostFullScreen",
              TransitionComponent: SlideUpTransition,
            });
          }
        }}
      >
        <Paper sx={{ width: 1, height: 1, padding: 3 }} className={"JS-ArticleSuggestionItem-Paper"}>
          {article.imageMediaUrls?.[0]?.conversions?.responsive?.srcset ? (
            <Image
              srcSet={article.imageMediaUrls?.[0]?.conversions?.responsive?.srcset}
              style={{ width: 220, height: 220, objectFit: "contain" }}
            />
          ) : null}
          <Typography sx={{ marginY: 1, fontWeight: 800 }}>{getArticleName(article, lang)}</Typography>
          {/*{price > 0 || article.vaprice > 0 ? (*/}
          {/*  <Typography sx={{ fontWeight: 800, marginTop: 1 }} className={"JS-ArticleSuggestionItem-Price"}>*/}
          {/*    {toCurrency(article.vaprice > 0 ? article.vaprice : price, {*/}
          {/*      name: "ArticleContent-price",*/}
          {/*    })}*/}
          {/*  </Typography>*/}
          {/*) : null}*/}
        </Paper>
      </ButtonBase>
    </ComponentOverlay>
  );
}
