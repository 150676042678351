import { useCallback } from "react";
import store, { useAppSelector } from "./redux/store";
import { useLazyFindVoucherV2Query } from "./vouchersV2/api/vouchersApi.tsx";
import { voucherOrderArticleConfirmed, vouchersV2Added, VoucherV2 } from "./vouchersV2/vouchersV2Slice.tsx";
import { canAddNewVoucher } from "./vouchersV2/canAddNewVoucher.ts";
import { BlockUserInput } from "@jamezz/react-components";
import { toast } from "react-toastify";
import buildDiscountsRecursively from "./vouchersV2/selectors/buildDiscountsRecursively.ts";
import { calculateDiscountForVoucher } from "./vouchersV2/selectors/calculateDiscountForVoucher.ts";
import _ from "lodash";
import OrderArticle, { initOrderArticle, OrderArticleOrigin } from "./models/order/OrderArticle.ts";
import { selectArticlesMap } from "./redux/selectors/selectArticlesMap.ts";
import { getArticlesById } from "./models/menu/Menu.ts";
import { selectArticlesApiIdMap } from "./redux/selectors/selectArticlesApiIdMap.ts";
import Article from "./models/menu/Article.ts";
import AddDiscountedProductDialog from "../../kiosk/components/VouchersV2/AddDiscountedProducts/AddDiscountedProductDialog.tsx";
import OrderArticleSelectorPage from "../../kiosk/pages/OrderArticleSelectorPage/OrderArticleSelectorPage.tsx";
import SlideUpTransition from "../components/SlideUpTransition.tsx";
import { useDialog } from "./dialog/DialogProvider.tsx";
import { selectSalesAreaPriceLineId } from "./useSalesAreaPriceLineId.ts";
import { selectArticleArticlegroupsMapUnfiltered } from "./redux/selectors/selectArticleArticlegroupsMapUnfiltered.ts";
import { selectArticlegroupsMap } from "./redux/selectors/selectArticlegroupsMap.ts";
import { useIntl } from "react-intl";
import useAddOrderArticleToShoppingCart from "./order/useAddOrderArticleToShoppingCart.tsx";

function processVouchers(voucherV2: VoucherV2, discountedProducts: VoucherV2[]) {
  if (
    voucherV2.voucherdef.discountType === "ADD_DISCOUNTED_PRODUCT" ||
    ((voucherV2.voucherdef.discountType === "PERCENTAGE_OFF_PRODUCT" ||
      voucherV2.voucherdef.discountType === "AMOUNT_OFF_PRODUCT") &&
      voucherV2.voucherdef.maxChooseItems > 0)
  ) {
    if (
      voucherV2.voucher.number_of_times >
      (_.chain(voucherV2.orderArticleUuids)
        .values()
        .map("count")
        .value()
        ?.reduce((total, count) => total + count, 0) ?? 0)
    ) {
      discountedProducts.push(voucherV2);
    }
  }

  voucherV2.subVouchers?.forEach((subVoucher) => {
    processVouchers(subVoucher, discountedProducts);
  });
}

export default function useVouchers(
  {
    withLoading = true,
    withToasts = true,
    doNotAddVoucherToStore = false,
  }: { withLoading?: boolean; withToasts?: boolean; doNotAddVoucherToStore?: boolean } = {
    withLoading: true,
    withToasts: true,
    doNotAddVoucherToStore: false,
  }
) {
  const [trigger, result] = useLazyFindVoucherV2Query();
  BlockUserInput.useBlockUserInput(result.isFetching && withLoading);
  const { openDialog, closeDialog } = useDialog();
  const addOrderArticleToShoppingCart = useAddOrderArticleToShoppingCart({ allowUpsell: false });
  const priceLineId = useAppSelector(selectSalesAreaPriceLineId);
  const articleArticlegroupsMap = useAppSelector(selectArticleArticlegroupsMapUnfiltered);
  const articlegroupsMap = useAppSelector(selectArticlegroupsMap);
  const intl = useIntl();

  const voucherAddedListener = useCallback(
    (voucher: VoucherV2) => {
      const vouchers: VoucherV2[] = [];

      processVouchers(voucher, vouchers);
      vouchers.forEach((voucherV2) => {
        const items = store.getState().shoppingCart.items;
        const currentDiscounts: {
          [orderArticleUuid: string]: {
            discount: number;
          }[];
        } = buildDiscountsRecursively(items);

        if (
          voucherV2.voucherdef.discountType == "ADD_DISCOUNTED_PRODUCT" ||
          ((voucherV2.voucherdef.discountType == "PERCENTAGE_OFF_PRODUCT" ||
            voucherV2.voucherdef.discountType == "AMOUNT_OFF_PRODUCT") &&
            voucherV2.voucherdef.maxChooseItems > 0)
        ) {
          const discounts = calculateDiscountForVoucher(
            voucherV2,
            items,
            priceLineId,
            currentDiscounts,
            articleArticlegroupsMap,
            articlegroupsMap
          );

          const potentialDiscount = _.chain(discounts).values().flattenDepth(1).sumBy("discount").value() > 0;
          if (potentialDiscount) {
            console.log("Items already in basket which discount applies on.");
            toast.success(intl.formatMessage({ id: "Discount applied" }));

            const orderArticles: OrderArticle[] = [];
            const items = store.getState().shoppingCart.items;

            Object.keys(discounts).forEach((uuid) => {
              const orderArticleIndex = items.findIndex((item) => item.uuid == uuid);
              if (orderArticleIndex >= 0) {
                orderArticles.push(items[orderArticleIndex]);
              }
            });

            store.dispatch(voucherOrderArticleConfirmed({ voucher: voucherV2, orderArticles }));
            return;
          }

          const articleIds =
            voucherV2.voucherdef.discountType == "ADD_DISCOUNTED_PRODUCT"
              ? voucherV2.voucherdef.free_products_v2.map((p) => p.id)
              : _.merge(
                  voucherV2.voucherdef.included_products.map((p) => p.id),
                  voucherV2.voucherdef.discounted_products.map((p) => p.id)
                );

          // const productIdsWhichMayBeOptions =
          //   voucherV2.voucherdef.discountType == "ADD_DISCOUNTED_PRODUCT"
          //     ? voucherV2.voucherdef.freeProducts_JSON
          //     : voucherV2.voucherdef.included_products.map((p) => p.id);

          const articlesMap = selectArticlesMap(store.getState());

          // const articlesWithPotentialOptions = getArticlesById(articlesMap, productIdsWhichMayBeOptions).forEach(
          //   (article) => {
          //     getOptionGroupsByArticleId();
          //   }
          // );
          let articles = getArticlesById(articlesMap, articleIds).filter((article) => article.apiId2 == null);

          if (voucherV2.voucherdef.discountType == "ADD_DISCOUNTED_PRODUCT") {
            if (articles.length === 0) {
              const articlesByApiId = selectArticlesApiIdMap(store.getState());
              articles = voucherV2.voucherdef.free_products_v2
                .map((product) => {
                  const article = product.apiId1 ? articlesByApiId[product.apiId1] : null;
                  if (article && article.apiId2 == null) {
                    return article;
                  } else {
                    return null;
                  }
                })
                .filter((a) => a) as Article[];
            }
          }

          if (articles.length > 1) {
            openDialog({
              children: <AddDiscountedProductDialog articles={articles} voucher={voucherV2} />,
              fullScreen: "almostFullScreen",
              disabledBackdropClick: true,
            });
          } else if (articles[0]?.optionGroupIds.length > 0) {
            openDialog({
              children: (
                <OrderArticleSelectorPage
                  article={articles[0]}
                  isUpselled={true}
                  fixedCount={1}
                  isPiggyReward={true}
                  onOrderArticleOk={(orderArticle) => {
                    addOrderArticleToShoppingCart(orderArticle).then(() => {
                      store.dispatch(
                        voucherOrderArticleConfirmed({ voucher: voucherV2, orderArticles: [orderArticle] })
                      );
                      closeDialog();
                      closeDialog();
                    });
                  }}
                />
              ),
              fullScreen: "almostFullScreen",
              TransitionComponent: SlideUpTransition,
            });
          } else if (articles.length === 1 && articles[0]?.optionGroupIds.length === 0) {
            const orderArticle = initOrderArticle(
              articlesMap,
              articles[0],
              1,
              "",
              false,
              false,
              false,
              OrderArticleOrigin.SYSTEM,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined
            );

            addOrderArticleToShoppingCart(orderArticle).then(() => {
              store.dispatch(voucherOrderArticleConfirmed({ voucher: voucherV2, orderArticles: [orderArticle] }));
            });
          } else {
            toast.success(
              "The discount will be applied as soon as there is an item in your shopping cart that qualifies."
            );
            // store.dispatch(voucherOrderArticleConfirmed({ voucher: voucherV2 }));
          }
        } else {
          // store.dispatch(voucherOrderArticleConfirmed({ voucher: voucherV2 }));
        }
      });
    },
    [
      addOrderArticleToShoppingCart,
      articleArticlegroupsMap,
      articlegroupsMap,
      closeDialog,
      intl,
      openDialog,
      priceLineId,
    ]
  );

  return useCallback(
    (voucherCode: string, numberOfTimes: number) => {
      return new Promise<VoucherV2>((resolve, reject) => {
        trigger({ code: voucherCode, numberOfTimes, vouchersV2: store.getState().vouchersV2.vouchers })
          .then((response) => {
            if (response.isSuccess) {
              if (!doNotAddVoucherToStore) {
                const result = canAddNewVoucher(store.getState().vouchersV2.vouchers, response.data);
                if (!result.isError) {
                  resolve(response.data);
                  store.dispatch(vouchersV2Added([response.data]));
                  voucherAddedListener(response.data);
                } else {
                  toast.warning(result.message);
                  reject({ result: { message: result.message } });
                }
              }
            } else if (
              response.error &&
              "data" in response.error &&
              response.error.data &&
              typeof response.error.data == "object" &&
              "message" in response.error.data
            ) {
              reject({ result: { message: response.error?.data?.message ?? "Voucher error" } });
              if (withToasts && typeof response.error?.data?.message == "string") {
                toast.error(response.error?.data?.message ?? "Voucher error");
              }
            } else {
              reject("Unexpected error");
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    [doNotAddVoucherToStore, trigger, withToasts]
  );
}
