import Campaign, { getPriceOfCampaign } from "../../../../global/utils/models/campaigns/Campaign.ts";
import { useDialog } from "../../../../global/utils/dialog/DialogProvider.tsx";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import useAddOrderArticleToShoppingCart from "../../../../global/utils/order/useAddOrderArticleToShoppingCart.tsx";
import useMenuFilters from "../../../../global/utils/menu/filters/useMenuFilters.ts";
import store, { useAppSelector } from "../../../../global/utils/redux/store.tsx";
import { selectArticlesMap } from "../../../../global/utils/redux/selectors/selectArticlesMap.ts";
import { selectArticleGroupsForCampaign } from "./selectArticleGroupsForCampaign.tsx";
import { Swiper as SwiperType } from "swiper/types";
import Article from "../../../../global/utils/models/menu/Article.ts";
import { getArticlesById } from "../../../../global/utils/models/menu/Menu.ts";
import md5 from "md5";
import _ from "lodash";
import useMessagesFromMinAndMaxSelect from "./useMessagesFromMinAndMaxSelect.tsx";
import { Button, DialogActions, Divider, lighten, Stack, Typography, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import ScrollShadowsKiosk from "../../../components/ScrollShadowKiosk.tsx";
import Image from "../../../../global/components/Image.tsx";
import { Swiper, SwiperSlide } from "swiper/react";
import CampaignMenuItemGrid from "./CampaignMenuItemGrid.tsx";
import CampaignArticleItem from "./CampaignArticleItem.tsx";
import FormattedMessageJamezz from "../../../../global/components/FormattedMessageJamezz.tsx";
import useMiniShoppingCart from "./useMiniShoppingCart.tsx";
import useNestedOptionGroupsDialog from "../../OrderArticleSelectorPage/NestedOptions/useNestedOptionGroupsDialog.tsx";
import OrderArticle, { initOrderArticle } from "../../../../global/utils/models/order/OrderArticle.ts";
import FormatCurrency from "../../../../global/components/Currencies/FormatCurrency.tsx";

export default function CampaignProductsDialog({ campaign }: { campaign: Campaign }) {
  const lastAction = useRef<"none" | "added" | "removed">("none");
  const { closeDialog } = useDialog();
  const [groupOnPage, setGroupOnPage] = useState(0);
  const addOrderArticleToShoppingCart = useAddOrderArticleToShoppingCart({ allowUpsell: false });

  const articleFilter = useMenuFilters();
  const articlesMap = useAppSelector(selectArticlesMap);

  const groups = useAppSelector((state) => selectArticleGroupsForCampaign(state, campaign));

  const [swiperRef, setSwiperRef] = useState<SwiperType | null>(null);

  const articlesPerGroup = useMemo<PartialRecord<number, Article[]>>(() => {
    return groups.map((group) => {
      return getArticlesById(articlesMap, group.productIds).filter(articleFilter) ?? [];
    });
  }, [groups, articlesMap, articleFilter]);

  const counts = useMemo(() => {
    return groups.map((group) => ({ maxCount: group.numberOfProducts, minCount: group.numberOfProducts }));
  }, [groups]);

  const groupKeys = useMemo(() => {
    return _.map(groups, (group) => md5(JSON.stringify(group)));
  }, [groups]);

  const groupKey = useMemo(() => {
    return groupKeys[groupOnPage];
  }, [groupKeys, groupOnPage]);

  const { addOrderArticle, totalCountPerGroup, allOrderArticles } = useMiniShoppingCart({
    maxCount: counts[groupOnPage].maxCount,
    groupKey,
  });

  const orderArticlesPerArticleIdPerGroup = useMemo(() => {
    return _.mapValues(allOrderArticles, (orderArticles) => _.chain(orderArticles).groupBy("article.id").value());
  }, [allOrderArticles]);

  const countPerArticleIdPerGroup = useMemo(() => {
    return _.mapValues(orderArticlesPerArticleIdPerGroup, (orderArticlesPerArticleId) =>
      _.chain(orderArticlesPerArticleId)
        .mapValues((group) => _.sumBy(group, "count"))
        .value()
    );
  }, [orderArticlesPerArticleIdPerGroup]);

  const price = useMemo(() => {
    return getPriceOfCampaign(campaign);
  }, [campaign]);

  const scrollRef = useRef(null);
  const message = useMessagesFromMinAndMaxSelect({
    minSelect: counts[groupOnPage].minCount,
    maxSelect: counts[groupOnPage].maxCount,
  });
  const theme = useTheme();
  const subTextColor = lighten(theme.palette.text.primary, 0.5);

  useEffect(() => {
    const handler = () => {
      if (swiperRef) {
        setGroupOnPage(swiperRef.activeIndex);
      }
    };

    if (swiperRef) {
      swiperRef.on("activeIndexChange", handler);
    }

    return () => {
      if (swiperRef) {
        swiperRef.off("activeIndexChange", handler);
      }
    };
  }, [setGroupOnPage, swiperRef]);
  const openNestedOptionGroups = useNestedOptionGroupsDialog();

  const addOrderArticle2 = useCallback(
    (orderArticle: OrderArticle) => {
      (async () => {
        if (orderArticle.count > 0) {
          if (counts[groupOnPage].maxCount === totalCountPerGroup[groupKey] && counts[groupOnPage].maxCount > 1) {
            return;
          }

          const orderArticle2 = await openNestedOptionGroups(orderArticle);

          if (orderArticle2) {
            lastAction.current = "added";
            addOrderArticle(orderArticle2, {
              cleanShoppingCart: counts[groupOnPage].maxCount === 1,
            }).then(() => {
              if (
                totalCountPerGroup[groupKey] + orderArticle2.count >= counts[groupOnPage].maxCount &&
                groupOnPage + 1 < groups.length
              ) {
                setGroupOnPage(groupOnPage + 1);
                swiperRef?.slideNext();
              }
            });
          }
        } else {
          lastAction.current = "removed";
          addOrderArticle(orderArticle);
        }
      })();
    },
    [
      addOrderArticle,
      groupKey,
      groupOnPage,
      groups.length,
      counts,
      openNestedOptionGroups,
      swiperRef,
      totalCountPerGroup,
    ]
  );

  useEffect(() => {
    (async () => {
      const record = articlesPerGroup[groupOnPage];

      if (swiperRef && record && record.length === 1 && totalCountPerGroup[groupKey] < counts[groupOnPage].maxCount) {
        const article = record[0];
        const orderArticle: OrderArticle | undefined = initOrderArticle(
          selectArticlesMap(store.getState()),
          article,
          1
        );
        if (orderArticle && (lastAction.current !== "removed" || orderArticle.orderOptionGroups.length === 0)) {
          addOrderArticle2(orderArticle);
        }
      }
    })();
  }, [swiperRef, addOrderArticle2, articlesPerGroup, groupKey, groupOnPage, counts, totalCountPerGroup]);

  return (
    <>
      <Box sx={{ flex: "1 1 0%", overflowY: "auto" }} ref={scrollRef}>
        <ScrollShadowsKiosk containerRef={scrollRef}>
          <Stack justifyContent={"center"} gap={2} sx={{ my: 2 }}>
            <Stack direction={"row"} gap={3}>
              {campaign.mediaInCategory?.conversions?.responsive?.srcset ? (
                <Image
                  style={{ maxHeight: 300, flex: "1 1 0%", objectFit: "contain" }}
                  srcSet={campaign.mediaInCategory?.conversions?.responsive?.srcset}
                />
              ) : null}
            </Stack>
            <Typography textAlign={"center"} sx={{ flex: "1 1 0%" }}>
              {campaign.name}
            </Typography>
            {price != null ? (
              <Typography textAlign={"center"}>
                <FormatCurrency value={price} />
              </Typography>
            ) : null}
            <Divider />
            <Swiper
              onSwiper={setSwiperRef}
              tabIndex={groupOnPage}
              allowTouchMove={false}
              style={{ height: "0", flex: "1 1 0%", overflow: "hidden", width: "100%" }}
            >
              {groups.map((group, index) => {
                const articles = articlesPerGroup[index] ?? [];

                return (
                  <SwiperSlide key={index}>
                    <Typography fontSize={"2rem"} fontStyle={"italic"} textAlign={"center"} color={subTextColor}>
                      {message}
                    </Typography>
                    <Stack direction={"row"} justifyContent={"space-between"} flexWrap={"wrap"}>
                      {_.chunk(articles, 3).map((articles) => (
                        <CampaignMenuItemGrid
                          key={JSON.stringify(articles.map((article) => article.id))}
                          numberOfArticles={articles.length}
                        >
                          {articles.map((article, columnIndex) => (
                            <CampaignArticleItem
                              key={article.id}
                              article={article}
                              addOrderArticle={addOrderArticle2}
                              count={countPerArticleIdPerGroup[groupKeys[index]]?.[article.id] ?? 0}
                              columnIndex={columnIndex}
                              maxCount={counts[index].maxCount}
                              orderArticles={orderArticlesPerArticleIdPerGroup[groupKeys[index]]?.[article.id] ?? []}
                            />
                          ))}
                        </CampaignMenuItemGrid>
                      ))}
                    </Stack>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </Stack>
        </ScrollShadowsKiosk>
      </Box>
      <Divider />
      <DialogActions sx={{ justifyContent: "center", gap: 10 }}>
        <Button
          sx={{ maxWidth: 300, width: 1 }}
          onClick={() => {
            if (groupOnPage === 0) {
              closeDialog();
            } else {
              swiperRef?.slidePrev();
              setGroupOnPage(groupOnPage - 1);
            }
          }}
        >
          <FormattedMessageJamezz id={"Go back"} />
        </Button>
        {groupOnPage + 1 === groups.length ? (
          <Button
            sx={{ maxWidth: 300, width: 1 }}
            disabled={
              (counts[groupOnPage].minCount > 0 && counts[groupOnPage].minCount > totalCountPerGroup[groupKey]) ||
              (counts[groupOnPage].maxCount > 0 && counts[groupOnPage].maxCount < totalCountPerGroup[groupKey])
            }
            onClick={() => {
              Object.values(allOrderArticles).forEach((orderArticles) => {
                orderArticles.forEach((orderArticle) => {
                  addOrderArticleToShoppingCart(orderArticle);
                });
              });

              closeDialog();
            }}
          >
            <FormattedMessageJamezz id={"Add"} />
          </Button>
        ) : (
          <Button
            sx={{ maxWidth: 300, width: 1 }}
            disabled={
              (counts[groupOnPage].minCount > 0 && counts[groupOnPage].minCount > totalCountPerGroup[groupKey]) ||
              (counts[groupOnPage].maxCount > 0 && counts[groupOnPage].maxCount < totalCountPerGroup[groupKey])
            }
            onClick={() => {
              setGroupOnPage(groupOnPage + 1);
              swiperRef?.slideNext();
            }}
          >
            <FormattedMessageJamezz id={"Next"} />
          </Button>
        )}
      </DialogActions>
    </>
  );
}
