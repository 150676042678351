import { Stack, TextField } from "@mui/material";
import { useContext } from "react";
import { OrderArticleContext } from "../../../global/utils/contexts/OrderArticleContext.ts";
import store, { RootState, useAppSelector } from "../../../global/utils/redux/store.tsx";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz.tsx";
import { onFocusKeyboard } from "../../../global/utils/redux/globalSlice.tsx";

export const OrderArticleNoteField = () => {
  const { orderArticle, setOrderArticle } = useContext(OrderArticleContext);
  const allowNotes = useAppSelector((state: RootState) => state.global.salesarea.custom_data?.appv5?.allow_item_notes);
  if (!orderArticle || !allowNotes) {
    return null;
  }
  return (
    <Stack direction={"row"} sx={{ width: 1 }} justifyContent={"center"}>
      <TextField
        sx={{ my: 2, width: 0.9, maxWidth: 800 }}
        multiline={true}
        label={<FormattedMessageJamezz id={"item_note"} />}
        rows={3}
        slotProps={{ htmlInput: { style: {}, maxLength: 140 } }}
        value={orderArticle.note}
        onChange={(e) => {
          setOrderArticle?.({ ...orderArticle, note: e.target.value });
        }}
        onFocus={() => store.dispatch(onFocusKeyboard({ layout: "default", value: orderArticle.note }))}
      />
    </Stack>
  );
};
