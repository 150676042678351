import { Chip, Collapse, Fade, IconButton, Paper, Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { Add, Delete, Loyalty, Remove } from "@mui/icons-material";
import { orderArticlesPushedByUser } from "../../../global/utils/redux/shoppingCartSlice";

import { useDialog } from "../../../global/utils/dialog/DialogProvider";
import OrderArticle, {
  getTotalPrice,
  OrderArticleOrigin,
  recurOrderArticleOptions,
} from "../../../global/utils/models/order/OrderArticle";
import { useAppDispatch, useAppSelector } from "../../../global/utils/redux/store";
import SlideUpTransition from "../../../global/components/SlideUpTransition";
import OrderArticleSelectorPage from "../OrderArticleSelectorPage/OrderArticleSelectorPage";
import { getArticleName } from "../../../global/utils/models/menu/Article";
import Image from "../../../global/components/Image";
import _ from "lodash";
import isQr from "../../../global/utils/helpers/isQr";
import useIsWidescreen from "../../../global/utils/useIsWidescreen";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz";
import { selectArticleCreditCost } from "../../../global/utils/redux/arrangements/arrangementsSlice";
import { JamezzIcon } from "../../../global/assets/JamezzIcon";
import { selectVoucherDiscounts } from "../../../global/utils/vouchersV2/selectors/selectVoucherDiscounts.ts";
import PercentageDiscount from "../../../assets/images/PercentageDiscount.svg?react";
import { selectAppLanguage } from "../../../kiosk/components/LanguageSelector/useLanguage";
import PrintOptionOrderArticle from "./PrintOptionOrderArticle";
import PrintOrderOptionGroup from "./PrintOrderOptionGroup";
import { selectArticlesMap } from "../../../global/utils/redux/selectors/selectArticlesMap.ts";
import { getArticleById } from "../../../global/utils/models/menu/Menu.ts";
import { selectShoppingCartCountPerArticleId } from "../../../global/utils/redux/selectors/selectShoppingCartCountPerArticleId.ts";
import useAddOrderArticleToShoppingCart from "../../../global/utils/order/useAddOrderArticleToShoppingCart.tsx";
import { selectSalesAreaPriceLineId } from "../../../global/utils/useSalesAreaPriceLineId.ts";
import { useMemo } from "react";
import FormatCurrency from "../../../global/components/Currencies/FormatCurrency.tsx";

const cardPadding = 1;

const costStyling = { fontWeight: 800, whiteSpace: "nowrap" };

export default function OrderArticleContent({
  orderArticle,
  usePiggyVouchers,
  index,
  canChangeOrderArticle = true,
}: {
  orderArticle: OrderArticle;
  index?: number;
  usePiggyVouchers?: boolean;
  canChangeOrderArticle?: boolean;
}) {
  const dispatch = useAppDispatch();
  const { openDialog } = useDialog();
  const lang = useAppSelector(selectAppLanguage);
  const isAyce = useAppSelector((state) => state.global.salesarea.use_arrangements);
  const isWidescreen = useIsWidescreen();
  const voucherDiscounts = useAppSelector(selectVoucherDiscounts);
  const ayce_credit_cost = useAppSelector((state) => selectArticleCreditCost(state, orderArticle));

  const articleEditable = orderArticle.added_origin === OrderArticleOrigin.MENU || orderArticle.added_origin === null;
  const articleFromStore =
    getArticleById(useAppSelector(selectArticlesMap), orderArticle.article.id) ?? orderArticle.article;
  const countOfArticleIdInShoppingCart =
    useAppSelector((state) => selectShoppingCartCountPerArticleId(state)[orderArticle.article.id]) ?? 0;
  const addOrderArticleToShoppingCart = useAddOrderArticleToShoppingCart({ allowUpsell: false });
  const priceLineId = useAppSelector(selectSalesAreaPriceLineId);
  const totalPrice = getTotalPrice(orderArticle, priceLineId);

  const discountAmount = useMemo(() => {
    const items = [orderArticle];

    const orderArticles = items
      .concat(...items.map((item) => [...recurOrderArticleOptions(item)]))
      .filter((item) => item.count > 0);

    return orderArticles.reduce(
      (total, orderArticle) => total + (voucherDiscounts.discountsPerOrderArticle[orderArticle.uuid]?.discount ?? 0),
      0
    );
  }, [orderArticle, voucherDiscounts.discountsPerOrderArticle]);

  return (
    <Paper
      data-cy="order-item"
      className="JS-OrderPage-ArticleCard"
      sx={{
        width: 1,
        position: "relative",
        borderRadius: 3,
        padding: cardPadding,
        display: "grid",
        gridTemplateColumns: "20% 1fr 1fr",
        gridTemplateRows: "max-content 1fr auto",
        gridTemplateAreas: `
            "Banner Banner Banner"
            "Image Body Body"
            "Image Amount-buttons Amount-buttons"
          `,
        ...(orderArticle.added_origin === OrderArticleOrigin.SYSTEM && {
          opacity: 0.6,
          "& *": {
            userSelect: "none",
          },
        }),
        overflow: "hidden",
      }}
      onClick={() => {
        if (articleEditable && canChangeOrderArticle) {
          openDialog({
            children: <OrderArticleSelectorPage orderArticle={_.cloneDeep(orderArticle)} />,
            fullScreen: !(isQr() && isWidescreen),
            TransitionComponent: SlideUpTransition,
          });
        }
      }}
    >
      <Box sx={{ gridArea: "Banner" }}>
        {orderArticle.added_origin === OrderArticleOrigin.PIGGY ? (
          <Box
            sx={(theme) => ({
              background: theme.palette.primary.main,
              width: `calc(100% + 2 * ${cardPadding} * 8px)`,
              translate: `calc(-${cardPadding} * 8px) calc(-${cardPadding} * 8px)`,
              px: 1,
              display: "flex",
              alignItems: "center",
              height: "24px",
            })}
          >
            <Loyalty sx={{ fontSize: "1em", mr: 1 }} />
            <Typography fontSize="small">
              <FormattedMessageJamezz id="Piggy.rewards.article.your-reward" />
            </Typography>
          </Box>
        ) : null}
        <Collapse
          sx={(theme) => ({
            background: theme.palette.error.main,
            width: `calc(100% + 2 * ${cardPadding} * 8px)`,
            translate: `calc(-${cardPadding} * 8px) calc(-${cardPadding} * 8px)`,
            px: 1,
            display: "flex",
            alignItems: "center",
            height: "24px",
          })}
          in={
            articleFromStore != null &&
            countOfArticleIdInShoppingCart > articleFromStore.stock &&
            articleFromStore.stock > 0
          }
        >
          <Box>{articleFromStore?.stock === 0 ? "Out of stock" : "Only " + articleFromStore?.stock + " left"}</Box>
        </Collapse>
      </Box>
      {orderArticle.article.imageMediaUrls?.[0]?.conversions?.responsive?.srcset ? (
        // flex centers the image vertically
        <Box
          sx={{ gridArea: "Image", display: "flex", marginRight: 1 }}
          className={"JS-OrderArticleContent-ImageWrapper"}
        >
          <Image
            srcSet={orderArticle.article.imageMediaUrls?.[0]?.conversions?.responsive?.srcset}
            style={{ objectFit: "contain", width: "100%" }}
          />
        </Box>
      ) : (
        <Box sx={{ gridArea: "Image", height: 0 }}></Box>
      )}
      <Box sx={{ display: "flex", flexDirection: "column", gridArea: "Body" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Stack>
            <Typography sx={{ fontWeight: 800 }} className={"JS-OrderArticleContent-TextName"}>
              {getArticleName(orderArticle.article, lang)}
            </Typography>
            {orderArticle.note?.length > 0 ? (
              <Typography sx={{ fontSize: 12 }} className={"JS-OrderArticleContent-Note"}>
                {orderArticle.note}
              </Typography>
            ) : null}
            {usePiggyVouchers && index != null && discountAmount ? (
              <Box>
                <Chip
                  icon={<PercentageDiscount style={{ color: "#fff", maxHeight: "80%" }} />}
                  sx={{
                    backgroundColor: "green",
                    color: "white",
                    ".MuiChip-icon": { color: "#fff" },
                    borderRadius: 16,
                    height: 18,
                    width: 1,
                  }}
                  label={"Voucher applied"}
                />
              </Box>
            ) : null}
          </Stack>
          <div>
            {isAyce && Number.isFinite(ayce_credit_cost) ? (
              <Typography sx={costStyling} fontSize={"1em"}>
                <JamezzIcon />
                &nbsp;
                {ayce_credit_cost}
              </Typography>
            ) : null}
            {/*price is hidden when credits should be shown instead. If it has a price AND credits, both show*/}
            {usePiggyVouchers && index != null && discountAmount > 0 ? (
              <>
                <Stack gap={0} direction={"column"} alignItems={"flex-start"}>
                  <Typography sx={{ textDecoration: "line-through", color: "rgb(222, 51, 51)" }}>
                    <FormatCurrency value={getTotalPrice(orderArticle, priceLineId)} />
                  </Typography>
                  <Typography sx={{ fontWeight: 800, color: "green" }}>
                    <FormatCurrency value={getTotalPrice(orderArticle, priceLineId) - discountAmount} />
                  </Typography>
                </Stack>
              </>
            ) : totalPrice === 0 && Number.isFinite(ayce_credit_cost) && isAyce ? null : (
              <Typography
                sx={{ ...costStyling, minWidth: 70, textAlign: "end" }}
                className={"JS-OrderArticleContent-TextPrice"}
              >
                <FormatCurrency value={totalPrice} />
              </Typography>
            )}
          </div>
        </Box>
        <Box>
          {orderArticle.orderOptionGroups.map((orderOptionGroup) => (
            <PrintOrderOptionGroup orderOptionGroup={orderOptionGroup} key={orderOptionGroup.id} />
          ))}
          {orderArticle.extraOrderArticles?.map((orderArticle) => (
            <PrintOptionOrderArticle orderArticle={orderArticle} count={orderArticle.count} key={orderArticle.uuid} />
          ))}
        </Box>
      </Box>

      <Box sx={{ gridArea: "Amount-buttons", display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
        <Fade in={Boolean(orderArticle.count > 0 && articleEditable)}>
          <IconButton
            className="JS-OrderPage-ArticleMinusButton"
            data-cy="order-article-decrement-button"
            sx={{ paddingX: 3 }}
            color={"primary"}
            size={"large"}
            onClick={(e) => {
              if (orderArticle.count > 0) {
                dispatch(orderArticlesPushedByUser([{ ...orderArticle, count: -1 }]));
                e.stopPropagation();
              }
            }}
          >
            {orderArticle.count == 1 ? <Delete /> : <Remove />}
          </IconButton>
        </Fade>
        {articleEditable || orderArticle.count > 1 ? (
          <Typography
            data-cy="order-article-count"
            className="JS-OrderPage-ArticleCountValue"
            sx={{ width: "1em", textAlign: "center" }}
          >
            {orderArticle.count}
            {!articleEditable ? "x" : ""}
          </Typography>
        ) : null}
        {articleEditable ? (
          <IconButton
            className="JS-OrderPage-ArticlePlusButton"
            data-cy="order-article-increment-button"
            sx={{ paddingX: 3 }}
            color={"primary"}
            size={"large"}
            onClick={async (e) => {
              e.stopPropagation();
              const newOrderArticle = { ...orderArticle, count: 1 };
              addOrderArticleToShoppingCart(newOrderArticle).catch((err) => console.log(err));
            }}
          >
            <Add />
          </IconButton>
        ) : null}
      </Box>
    </Paper>
  );
}
