import { PiggyVoucher } from "../../../../types/shared/piggy.ts";
import store, { useAppSelector } from "../../../../global/utils/redux/store.tsx";

import Box from "@mui/material/Box";
import { ButtonBase, Fade, IconButton, Stack, Typography } from "@mui/material";
import PaperRip from "./PaperRip.tsx";
import Checkmark from "./Checkmark.tsx";
import { Add, Lock, Remove } from "@mui/icons-material";
import { format } from "date-fns";

import Barcode from "../../../../assets/images/Barcode.svg?react";
import PercentageDiscount from "../../../../assets/images/PercentageDiscount.svg?react";
import JamezzLogo from "../../../../assets/images/JamezzLogo.svg";
import Image from "../../../../global/components/Image.tsx";
import usePiggyVoucher from "../../../../global/utils/piggy/usePiggyVoucher.ts";
import useVouchers from "../../../../global/utils/useVouchers";
import { selectVouchersToBeRedeemedMap } from "../../../../global/utils/vouchersV2/selectors/selectVouchersToBeRedeemedMap.ts";
import {
  vouchersV2NumberOfTimesChanged,
  vouchersV2Removed,
} from "../../../../global/utils/vouchersV2/vouchersV2Slice.tsx";
import useConfirmationDialog from "../../useConfirmationDialog/useConfirmationDialog.tsx";
import FormatCurrency from "../../../../global/components/Currencies/FormatCurrency.tsx";

export default function PiggyVoucherItem({ item }: { item: PiggyVoucher }) {
  const vouchersV2 = useAppSelector(selectVouchersToBeRedeemedMap);
  const voucherIsInShoppingCart = vouchersV2[item.code] != null;
  const isRedeemed = item.status === "REDEEMED";
  const isLocked = item.status === "LOCKED";
  const isExpired = item.status === "EXPIRED";
  const { validDate } = usePiggyVoucher(item);
  const confirmationDialog = useConfirmationDialog();

  const logoImage = useAppSelector(
    (state) => state.global.salesarea?.media_urls?.logoImage?.[0]?.conversions?.optimized?.url
  );
  const isMultiVoucher = item.promotion.type == "MULTI_USE" && (item.redemptions_remaining ?? 0) > 1;
  const checkVoucherCode = useVouchers({ withLoading: true });

  const venueId = useAppSelector((state) => state.global.salesarea?.venue_id);

  return (
    <Box
      sx={{
        width: venueId == 1647 ? 400 : 400,
        height: venueId == 1647 ? 200 * 1.4 : 200,
        backgroundColor: "primary.main",
        borderRadius: 2,
        overflow: "hidden",
        boxShadow: "0 1px 10px 0px rgba(0,0,0,0.6)",
      }}
    >
      <ButtonBase
        sx={{ height: 1, width: 1 }}
        disabled={isRedeemed || isLocked || isExpired}
        onClick={() => {
          if (voucherIsInShoppingCart) {
            if (!isMultiVoucher) {
              confirmationDialog({
                body: "Are you sure you do not want to use this voucher?",
                actions: [
                  {
                    id: "Yes",
                    action: () => {
                      store.dispatch(vouchersV2Removed([vouchersV2[item.code]]));
                    },
                  },
                  { id: "Cancel" },
                ],
              });
            }
          } else {
            checkVoucherCode(item.code, 1);
          }
        }}
      >
        <Box sx={{ display: "flex", width: 1, height: 1, position: "relative" }}>
          {isRedeemed ? <PaperRip isUsed={true} /> : null}
          {isExpired ? <PaperRip isExpired={true} /> : null}
          {!isMultiVoucher ? (
            <Fade in={voucherIsInShoppingCart} unmountOnExit>
              <Box>
                <Checkmark scale={6} />
              </Box>
            </Fade>
          ) : null}
          <Fade in={isLocked} unmountOnExit>
            <Box>
              <Box sx={{ position: "absolute", width: 1, height: 1, background: "#00000050" }}></Box>
              <Box sx={{ position: "absolute", left: "50%", top: "50%", transform: "translate(-50%,-50%)" }}>
                <Lock />
              </Box>
            </Box>
          </Fade>

          <Stack sx={{ textAlign: "right", flex: "1 1 0%", mx: 2, width: "calc(100% - 32px)" }}>
            <Box sx={{ textAlign: "left", display: "flex", alignItems: "center", gap: 1, width: 1, maxHeight: 0.33 }}>
              <Image
                src={logoImage ?? JamezzLogo}
                style={{ flex: "1 1 0%", width: 0, maxHeight: "100%", objectFit: "contain", objectPosition: "left" }}
              />
              <PercentageDiscount style={{ width: 30, color: "white" }} />
              <Typography sx={{ color: "white", fontSize: 18 }}>Voucher</Typography>
            </Box>
            <Typography sx={{ fontWeight: 800, fontSize: 28, color: "white", flex: 1 }}>
              {item.promotion.name}
            </Typography>
            {isMultiVoucher ? <MultiUseVoucherControls item={item} /> : null}
            <Box sx={{ textAlign: "left", display: "flex", alignItems: "center", gap: 1 }}>
              {validDate ? (
                <Typography sx={{ color: "white", fontSize: 18 }}>Valid {format(validDate, "dd-MMM-yyyy")}</Typography>
              ) : null}
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography sx={{ fontWeight: 800, fontSize: 22, color: "white" }}>
                *****{item.code.substring(5)}
              </Typography>
              <Typography sx={{ fontWeight: 800, fontSize: 22, color: "white" }}>
                {isMultiVoucher ? <>{item.redemptions_remaining}x </> : null}
                {!Number.isNaN(Number(item.attributes.max_value)) && item.attributes.max_value / 100 != 0 ? (
                  <FormatCurrency value={item.attributes.max_value / 100} />
                ) : null}
              </Typography>
            </Box>
          </Stack>
          <Box sx={{ background: "white", padding: 1, height: 1 }}>
            <Barcode style={{ width: 58, height: "100%" }} />
          </Box>
        </Box>
      </ButtonBase>
    </Box>
  );
}

function MultiUseVoucherControls({ item }: { item: PiggyVoucher }) {
  const vouchersV2 = useAppSelector(selectVouchersToBeRedeemedMap);

  return (
    <Fade in={vouchersV2[item.code]?.voucher.number_of_times > 0}>
      <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} sx={{ width: 1, color: "white" }}>
        <IconButton
          sx={{ color: "white" }}
          onClick={(e) => {
            e.stopPropagation();
            if (vouchersV2[item.code]?.voucher.number_of_times === 1) {
              store.dispatch(vouchersV2Removed([vouchersV2[item.code]]));
            } else {
              store.dispatch(
                vouchersV2NumberOfTimesChanged([
                  {
                    numberOfTimes: vouchersV2[item.code]?.voucher.number_of_times - 1,
                    voucherCode: item.code,
                  },
                ])
              );
            }
          }}
        >
          <Remove />
        </IconButton>
        <Typography sx={{ textAlign: "center", marginX: 3, minWidth: 50 }}>
          {vouchersV2[item.code]?.voucher.number_of_times}
        </Typography>
        <Fade in={vouchersV2[item.code]?.voucher.number_of_times < (item?.redemptions_remaining ?? 0)}>
          <IconButton
            sx={{ color: "white" }}
            onClick={(e) => {
              e.stopPropagation();
              if (vouchersV2[item.code]?.voucher.number_of_times < (item?.redemptions_remaining ?? 0)) {
                store.dispatch(
                  vouchersV2NumberOfTimesChanged([
                    {
                      numberOfTimes: vouchersV2[item.code]?.voucher.number_of_times + 1,
                      voucherCode: item.code,
                    },
                  ])
                );
              }
            }}
          >
            <Add />
          </IconButton>
        </Fade>
      </Stack>
    </Fade>
  );
}
