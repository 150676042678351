import { Box } from "@mui/material";
import MenuHeader from "./MenuHeader";
import MenuContent from "./MenuContent";
import { useEffect } from "react";
import OrderPage from "../OrderPage/OrderPage";
import ShoppingCartChecker from "../../../global/utils/order/ShoppingCartChecker";
import WelcomePopupScreen from "../../components/WelcomePopupScreen";
import SalesareaStatus from "../../components/SalesareaStatus";
import Piggy from "../../components/Piggy/Piggy";
import { useAppSelector } from "../../../global/utils/redux/store";
import useIsWidescreen from "../../../global/utils/useIsWidescreen";
import { VoucherChecker } from "../../../global/utils/order/VoucherChecker";
import { OnetimeUpsell } from "../../../global/utils/hooks/useOpenOneTimeUpsellDialog";
import SharedShoppingCartDialog from "../../components/SharedShoppingCart/SharedShoppingCartDialog";
import useAfterPay from "../../../global/utils/hooks/useAfterPay";
import { useLocation } from "react-router";
import { createPath, routes } from "../../../global/utils/config";
import CheckoutPage from "../CheckoutPage/CheckoutPage";
import SlideUpTransition from "../../../global/components/SlideUpTransition";
import { useDialog } from "../../../global/utils/dialog/DialogProvider";
import { useScroll } from "../../../global/utils/ScrollProvider";
import isQr from "../../../global/utils/helpers/isQr";
import useAppNavigate from "../../../global/utils/useAppNavigate.ts";
import { AyceReactService } from "../../components/Arrangements/AyceReactService.tsx";
import AiUpsellService from "../../components/AiUpsell/AiUpsellService.tsx";

function MenuPage() {
  const { scrollContent } = useScroll();
  const navigate = useAppNavigate();
  const isWidescreen = useIsWidescreen();
  const piggyEnabled = useAppSelector((state) => state.global.salesarea.piggy.loyalty.enabled);
  const shouldShowWelcomePopup = useAppSelector((state) => state.global.salesarea.show_welcome_screen);
  const triggerGetBill = useAfterPay();
  const location = useLocation();
  const { openDialog } = useDialog();
  const afterPay = useAppSelector((state) => state.global.sessionState?.payAfter);
  const isCatalog = useAppSelector((state) => state.global.salesarea.salesarea_type === "CATALOG");

  useEffect(() => {
    if (afterPay) {
      triggerGetBill();
    }
  }, [afterPay, triggerGetBill]);

  useEffect(() => {
    if (location.pathname === createPath(routes.qr.checkoutPath)) {
      openDialog({
        children: <OrderPage />,
        fullScreen: !(isQr() && isWidescreen),
        TransitionComponent: SlideUpTransition,
      });
      openDialog({
        children: <CheckoutPage />,
        fullScreen: !(isQr() && isWidescreen),
        TransitionComponent: SlideUpTransition,
        onClose: () => {
          navigate(createPath(routes.qr.rootPath));
        },
      });
    }
  }, []);

  useEffect(() => {
    scrollContent.current = window;
  }, []);

  return (
    <>
      <SalesareaStatus />
      {shouldShowWelcomePopup ? <WelcomePopupScreen /> : null}
      <ShoppingCartChecker />
      <VoucherChecker />
      <AyceReactService />
      <AiUpsellService />

      {isWidescreen && !isCatalog ? (
        <Box sx={{ width: "calc(100vw - 450px)", display: "inline-block" }}>
          <Box sx={{ width: 1, maxWidth: 1200, marginX: "auto" }}>
            <MenuHeader />
            {piggyEnabled ? <Piggy /> : null}
            <MenuContent />
          </Box>
        </Box>
      ) : (
        <>
          <MenuHeader />
          {piggyEnabled ? <Piggy /> : null}
          <MenuContent />
        </>
      )}

      {isWidescreen && !isCatalog ? (
        <Box
          sx={{
            display: "inline-block",
            position: "fixed",
            top: 0,
            bottom: 0,
            right: 0,
            width: 450,
            overflow: "auto",
          }}
        >
          <OrderPage />
        </Box>
      ) : null}
      <SharedShoppingCartDialog />
      <OnetimeUpsell where={"onOpenMenuPage"} />
    </>
  );
}

export default MenuPage;
