import SlideUpTransition from "../../global/components/SlideUpTransition";
import { useEffect, useState } from "react";
import { useDialog } from "../../global/utils/dialog/DialogProvider";
import FormattedMessageJamezz from "../../global/components/FormattedMessageJamezz";
import AfterPayPage from "../pages/AfterPayPage/AfterPayPage";
import Box from "@mui/material/Box";
import { Button, CircularProgress, Collapse, Typography } from "@mui/material";
import store, { useAppSelector } from "../../global/utils/redux/store";
import checkOutstandingBalance from "../../global/utils/api/scanAndPay/checkOutstandingBalance";
import { setAfterPayData } from "../../global/utils/redux/shoppingCartSlice";
import isQr from "../../global/utils/helpers/isQr";
import useIsWidescreen from "../../global/utils/useIsWidescreen";
import { useCustomTexts } from "../../global/utils/useCustomTexts.tsx";
import { pay_after_custom_text_keys } from "../../types/shared/language.ts";
import FormatCurrency from "../../global/components/Currencies/FormatCurrency.tsx";

function PayAfterOrderDialog() {
  const { openDialog, closeDialog } = useDialog();
  const isWidescreen = useIsWidescreen();
  const afterPayData = useAppSelector((state) => state.shoppingCart.afterPayData);
  const tableName = useAppSelector((state) => state.global.sessionState?.tableName);

  const [loading, setLoading] = useState(
    !afterPayData || (new Date().getTime() - afterPayData.clientTimestamp) / 1000 > 5
  );

  useEffect(() => {
    // api call
    if (loading) {
      checkOutstandingBalance()
        .then((response) => {
          store.dispatch(setAfterPayData(response));
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [afterPayData]);

  const ct = useCustomTexts<pay_after_custom_text_keys>([
    "payafter - title",
    "payafter - body",
    "payafter - cancel button",
    "payafter - ok button",
  ]);

  return (
    <Box sx={{ padding: 2 }}>
      <Typography sx={{ textAlign: "center" }}>
        <h1>{ct["payafter - title"] ?? <FormattedMessageJamezz id={"Welcome back"} />}</h1>
        {loading ? (
          <CircularProgress color={"primary"} />
        ) : (
          <>
            {!afterPayData ? (
              <FormattedMessageJamezz id={"afterpay.pay.bill.empty.message"} />
            ) : afterPayData.payment <= 0 ? (
              <CircularProgress color={"primary"} />
            ) : (
              <p data-cy={"pay-after-order-open-amount"}>
                {ct["payafter - body"] ?? (
                  <FormattedMessageJamezz
                    id={"afterpay.pay.bill.message"}
                    values={{ amount: <FormatCurrency value={afterPayData.payment} />, tableName }}
                  />
                )}
              </p>
            )}
          </>
        )}
      </Typography>
      <Collapse in={!loading}>
        <Box sx={{ display: "flex", justifyContent: "space-around", marginTop: 8, marginBottom: 2 }}>
          <Button
            sx={{ width: 0.4, color: "white" }}
            color={"primary"}
            variant={"contained"}
            onClick={() => {
              closeDialog();
            }}
          >
            {ct["payafter - cancel button"] ?? <FormattedMessageJamezz id={"Cancel"} />}
          </Button>

          <Button
            disabled={(afterPayData?.payment ?? 0) <= 0}
            sx={{ width: 0.4 }}
            color={"error"}
            variant={"outlined"}
            onClick={() => {
              closeDialog();

              openDialog({
                children: <AfterPayPage />,
                fullScreen: !(isQr() && isWidescreen),
                TransitionComponent: SlideUpTransition,
              });
            }}
          >
            {ct["payafter - ok button"] ?? <FormattedMessageJamezz id={"Ok"} />}
          </Button>
        </Box>
      </Collapse>
    </Box>
  );
}

export default PayAfterOrderDialog;
