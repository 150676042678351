import Echo from "laravel-echo";
import { useEffect } from "react";
import { usePrinterCheckResponse } from "../../kiosk/utils/usePrinterCheck";
import { useAppSelector } from "./redux/store";
import Pusher from "pusher-js";
import { useFetchSharedShoppingCart } from "./sharedShoppingCart/processSharedShoppingCart.ts";
import { toast } from "react-toastify";
import { useIntl } from "react-intl";

let initialized: boolean = false;

export default function Websockets() {
  const echo = useEcho();
  const salesareaId = useAppSelector((state) => state.global.salesarea?.id);
  const checkPrinterResponse = usePrinterCheckResponse();
  const fetchSharedShoppingCart = useFetchSharedShoppingCart();
  const isSharedShoppingCartEnabled = useAppSelector((state) => state.shoppingCart.isSharedShoppingCartEnabled);
  const webcode = useAppSelector((state) => state.global.webcode);
  const intl = useIntl();

  // const sessionState = useAppSelector((state) => state.global.sessionState);
  // console.log(sessionState.sessionid);
  // echo.channel("AppEvents." + sessionState.sessionid).listen(".Orders.PayStatus.changed", () => {
  // if (!this.state.paymentReady) {
  //     this.loadRemoteData();
  // }
  // });
  // const vestigingId = 1;
  //

  useEffect(() => {
    const subscribedWebcode = webcode;
    if (!initialized) {
      if (salesareaId && isSharedShoppingCartEnabled && webcode) {
        const channel = echo.channel(`sharedShoppingCart.${webcode}`);

        channel.listen(".shoppingCartUpdated", () => {
          fetchSharedShoppingCart();
        });

        channel.listen(".shoppingCartOrdered", () => {
          fetchSharedShoppingCart();
          toast.success(intl.formatMessage({ id: "Your order is placed successfully!" }));
        });

        channel.listen(".shoppingCartCleared", () => {});
        channel.listen(".shoppingCartUuidCreated", () => {});
        channel.listen(".shoppingCartUuidCanceled", () => {});
        channel.listen(".shoppingCartTicketUpdated", () => {});
        initialized = true;

        fetchSharedShoppingCart();
      }
    }
    return () => {
      initialized = false;

      echo.leaveChannel(`sharedShoppingCart.${subscribedWebcode}`);
    };
  }, [echo, salesareaId, webcode, isSharedShoppingCartEnabled, fetchSharedShoppingCart, intl]);

  useEffect(() => {
    if (salesareaId) {
      echo.channel(`printerStates${salesareaId}`).listen(".printerStatus", (response: any) => {
        checkPrinterResponse({
          printerId: response.printer_id,
          errorState: response.errorState,
          vestigingId: response.vestiging_id,
        });
      });

      // echo.channel(`tableSession.${salesareaId}`).listen(".MenuUpdated", () => {
      //   getMenu();
      // });
    }
    return () => {
      if (salesareaId) {
        echo.leaveChannel(`printerStates${salesareaId}`);
      }
    };
  }, [checkPrinterResponse, echo, salesareaId]);
  return null;
}

window.Pusher = Pusher;

const pusher_config = {
  broadcaster: "pusher",
  key: import.meta.env.VITE_PUSHER_APP_KEY,
  cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
  forceTLS: true,
};

const ci_config = {
  broadcaster: "null",
};

const echo = new Echo({
  ...(import.meta.env.JAMEZZ_CI ? ci_config : pusher_config),
});

export function useEcho() {
  return echo;
}
