import { useContext, useEffect, useRef, useState } from "react";
import OrderArticle, { getExtraOrderArticlesPrice } from "../../../global/utils/models/order/OrderArticle";
import { getArticleDescription, getArticleName, getArticlePrice } from "../../../global/utils/models/menu/Article";
import { Box, Button, Collapse, Typography } from "@mui/material";
import Image from "../../../global/components/Image";
import MenuTag from "../../../global/utils/models/menu/MenuTag";
import MenuTagView from "../../components/MenuTagView";
import OptionGroupContent from "./OptionGroups/OptionGroupContent";
import FormattedMessageJamezz from "../../../global/components/FormattedMessageJamezz";
import { useDialog } from "../../../global/utils/dialog/DialogProvider";
import useMenuTagsForArticle from "../../../global/utils/hooks/useMenuTagsForArticle";
import store, { useAppSelector } from "../../../global/utils/redux/store";
import { useActiveMenuTagsOfOrderArticle } from "../../../global/utils/menu/useActiveMenuTags";
import ActiveMenuTag from "../../components/ActiveMenuTag";
import { OptionTranslationsContext } from "../../../global/utils/translations/useOptionTranslations";
import _ from "lodash";
import Articlegroup from "../../../global/utils/models/menu/Articlegroup";
import useSalesareaType, { SalesareaType } from "../../../global/utils/hooks/useSalesareaType";
import { TransitionGroup } from "react-transition-group";
import { useCustomTexts } from "../../../global/utils/useCustomTexts";
import DialogHeader from "../../components/Dialog/DialogHeader";
import { ScrollToTargetButton } from "../../components/ScrollToTargetButton";
import { AddArticleToOrderButton } from "./AddArticleToOrderButton";
import { PlusMinusButtons } from "./PlusMinusButtons";
import { OrderingUnavailableReason } from "./OrderingUnavailableReason";
import { UpsellWhenOrderingDisabled } from "./UpsellWhenOrderingDisabled";
import { useCheckAyceRules } from "../../utils/arrangements/useCheckAyceRules.ts";
import { selectAppLanguage } from "../../../kiosk/components/LanguageSelector/useLanguage.ts";
import useIsOrderingAllowed from "../../../global/utils/hooks/useIsOrderingAllowed.ts";
import useIsWidescreen from "../../../global/utils/useIsWidescreen.ts";
import { OrderArticleContext } from "../../../global/utils/contexts/OrderArticleContext.ts";
import { checkStockAndMaxCount } from "../../../global/utils/order/useAddOrderArticleToShoppingCart.tsx";
import { selectSalesAreaPriceLineId } from "../../../global/utils/useSalesAreaPriceLineId.ts";
import FormatCurrency from "../../../global/components/Currencies/FormatCurrency.tsx";
import clsx from "clsx";
import { OrderArticleNoteField } from "./OrderArticleNoteField.tsx";

interface Props {
  orderArticle: OrderArticle;
  articlegroup?: Articlegroup;
  modifyingOrderArticle?: OrderArticle;
  isPiggyReward?: boolean;
  callAfterAdding?: () => void;
}

export default function OrderArticleSelectorPageContent({
  modifyingOrderArticle,
  orderArticle,
  articlegroup,
  isPiggyReward = false,
  callAfterAdding = () => {},
}: Props) {
  const context = useContext(OrderArticleContext);
  const setOrderArticle = context?.setOrderArticle;
  const background = useAppSelector(
    (state) =>
      state.global.salesarea?.media_urls?.extra_settings__qrv5_article_page_background?.[0]?.conversions?.optimized?.url
  );
  const article = orderArticle.article;
  const { closeDialog } = useDialog();
  const [isScrollable, setIsScrollable] = useState(false);
  const lang = useAppSelector(selectAppLanguage);
  const box = useRef<HTMLDivElement | null>(null);
  const grid = useRef(null);
  const menuTags = useMenuTagsForArticle(article);
  const activeMenuTags = useActiveMenuTagsOfOrderArticle(orderArticle);
  const salesareaType = useSalesareaType();
  const arrangementRuleResult = useCheckAyceRules();

  const onResize = () => {
    if (box.current?.parentElement) {
      setIsScrollable(box.current.parentElement.scrollHeight > box.current.parentElement.clientHeight);
    }
  };

  useEffect(() => {
    const boxElement = box.current;
    const gridElement = grid.current;
    if (boxElement && gridElement) {
      const resizeObserver = new ResizeObserver(onResize);
      resizeObserver.observe(gridElement);
      return () => {
        resizeObserver.disconnect();
      };
    }
  }, []);

  const customTexts = useCustomTexts(["order_article_page_back_button"]);
  const isWidescreen = useIsWidescreen();
  const priceLineId = useAppSelector(selectSalesAreaPriceLineId);
  const extraPrice = getExtraOrderArticlesPrice(orderArticle, priceLineId, 1);
  const price = getArticlePrice(orderArticle.article, { salesAreaPriceLineId: priceLineId });
  const orderingStatus = useIsOrderingAllowed(articlegroup);
  return (
    <Box
      ref={box}
      data-cy={"page-order-article-content"}
      className={clsx("JS-OrderArticleSelectorPage-Root", "JS-OrderArticleSelectorPage-BackgroundColor")}
      sx={{
        background: background ? `url(${background})` : "background.paper",
        width: 1,
        maxWidth: 1080,
        flexGrow: 1,
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        alignSelf: "center",
      }}
    >
      <DialogHeader canClose={!isPiggyReward} jsClass="JS-OrderArticleSelectorPageContent-HeaderWrapper">
        {getArticleName(article, lang)}
      </DialogHeader>

      <Box
        sx={{
          flex: "1 1 0%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: 1,
          marginTop: 2,
        }}
      >
        {article.imageMediaUrls?.[0]?.conversions?.responsive?.srcset ? (
          <Image
            srcSet={article.imageMediaUrls?.[0]?.conversions?.responsive?.srcset}
            style={{ maxHeight: 300, maxWidth: "100%", objectFit: "contain", width: "100%", height: "100%" }}
            className={"JS-OrderArticleSelectorPage-Image"}
          />
        ) : null}
        <Typography
          sx={{
            paddingX: 2,
            width: 1,
            fontSize: "0.95rem",
            marginTop: 3,
            wordBreak: "break-word",
            textAlign: "center",
          }}
          className={"JS-ArticleDialog-Description"}
          dangerouslySetInnerHTML={{ __html: getArticleDescription(article, lang) }}
        />
        <Typography variant={"h6"} sx={{ fontStyle: "italic" }} className={"JS-ArticleDialog-Price"}>
          {price != 0 ? <FormatCurrency value={price} /> : null}
        </Typography>
        {orderArticle.extraOrderArticles?.map((extraOrderArticle) => (
          <>
            <Typography sx={{ fontStyle: "italic", fontSize: "0.9rem" }} className={"JS-ArticleDialog-ExtraPrice"}>
              {getArticleName(extraOrderArticle.article, lang)} +{extraPrice}
            </Typography>
          </>
        ))}

        {isScrollable ? <ScrollToTargetButton target={grid} /> : null}

        <Box sx={{ display: "flex", flexWrap: "wrap", alignItems: "center", marginX: 2, marginBottom: 1 }}>
          {menuTags.map((menuTag: MenuTag) => {
            return <MenuTagView key={menuTag.id} menuTag={menuTag} />;
          })}
        </Box>
        <TransitionGroup>
          {activeMenuTags.map((activeMenuTag, index) => (
            <Collapse key={activeMenuTag.id}>
              <Box sx={{ display: "flex", marginTop: index > 0 ? 2 : 0, flexWrap: "wrap", justifyContent: "center" }}>
                <ActiveMenuTag menuTag={activeMenuTag} />
              </Box>
            </Collapse>
          ))}
        </TransitionGroup>

        <Box ref={grid} sx={{ width: 1 }}>
          {orderArticle.orderOptionGroups.map((orderOptionGroup, index) => (
            <OptionTranslationsContext.Provider
              value={orderArticle.article.translations}
              key={index + "-" + orderOptionGroup.id}
            >
              <OptionGroupContent
                orderArticle={orderArticle}
                isFirstOptionGroup={index === 0}
                orderOptionGroup={orderOptionGroup}
                onChange={async (orderOptionGroup) => {
                  const copy = _.cloneDeep(orderArticle);
                  copy.orderOptionGroups[index] = orderOptionGroup;
                  setOrderArticle?.(copy);
                }}
              />
            </OptionTranslationsContext.Provider>
          ))}
        </Box>
      </Box>
      {salesareaType === SalesareaType.CATALOG ? <UpsellWhenOrderingDisabled orderArticle={orderArticle} /> : null}
      <OrderArticleNoteField />
      {orderingStatus === "ALLOWED" && !isPiggyReward ? (
        <Box sx={{ marginBottom: 2 }}>
          <PlusMinusButtons
            count={orderArticle.count}
            modifyingOrderArticle={modifyingOrderArticle}
            onClickAdd={() => {
              if (
                !checkStockAndMaxCount(
                  { ...orderArticle, count: orderArticle.count + 1 },
                  store.getState().shoppingCart.items.filter((item) => item.uuid !== orderArticle.uuid)
                )
              ) {
                return;
              }
              setOrderArticle?.({ ...orderArticle, count: orderArticle.count + 1 });
            }}
            onClickRemove={() => {
              setOrderArticle?.({ ...orderArticle, count: orderArticle.count - 1 });
            }}
          />
        </Box>
      ) : null}
      {orderingStatus === "ALLOWED" ? (
        <AddArticleToOrderButton
          orderArticle={orderArticle}
          isScrollable={isScrollable}
          modifyingOrderArticle={modifyingOrderArticle}
          callAfterAdding={!isWidescreen ? callAfterAdding : () => {}}
        />
      ) : (
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          {orderingStatus !== "SALESAREA_DISABLED" ? (
            <OrderingUnavailableReason
              articlegroupId={articlegroup?.id ?? "0"}
              arrangementRuleResult={arrangementRuleResult}
            />
          ) : null}
          <Button
            sx={{ marginBottom: 4 }}
            variant={"contained"}
            onClick={() => {
              closeDialog();
            }}
          >
            {customTexts["order_article_page_back_button"] ?? <FormattedMessageJamezz id={"Back"} />}
          </Button>
        </Box>
      )}
    </Box>
  );
}
