import { memo, ReactNode } from "react";
import OrderArticle from "../../../../global/utils/models/order/OrderArticle.ts";
import { Box, Stack, Typography } from "@mui/material";
import CheckoutArticleContent from "../../../pages/CheckoutPage/CheckoutArticleContent.tsx";
import { ORDER_STATUS } from "../../../../types/qr/arrangements.ts";
import FormattedMessageJamezz from "../../../../global/components/FormattedMessageJamezz.tsx";

function _OrderRound({
  order_items,
  count_as_round,
  header,
  id,
  orderStatus,
}: {
  order_items: OrderArticle[];
  count_as_round: boolean;
  header?: ReactNode;
  id: React.Key;
  orderStatus: ORDER_STATUS;
}) {
  return (
    <Box key={id}>
      {header ? (
        <Box
          sx={(theme) => ({
            width: 1,
            display: "flex",
            alignItems: "baseline",
            justifyContent: "space-between",
            ...(count_as_round
              ? {
                  borderBottom: `2px solid ${theme.palette.primary.main}`,
                  color: theme.palette.primary.main,
                }
              : {
                  borderBottom: `1px solid ${theme.palette.primary.light}`,
                  color: theme.palette.primary.light,
                }),
            mb: 1,
          })}
        >
          {header}
        </Box>
      ) : null}

      {orderStatus === "FAILED" ? (
        <Stack direction={"row"} alignItems={"flex-start"}>
          <Typography color={"error"} fontWeight={800} fontSize={18}>
            <FormattedMessageJamezz id={"The order could not be sent."} />
          </Typography>
          {/*<Button variant={"contained"} onClick={() => {}}>*/}
          {/*  <FormattedMessageJamezz id={"Retry"} />*/}
          {/*</Button>*/}
        </Stack>
      ) : null}
      {order_items.map((orderArticle) => {
        return <CheckoutArticleContent orderArticle={orderArticle} key={orderArticle.article.id} />;
      })}
      <Box sx={{ mt: 4 }} />
    </Box>
  );
}

export const OrderRound = memo(_OrderRound);
